<!-- macrisuddivisione in base al ruolo: cliente admin o amministrazione -->
<!-- dentro ogni area (specialmente cliente) suddividiamo in base al tipo cliente -->

<div class=" container-fluid text-center" id="container" *ngIf="currentUser;else loggedOut">
    <!--  questo pezzo non cè se ruolo use è amministrazione -->
    <hr>
    <div class="container-fluid" [ngSwitch]="currentUser.roles.id">
        <!-- ruolo amministrazione ENDY -->
        <div class="row row-cols-lg-auto g-3 align-items-center justify-content-center" *ngSwitchCase="1">
            <div class="col-3">
                <div class="card card-gestioneclientifas">
                    <mat-icon id="gestioneutente">person</mat-icon>
                    <div class="card-body nav-card">
                        <h1>Gestione clienti ENDY</h1>
                        <a href="#!" routerLink="/gestioneclienti" role="button"
                            class="btn btn-gestioneclientifas">Vai</a>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <div class="card card-gestioneclientifas">
                    <mat-icon id="gestioneclienti">supervisor_account</mat-icon>
                    <div class="card-body nav-card">
                        <h1>Censimento clienti ENDY</h1> <!-- passo un array a routerLink -->
                        <a href="#!" [routerLink]="['/censimentocliente']" role="button"
                            class="btn btn-gestioneclientifas">Vai</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- ruolo utente amministratore commercialista/azienda singola-->
        <div class="row row-cols-lg-auto g-3 align-items-center justify-content-center" *ngSwitchCase="2">
            <div class="col-3" *ngIf="currentUser.cliente.idTipoCliente==2">
                <div class="card card-gestioneutenti">
                    <mat-icon id="gestioneutente">person</mat-icon>
                    <div class="card-body nav-card">
                        <h1>Gestione utenze</h1>
                        <a href="#!" routerLink="/gestioneUtenti" role="button" class="btn btn-gestioneutenti">Vai</a>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <div class="card card-gestioneclienti" *ngIf="currentUser.cliente.idTipoCliente==2">
                    <mat-icon id="gestioneclienti">supervisor_account</mat-icon>
                    <div class="card-body nav-card">
                        <h1>Gestione clienti</h1>
                        <a href="#!" routerLink="/censiementorichiedente" role="button"
                            class="btn btn-gestioneclienti">Vai</a>
                    </div>
                </div>
            </div>
            <div class="col-3" *ngIf="currentUser.cliente.idTipoCliente==1">
                <div class="card card-gestioneanagrafica">
                    <mat-icon id="gestioneanagrafica">person</mat-icon>
                    <div class="card-body nav-card">
                        <h1>Gestione anagrafica</h1>
                        <a href="#!" routerLink="/gestioneanagrafica" role="button"
                            class="btn btn-gestioneanagrafica">Vai</a>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <div class="card card-configurazioneambiente"
                    *ngIf="currentUser.cliente.idTipoCliente==1 || (currentUser.cliente.idTipoCliente==2 &&  numeroPivaUtilizzate>0)">
                    <mat-icon id="configurazioneambiente">settings</mat-icon>
                    <div class="card-body nav-card">
                        <h1>Configurazione ambiente</h1>
                        <a href="#!" routerLink="/configurazioneambiente" role="button"
                            class="btn btn-configurazioneambiente">Vai</a>
                    </div>
                </div>
            </div>

            <!-- <div class="col-3" *ngIf="currentUser.cliente.idTipoCliente==1">
                <div class="card card-MCC">
                    <img class="card-img-top" src="#" alt="Card image cap">
                    <div class="card-body nav-card">
                        <h1>Analisi MCC e CR</h1>
                        <a href="#!" routerLink="/analisimcc" role="button" class="btn btn-MCC">Vai</a>

                    </div>
                </div>
            </div> -->
            <!--
            <div class="col-3"
                *ngIf="currentUser.cliente.idTipoCliente==1 || (currentUser.cliente.idTipoCliente==2 &&  numeroPivaUtilizzate>0)">
                <div class="card card-BP">
                    <img class="card-img-top" src="#" alt="Card image cap">
                    <div class="card-body nav-card">
                        <h1>Agricoltura</h1>
                        <a href="#!" routerLink="/pratiche" role="button" class="btn btn-BP">Vai</a>
                    </div>
                </div>
            </div>
            -->
            <div class="col-3"
                *ngIf="currentUser.cliente.idTipoCliente==1 || (currentUser.cliente.idTipoCliente==2 &&  (numeroPivaUtilizzate>0))">
                <div class="card card-BP">
                    <mat-icon id="configurazionebusinessplan">assessment</mat-icon>
                    <div class="card-body nav-card">
                        <h1>Gestione business plan</h1>
                        <a *ngIf="ambienteIsPresent || bpIsPresent" href="#!" routerLink="/listaBplanNuova"
                            role="button" class="btn btn-BP">Vai</a>

                        <a *ngIf="!ambienteIsPresent && !bpIsPresent" href="#!" role="button" class="btn btn-BP"
                            [disabled]
                            [ngbTooltip]="'Per acccedere a questa sezione devi configurazre l\'ambiente per azienda'">Vai</a>
                    </div>
                </div>
            </div>

            <div class="col-3"
                *ngIf="currentUser.cliente.idTipoCliente==1 || (currentUser.cliente.idTipoCliente==2 &&  numeroPivaUtilizzate>0)">
                <div class="card card-Bench">
                    <mat-icon id="benchmark">trending_up</mat-icon>
                    <div class="card-body nav-card">
                        <h1>Benchmark</h1>
                        <a *ngIf="ambienteIsPresent || bpIsPresent" href="#!" routerLink="/benchmark" role="button"
                            class="btn btn-Bench">Vai</a>

                        <a *ngIf="!ambienteIsPresent && !bpIsPresent" href="#!" role="button" class="btn btn-Bench"
                            [disabled]
                            [ngbTooltip]="'Per acccedere a questa sezione devi configurazre l\'ambiente per azienda'">Vai</a>
                    </div>
                </div>
            </div>
            <div class="col-3"
            *ngIf="currentUser.cliente.idTipoCliente==1 || numeroPivaUtilizzate>0">
                <div class="card card-Smart">
                    <mat-icon id="smartBp">assignment</mat-icon>
                    <div class="card-body nav-card">
                        <h1>Smart Business Plan</h1>
                        <a href="#!" routerLink="/smartBp" role="button"
                            class="btn btn-Smart">Vai</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="row row-cols-lg-auto g-3 align-items-center justify-content-center" *ngSwitchCase="3">
            <div class="col-3"
                *ngIf="currentUser.cliente.idTipoCliente==1 || (currentUser.cliente.idTipoCliente==2 &&  numeroPivaUtilizzate>0)">
                <div class="card card-BP">
                    <mat-icon id="configurazionebusinessplan">assessment</mat-icon>
                    <div class="card-body nav-card">
                        <h1>Gestione business plan</h1>
                        <a *ngIf="ambienteIsPresent || bpIsPresent" href="#!" routerLink="/listaBplanNuova"
                            role="button" class="btn btn-BP">Vai</a>

                        <a *ngIf="!ambienteIsPresent && !bpIsPresent" href="#!" role="button" class="btn btn-BP"
                            [disabled]
                            [ngbTooltip]="'Per acccedere a questa sezione devi configurazre l\'ambiente per azienda'">Vai</a>
                    </div>
                </div>
            </div>
            <div class="col-3"
                *ngIf="currentUser.cliente.idTipoCliente==1 || (currentUser.cliente.idTipoCliente==2 &&  numeroPivaUtilizzate>0)">
                <div class="card card-Bench">
                    <mat-icon id="benchmark">trending_up</mat-icon>
                    <div class="card-body nav-card">
                        <h1>Benchmark</h1>
                        <a *ngIf="ambienteIsPresent || bpIsPresent" href="#!" routerLink="/benchmark" role="button"
                            class="btn btn-Bench">Vai</a>

                        <a *ngIf="!ambienteIsPresent && !bpIsPresent" href="#!" role="button" class="btn btn-Bench"
                            [disabled]
                            [ngbTooltip]="'Per acccedere a questa sezione devi configurazre l\'ambiente per azienda'">Vai</a>
                    </div>
                </div>
            </div>
            <div class="col-3"
            *ngIf="currentUser.cliente.idTipoCliente==1 || numeroPivaUtilizzate>0">
                <div class="card card-Smart">
                    <mat-icon id="smartBp">assignment</mat-icon>
                    <div class="card-body nav-card">
                        <h1>Smart Business Plan</h1>
                        <a href="#!" routerLink="/smartBp" role="button"
                            class="btn btn-Smart">Vai</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="row row-cols-lg-auto g-3 align-items-center justify-content-center" *ngSwitchCase="4">
            <div class="col-3"
                *ngIf="currentUser.cliente.idTipoCliente==1 || (currentUser.cliente.idTipoCliente==2 &&  numeroPivaUtilizzate>0)">
                <div class="card card-BP">
                    <mat-icon id="configurazionebusinessplan">assessment</mat-icon>
                    <div class="card-body nav-card">
                        <h1>Gestione business plan</h1>
                        <a *ngIf="ambienteIsPresent || bpIsPresent" href="#!" routerLink="/listaBplanNuova"
                            role="button" class="btn btn-BP">Vai</a>

                        <a *ngIf="!ambienteIsPresent && !bpIsPresent" href="#!" role="button" class="btn btn-BP"
                            [disabled]
                            [ngbTooltip]="'Per acccedere a questa sezione devi configurazre l\'ambiente per azienda'">Vai</a>
                    </div>
                </div>
            </div>
            <div class="col-3"
                *ngIf="currentUser.cliente.idTipoCliente==1 || (currentUser.cliente.idTipoCliente==2 &&  numeroPivaUtilizzate>0)">
                <div class="card card-Bench">
                    <mat-icon id="benchmark">trending_up</mat-icon>
                    <div class="card-body nav-card">
                        <h1>Benchmark</h1>
                        <a *ngIf="ambienteIsPresent || bpIsPresent" href="#!" routerLink="/benchmark" role="button"
                            class="btn btn-Bench">Vai</a>

                        <a *ngIf="!ambienteIsPresent && !bpIsPresent" href="#!" role="button" class="btn btn-Bench"
                            [disabled]
                            [ngbTooltip]="'Per acccedere a questa sezione devi configurazre l\'ambiente per azienda'">Vai</a>
                    </div>
                </div>
            </div>
            <div class="col-3"
            *ngIf="currentUser.cliente.idTipoCliente==1 || numeroPivaUtilizzate>0">
                <div class="card card-Smart">
                    <mat-icon id="smartBp">assignment</mat-icon>
                    <div class="card-body nav-card">
                        <h1>Smart Business Plan</h1>
                        <a href="#!" routerLink="/smartBp" role="button"
                            class="btn btn-Smart">Vai</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr>
</div> <!-- end container -->

<ng-template #loggedOut>
    Accedete, per favore.
</ng-template>