import { Component, Input, OnInit, ViewChild, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormControl, Validators, UntypedFormGroup, UntypedFormBuilder, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IndicatoriGuidaRequest } from '../interface/indicatori-guida-bp';
import { InvestimentiDisinvestimenti } from '../interface/InvestimentiDisinvestimenti';
import { LineaBusinessBpCompleto } from '../interface/LineaBusinessBpCompleto';
import { Magazzino } from '../interface/magazzino';
import { PraticheNuova } from '../interface/Pratiche-nuova';
import { QuestionarioBp } from '../interface/questionario-bp';
import { Richiedente } from '../interface/richiedente';
import { SituazioneCreditizia, SituazioneDebitoria } from '../interface/situazione-debitoria';
import { ModalcontentComponent } from '../modalcontent/modalcontent/modalcontent.component';
import { ConfigurazioneBusinessPlanService } from '../_services/configurazione-business-plan.service';
import { RichiedenteService } from '../_services/richiedente.service';
import { TokenStorageService } from '../_services/token-storage.service';
import { ConfiguarazioneMagazzinoComponent } from './configuarazione-magazzino/configuarazione-magazzino.component';
import { IndicatoriGuidaBpComponent } from './indicatori-guida-bp/indicatori-guida-bp.component';
import { InserimentoRicaviCostiComponent } from './inserimento-ricavi-costi/inserimento-ricavi-costi.component';
/* import { SituazioneDebitoriaComponent } from './situazione-debitoria/situazione-debitoria.component'; */
import { IndicatoriGuida } from 'src/app/interface/indicatori-guida-bp';
import { StikyService } from './../_services/stiky.service';
import { Observable, Subscription } from 'rxjs';
import { QuestionarioBpTot } from '../interface/QuestionarioBpTot';
import { MenuPulsantieraService } from '../header/services/menu-pulsantiera.service';
import { InvestimentiDisinvestimentiBpComponent } from './investimenti-disinvestimenti-bp/investimenti-disinvestimenti-bp.component';
import { SituazioneCreditiziaComponent } from './situazione-creditizia/situazione-creditizia.component';
import { ColIndiciBilancio } from './../risultati-questionario/interface/dettaglio';
import { SituazioneDebitoriaNuovoComponent } from './situazione-debitoria-nuovo/situazione-debitoria-nuovo.component';
import { ToolTipService } from '../_services/tool-tip.service';
import { ToolTip } from '../interface/toolTip';



@Component({
  selector: 'app-configurazione-business-plan',
  templateUrl: './configurazione-business-plan.component.html',
  styleUrls: ['./configurazione-business-plan.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfigurazioneBusinessPlanComponent implements OnInit, OnDestroy {

  //@ViewChild(SituazioneDebitoriaComponent, { static: false }) situazioneDebitoriaComponent: SituazioneDebitoriaComponent;
  @ViewChild(SituazioneDebitoriaNuovoComponent, { static: false }) situazioneDebitoriaComponent: SituazioneDebitoriaNuovoComponent;
  @ViewChild(SituazioneCreditiziaComponent, { static: false }) situazioneCreditiziaComponent: SituazioneCreditiziaComponent;

  @ViewChild(InvestimentiDisinvestimentiBpComponent, { static: false }) investimentiDisinvestimentiComponent: InvestimentiDisinvestimentiBpComponent;
  @ViewChild(IndicatoriGuidaBpComponent, { static: false }) indicatoriGuidaBpComponent: IndicatoriGuidaBpComponent;
  @ViewChild(InserimentoRicaviCostiComponent, { static: false }) inserimentoRicaviCostiComponent: InserimentoRicaviCostiComponent;
  @ViewChild(ConfiguarazioneMagazzinoComponent, { static: false }) configuarazioneMagazzinoComponent: ConfiguarazioneMagazzinoComponent;



  @Input() labelClass: string;
  questionarioBp: QuestionarioBp;
  questionarioBpToSave: QuestionarioBp;

  /* id bp viene passato in input. sarà 0 se clicca su nuovo o != se vado su modfica. se l'id!=0  devo bloccare l'input del nome azienda */
  selectedIndex = 0;
  selectedIndexFin = 0;
  codiceAtecoPrimario: string;
  ricaviInizialiArray: any = []
  nomesalvataggio: string = ''

  configurazioneBpForm: UntypedFormGroup;
  risultatiVolatileSubscription: Subscription;
  currentBpSubscription: Subscription;
  checkBpSalvatoSubscription: Subscription;
  //calcolaStickySub: Subscription;
  //setPaddingTopRicaviCostiSub: Subscription;
  //setPaddingTopDebitoriaSub: Subscription;

  listaNomiAzienda: string[] = [];
  businessPlanTemp: PraticheNuova = null;
  questionarioTot: QuestionarioBpTot
  //listaRichiedenti: Richiedente[] = [];
  presenzaMagazzino: boolean = false;
  isRisultatiVolatile: boolean = false;
  currentIndicatoriGuida: IndicatoriGuidaRequest = null;
  currentInvestimentiDisinvestimenti: InvestimentiDisinvestimenti = null;
  currentMagazzino: Magazzino[] = null
  currentSituazioneDebitoria: SituazioneDebitoria = null
  currentSituazioneCreditizia: SituazioneCreditizia = null
  currentRicaviCosti: LineaBusinessBpCompleto[] = null;
  bpSalvato: boolean = false;
  listaTips: ToolTip[] = [];

  situazioneDebitoriaValid: boolean = true;
  indicatoriGuidaBpValid: boolean = true;
  investimentiDisinvestimentiValid: boolean = true;
  magazzinoValid: boolean = true;
  situazioneCreditiziaValid: boolean = true;


  situazioneDebitoriaDirty: boolean = false;
  indicatoriGuidaBpDirty: boolean = false;
  investimentiDisinvestimentiDirty: boolean = false;
  magazzinoDirty: boolean = false;
  situazioneCreditiziaDirty: boolean = false;

  ricaviCostiTotValid: boolean = true;
  ricaviCostiValid: boolean[] = []
  ricaviCostiDirty: boolean[] = [];
  ricaviCostiTotDirty: boolean = false;
  ricaviCostiSaved: boolean[] = []; // per salva
  ricaviCostiTotSaved: boolean = false;

  situazioneDebitoriaSaved: boolean = false;
  indicatoriGuidaBpSaved: boolean = false;
  investimentiDisinvestimentiSaved: boolean = false;
  magazzinoSaved: boolean = false;
  situazioneCreditiziaSaved: boolean = false;

  data = new Date();

  inizioDurata = {
    anniSviluppoBp: 0,
    annoIniziale: 0
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private headerService: MenuPulsantieraService,
    private modalService: NgbModal,
    private configurazioneBusinessPlanService: ConfigurazioneBusinessPlanService,
    private tokeStorageService: TokenStorageService,
    private router: Router,
    private toolTipService: ToolTipService,) {

    this.configurazioneBpForm = this.formBuilder.group({
      magazzino: this.formBuilder.group({}),
      indicatoriGuida: this.formBuilder.group({}),
      investimenti: this.formBuilder.group({}),
      ricaviEcosti: this.formBuilder.group({}),
      situazioneDebitoria: this.formBuilder.group({}),
      situazioneCreditizia: this.formBuilder.group({}),
    })
  }
  get currentUser() { return this.tokeStorageService.getUser() }
  get f() { return this.configurazioneBpForm.controls }
  get indicatoriGuidaForm(): UntypedFormGroup { return <UntypedFormGroup> this.f.indicatoriGuida }
  get magazzinoForm(): UntypedFormGroup { return <UntypedFormGroup> this.f.magazzino }
  get investimentiForm(): UntypedFormGroup { return <UntypedFormGroup> this.f.investimenti }
  get ricaviEcostiForm(): UntypedFormGroup { return <UntypedFormGroup> this.f.ricaviEcosti }
  get situazioneDebitoriaForm(): UntypedFormGroup { return <UntypedFormGroup> this.f.situazioneDebitoria }
  get situazioneCreditiziaForm(): UntypedFormGroup { return <UntypedFormGroup> this.f.situazioneCreditizia }




  /* quando accedo da un BP specifico (no nuovo) passiamo l'id BP */
  ngOnInit(): void {
    /* crea una copia della funzione  checkBpDirty() vhe viene messa nel service. richiamando la funzione nel service la attivo*/
    this.headerService.checkFormDirty(this.checkBpDirty.bind(this))

    this.currentBpSubscription = this.configurazioneBusinessPlanService.currentBusinessPlan.subscribe(bp => {
      if (bp != null && bp.idAziendaRichiedente) {
        this.toolTipService.getAllTipsContent('GBP')//carico tutti i tip di GBP sul service
        this.businessPlanTemp = bp;
        this.configurazioneBusinessPlanService.checkPresenzaMagazzino(this.businessPlanTemp.id).subscribe(presenzaMagazzino => {

          this.presenzaMagazzino = presenzaMagazzino;
        })

      } else {
        this.router.navigate(['/listaBplanNuova']);
      }
    })

    this.checkBpSalvatoSubscription = this.configurazioneBusinessPlanService.checkBpSalvato.subscribe(bpSalvato => {
      this.bpSalvato = bpSalvato;
    })
    this.configurazioneBusinessPlanService.currentRisultatiVolatile.subscribe(siVolatile => {
      this.isRisultatiVolatile = siVolatile
      if (this.isRisultatiVolatile == true) {
        this.configurazioneBusinessPlanService.currentQuestionarioBp.subscribe(response => {
          if (response != null) {
            this.currentIndicatoriGuida = response.indicatoriGuidaBp
            this.currentInvestimentiDisinvestimenti = response.investimentiDisinvestimenti
            this.currentMagazzino = response.magazzino
            this.currentSituazioneDebitoria = response.situazioneDebitoria
            this.currentSituazioneCreditizia = response.situazioneCreditizia
            this.currentRicaviCosti = response.ricaviCosti

          }
        }).unsubscribe()
      }
    }).unsubscribe()

  }

  saveIndicatoriGuidaValid(isValid: any) {
    this.indicatoriGuidaBpValid = isValid.indicatoriGuidaBpValid
    this.indicatoriGuidaBpDirty = isValid.indicatoriGuidaDirty
  }

  saveInvestimentiValid(isValid: any) {
    this.investimentiDisinvestimentiValid = isValid.investimentiValid;
    this.investimentiDisinvestimentiDirty = isValid.investimentiDisinvestimentiDirty
  }
  saveCreditiziaValid(isValid: any) {
    this.situazioneCreditiziaValid = isValid.situazioneCreditiziaValid
    this.situazioneCreditiziaDirty = isValid.situazioneCreditiziaDirty
  }
  saveDebitoriaValid(isValid: any) {
    this.situazioneDebitoriaValid = isValid.situazioneDebitoriaValid
    this.situazioneDebitoriaDirty = isValid.situazioneDebitoriaDirty
  }
  saveMagazzinoValid(isValid: any) {
    this.magazzinoValid = isValid.magazzinoValid;
    this.magazzinoDirty = isValid.magazzinoDirty
  }

  saveRicaviCostiValid(isValid: any) {
    let dirtyTemp = true;
    let validTemp = true;
    this.ricaviCostiValid = []
    this.ricaviCostiDirty = []
    for (let i = 0; i < isValid.lineaBusinessArrayValid.length; i++) {

      dirtyTemp = dirtyTemp && isValid.lineeBpDirty[i]
      validTemp = validTemp && isValid.lineaBusinessArrayValid[i]
    }
    this.ricaviCostiValid = isValid.lineaBusinessArrayValid
    this.ricaviCostiDirty = isValid.lineeBpDirty
    this.ricaviCostiTotValid = validTemp
    this.ricaviCostiTotDirty = dirtyTemp
  }
  /* ------------------- */


  /* crea una copia della funzione  checkBpDirty*/
  checkBpDirty() {
    this.situazioneDebitoriaDirty = this.businessPlanTemp.idStatoPatrimonialePassivo != 0 ? this.situazioneDebitoriaComponent.isFormDebitoDirty() : false;
    this.situazioneCreditiziaDirty = this.businessPlanTemp.idStatoPatrimonialeAttivo != 0 ? this.situazioneCreditiziaComponent.isFormCreditoDirty() : false;
    this.indicatoriGuidaBpDirty = this.indicatoriGuidaBpComponent.isFormDirty()
    this.investimentiDisinvestimentiDirty = this.investimentiDisinvestimentiComponent.isFormDirty()
    this.magazzinoDirty = (this.presenzaMagazzino) ? this.configuarazioneMagazzinoComponent.isFormDirty() : false
    this.ricaviCostiTotDirty = this.inserimentoRicaviCostiComponent.RicaviCostiDirtySubmit().ricaviCostiDirty

    return this.situazioneDebitoriaDirty || this.situazioneCreditiziaDirty || this.indicatoriGuidaBpDirty || this.indicatoriGuidaBpDirty ||
      this.investimentiDisinvestimentiDirty || this.ricaviCostiTotDirty || this.magazzinoDirty
  }

  onSubmitComplessivo() {
    this.situazioneDebitoriaSaved = false;
    this.indicatoriGuidaBpSaved = false;
    this.investimentiDisinvestimentiSaved = false;
    this.magazzinoSaved = false;
    this.situazioneCreditiziaSaved = false;
    this.ricaviCostiTotSaved = false;
    this.ricaviCostiDirty = []
    this.ricaviCostiValid = []

    if (this.businessPlanTemp.idStato == 1) {
      /* controlli a cascata. prima controllo quali sezioni ha visitato l'utente tramite formDirty. per le sezioni visitate
      controllo la validità del form. infime salvo solo le sezioni visitate e validenetf  */
      /*per elabora dirty = false solo per le linee disabilitate. per salva dirty= false per tutte le linee sia disabilitate che valorizzate ma non modificate */

      this.situazioneDebitoriaDirty = this.businessPlanTemp.idStatoPatrimonialePassivo != 0 ? this.situazioneDebitoriaComponent.isFormDebitoDirty() : false;
      this.situazioneCreditiziaDirty = this.businessPlanTemp.idStatoPatrimonialeAttivo != 0 ? this.situazioneCreditiziaComponent.isFormCreditoDirty() : false;
      this.indicatoriGuidaBpDirty = this.indicatoriGuidaBpComponent.isFormDirty()
      this.investimentiDisinvestimentiDirty = this.investimentiDisinvestimentiComponent.isFormDirty()
      this.magazzinoDirty = (this.presenzaMagazzino) ? this.configuarazioneMagazzinoComponent.isFormDirty() : false

      this.ricaviCostiTotDirty = this.inserimentoRicaviCostiComponent.RicaviCostiDirtySubmit().ricaviCostiDirty
      /* carico gli array delle linee */
      this.ricaviCostiDirty = this.inserimentoRicaviCostiComponent.RicaviCostiDirtySubmit().lineeBpDirty
      /* se una sezione qualsiasi è dirty */
      // scenario:
      // 1.elabora bp in prep poi fa modifica da risultati torna a questionario devo poter salvare anche se non applica modifiche
      // 2. bp in lavorazione con risultati volatili (risultati->modifica (faccio una modifica)->calcolo i risultati (che diventano volatili)->
      // torna a questionario e devo poter salvare anche se non applico modifiche
      //l'if serve a poter salvare sempre quando  torno su questionario da risultati
      if (this.isRisultatiVolatile) {
        this.situazioneDebitoriaDirty = true
        this.situazioneCreditiziaDirty = true
        this.indicatoriGuidaBpDirty = true
        this.investimentiDisinvestimentiDirty = true
        this.magazzinoDirty = true
        this.ricaviCostiTotDirty = true
        /* carico gli array delle linee */
        for (let i = 0; i < this.ricaviCostiDirty.length; i++) {
          this.ricaviCostiDirty.splice(i, 1, true)
        }
      }
      if (this.situazioneDebitoriaDirty || this.situazioneCreditiziaDirty || this.indicatoriGuidaBpDirty || this.indicatoriGuidaBpDirty ||
        this.investimentiDisinvestimentiDirty || this.ricaviCostiTotDirty || this.magazzinoDirty) {

        //verifico la validità dei form (modifiche anche in onsubmit di situazioneDebitoria.ts e situazioneCreditizia.ts)
        this.situazioneDebitoriaValid = (this.situazioneDebitoriaDirty && this.businessPlanTemp.idStatoPatrimonialePassivo != 0) ? this.situazioneDebitoriaComponent.isDebitiValid() : true;
        this.situazioneCreditiziaValid = (this.situazioneCreditiziaDirty && this.businessPlanTemp.idStatoPatrimonialeAttivo != 0) ? this.situazioneCreditiziaComponent.isCreditiValid() : true;
        this.indicatoriGuidaBpValid = this.indicatoriGuidaBpDirty ? this.indicatoriGuidaBpComponent.isFormValid() : true
        this.investimentiDisinvestimentiValid = this.investimentiDisinvestimentiDirty ? this.investimentiDisinvestimentiComponent.isFormValid() : true
        this.magazzinoValid = (this.magazzinoDirty && this.presenzaMagazzino) ? this.configuarazioneMagazzinoComponent.isFormValid() : true //se non cè magazzino non salva la sezione

        //non controllo più la validità per il salva su queste sezioni. può salvare il cazzo che gli pare
        //this.situazioneDebitoriaValid = true;
        //this.situazioneCreditiziaValid = true;
        //this.indicatoriGuidaBpValid = true
        //modifiche in ricaviCosti.ts per non controllare la validità delle linee
        this.ricaviCostiTotValid = this.ricaviCostiTotDirty ? this.inserimentoRicaviCostiComponent.RicaviCostiValidSubmit().ricaviCostiFormValid : true
        this.ricaviCostiValid = this.inserimentoRicaviCostiComponent.RicaviCostiValidSubmit().lineaBusinessArrayValid;
        //se almeno uno di quelli dirty è invalid
        if (!(this.situazioneDebitoriaValid && this.situazioneCreditiziaValid && this.indicatoriGuidaBpValid && this.investimentiDisinvestimentiValid && this.magazzinoValid && this.ricaviCostiTotValid)) {
          if (this.modalService.hasOpenModals) {
            this.modalService.dismissAll(); /*chiude tutto per sicurezza? */
          }
          /* modale salva */
          const modalRef = this.modalService.open(ModalcontentComponent, {
            backdrop: 'static',
            backdropClass: 'customBackdrop',
          });
          modalRef.componentInstance.modalPar = {
            name: "salvamodifiche",
            title: "Salva questionario",
            description: "Sono presenti delle sezioni incomplete. Salvare comunque i dati inseriti?",
            actionButtonText: "Salva",
            undoButtonText: "Indietro",
          }
          modalRef.result.then((r) => {
            if (r) {
              /* mettendoli tutti a true, in combo con valid = false attiva la scritta rossa */
              this.questionarioBpToSave = {
                situazioneDebitoria: (this.situazioneDebitoriaDirty && this.businessPlanTemp.idStatoPatrimonialePassivo != 0) ? this.situazioneDebitoriaComponent.onSubmitDebiti() : null,
                indicatoriGuidaBp: this.indicatoriGuidaBpDirty ? this.indicatoriGuidaBpComponent.onSubmit() : null,
                investimentiDisinvestimenti: this.investimentiDisinvestimentiDirty ? this.investimentiDisinvestimentiComponent.onSubmit() : null,
                magazzino: (this.magazzinoDirty && this.presenzaMagazzino) ? this.configuarazioneMagazzinoComponent.onSubmit() : null,
                situazioneCreditizia: (this.situazioneCreditiziaDirty && this.businessPlanTemp.idStatoPatrimonialeAttivo != 0) ? this.situazioneCreditiziaComponent.onSubmitCrediti() : null,
                ricaviCosti: this.inserimentoRicaviCostiComponent.onSubmitLinee(),
              }
              let riferimentoBp: PraticheNuova = {
                id: this.businessPlanTemp.id, //BusinessPlan
                riferimentoBp: this.businessPlanTemp.riferimentoBp,
                idUtente: this.businessPlanTemp.idUtente,
                idAziendaRichiedente: this.businessPlanTemp.idAziendaRichiedente,
                nomeAziendaRichiedente: this.businessPlanTemp.nomeAziendaRichiedente,
                dataCreazione: this.businessPlanTemp.dataCreazione,
                dataModifica: this.data, //aggiorno data modifica
                idStato: this.businessPlanTemp.idStato,
                idContoEconomico: this.businessPlanTemp.idContoEconomico,
                idStatoPatrimonialeAttivo: this.businessPlanTemp.idStatoPatrimonialeAttivo,
                idStatoPatrimonialePassivo: this.businessPlanTemp.idStatoPatrimonialePassivo,
                bilancioAbbreviato: this.businessPlanTemp.bilancioAbbreviato,
              }
              let questionarioTot = {
                questionarioCompleto: this.questionarioBpToSave,
                riferimentoBp: riferimentoBp,

              }
              this.saveBpPreparazione(questionarioTot)

            } else {
              /* se clicca indietro riporto tutto allo stato di partenza */
              this.situazioneDebitoriaDirty = false;
              this.situazioneCreditiziaDirty = false;
              this.indicatoriGuidaBpDirty = false
              this.investimentiDisinvestimentiDirty = false
              this.ricaviCostiTotDirty = false
              this.magazzinoDirty = false

              this.situazioneDebitoriaValid = true;
              this.situazioneCreditiziaValid = true;
              this.indicatoriGuidaBpValid = true
              this.investimentiDisinvestimentiValid = true
              this.ricaviCostiTotValid = true
              this.magazzinoValid = true

              this.situazioneDebitoriaSaved = false;
              this.indicatoriGuidaBpSaved = false;
              this.investimentiDisinvestimentiSaved = false;
              this.magazzinoSaved = false;
              this.situazioneCreditiziaSaved = false;
              this.ricaviCostiTotSaved = false;

              for (let i = 0; i < this.ricaviCostiValid.length; i++) {
                this.ricaviCostiValid.splice(i, 1, true)
                this.ricaviCostiDirty.splice(i, 1, false)
                this.ricaviCostiSaved.splice(i, 1, false)
              }

            }
          })
        } else {
          this.questionarioBpToSave = {
            situazioneDebitoria: (this.situazioneDebitoriaDirty && this.businessPlanTemp.idStatoPatrimonialePassivo != 0) ? this.situazioneDebitoriaComponent.onSubmitDebiti() : null,
            indicatoriGuidaBp: this.indicatoriGuidaBpDirty ? this.indicatoriGuidaBpComponent.onSubmit() : null,
            investimentiDisinvestimenti: this.investimentiDisinvestimentiDirty ? this.investimentiDisinvestimentiComponent.onSubmit() : null,
            magazzino: (this.magazzinoDirty && this.presenzaMagazzino) ? this.configuarazioneMagazzinoComponent.onSubmit() : null,
            situazioneCreditizia: (this.situazioneCreditiziaDirty && this.businessPlanTemp.idStatoPatrimonialeAttivo != 0) ? this.situazioneCreditiziaComponent.onSubmitCrediti() : null,
            ricaviCosti: this.inserimentoRicaviCostiComponent.onSubmitLinee(),
          }
          let riferimentoBp: PraticheNuova = {
            id: this.businessPlanTemp.id, //BusinessPlan
            riferimentoBp: this.businessPlanTemp.riferimentoBp,
            idUtente: this.businessPlanTemp.idUtente,
            idAziendaRichiedente: this.businessPlanTemp.idAziendaRichiedente,
            nomeAziendaRichiedente: this.businessPlanTemp.nomeAziendaRichiedente,
            dataCreazione: this.businessPlanTemp.dataCreazione,
            dataModifica: this.data, //aggiorno data modifica
            idStato: this.businessPlanTemp.idStato,
            idContoEconomico: this.businessPlanTemp.idContoEconomico,
            idStatoPatrimonialeAttivo: this.businessPlanTemp.idStatoPatrimonialeAttivo,
            idStatoPatrimonialePassivo: this.businessPlanTemp.idStatoPatrimonialePassivo,
            bilancioAbbreviato: this.businessPlanTemp.bilancioAbbreviato,
          }
          let questionarioTot = {
            questionarioCompleto: this.questionarioBpToSave,
            riferimentoBp: riferimentoBp,

          }
          this.saveBpPreparazione(questionarioTot)

        }
      }
    } else if (this.businessPlanTemp.idStato == 2) {
      this.situazioneDebitoriaDirty = this.businessPlanTemp.idStatoPatrimonialePassivo != 0 ? true : false;
      this.situazioneCreditiziaDirty = this.businessPlanTemp.idStatoPatrimonialeAttivo != 0 ? true : false;
      this.indicatoriGuidaBpDirty = true
      this.investimentiDisinvestimentiDirty = true
      this.magazzinoDirty = (this.presenzaMagazzino) ? true : false

      this.ricaviCostiTotDirty = true
      /* carico gli array delle linee. è già gestito in base all'id stato*/
      this.ricaviCostiDirty = this.inserimentoRicaviCostiComponent.RicaviCostiDirtySubmit().lineeBpDirty

      if (this.situazioneDebitoriaDirty || this.situazioneCreditiziaDirty || this.indicatoriGuidaBpDirty || this.indicatoriGuidaBpDirty ||
        this.investimentiDisinvestimentiDirty || this.ricaviCostiTotDirty || this.magazzinoDirty) {
        // per bp in lavorazione salvo sempre tutto (controllo solo la validità)
        // nel caso start up metto situazione debitoria, creditizia e magazzino a valid altrimenti non salva. tanto  poi passo null al submit
        this.situazioneDebitoriaValid = (this.businessPlanTemp.idStatoPatrimonialePassivo != 0) ? this.situazioneDebitoriaComponent.isDebitiValid() : true;
        this.situazioneCreditiziaValid = (this.businessPlanTemp.idStatoPatrimonialeAttivo != 0) ? this.situazioneCreditiziaComponent.isCreditiValid() : true;
        this.indicatoriGuidaBpValid = this.indicatoriGuidaBpComponent.isFormValid()
        this.investimentiDisinvestimentiValid = this.investimentiDisinvestimentiComponent.isFormValid()
        this.magazzinoValid = (this.presenzaMagazzino) ? this.configuarazioneMagazzinoComponent.isFormValid() : true //se non cè magazzino non salva la sezione

        this.ricaviCostiTotValid = this.inserimentoRicaviCostiComponent.RicaviCostiValidSubmit().ricaviCostiFormValid
        this.ricaviCostiValid = this.inserimentoRicaviCostiComponent.RicaviCostiValidSubmit().lineaBusinessArrayValid
        if (this.situazioneDebitoriaValid && this.situazioneCreditiziaValid && this.indicatoriGuidaBpValid && this.investimentiDisinvestimentiValid && this.magazzinoValid && this.ricaviCostiTotValid) {

          if (this.modalService.hasOpenModals) {
            this.modalService.dismissAll(); /*chiude tutto per sicurezza? */
          }
          const modalRef = this.modalService.open(ModalcontentComponent);
          modalRef.componentInstance.modalPar = {
            name: "salvaBp",
            idAzienda: this.businessPlanTemp.idAziendaRichiedente,
            isRisultatiVolatile: this.isRisultatiVolatile,
            description: this.businessPlanTemp.riferimentoBp,
            title: "Salvataggio Business Plan",
            actionButtonText: "Salva",
            undoButtonText: "Indietro",
          }
          modalRef.result.then(r => {
            if (r.promise) {
              this.nomesalvataggio = r.nomesalvataggio
              if (this.nomesalvataggio != '' && r.conferma != undefined) {// condizione superflua ma lasciamola

                this.questionarioBpToSave = {
                  situazioneDebitoria: (this.businessPlanTemp.idStatoPatrimonialePassivo != 0) ? this.situazioneDebitoriaComponent.onSubmitDebiti() : null,
                  indicatoriGuidaBp: this.indicatoriGuidaBpComponent.onSubmit(),
                  investimentiDisinvestimenti: this.investimentiDisinvestimentiComponent.onSubmit(),
                  magazzino: (this.presenzaMagazzino) ? this.configuarazioneMagazzinoComponent.onSubmit() : null,
                  situazioneCreditizia: (this.businessPlanTemp.idStatoPatrimonialeAttivo != 0) ? this.situazioneCreditiziaComponent.onSubmitCrediti() : null,
                  ricaviCosti: this.inserimentoRicaviCostiComponent.onSubmitLinee(),
                }
                /* se non cambia nome lo avverto che sovrascrive i dati con tutte le consegueze del caso */
                if (this.nomesalvataggio == this.businessPlanTemp.riferimentoBp) {
                  /* if (this.modalService.hasOpenModals) {
                   this.modalService.dismissAll(); 
                  }*/
                  const modalRef = this.modalService.open(ModalcontentComponent);
                  modalRef.componentInstance.modalPar = {
                    name: "salvamodifiche",
                    description: " Sovrascrivere i dati?" + '\n' + " In questo modo il Business plan '" + this.businessPlanTemp.riferimentoBp +
                      "' verrà riportato allo stato di 'Preparazione' e i risultati calcolati con i dati precedenti verranno eliminati.",
                    title: "Salvataggio Business Plan",
                    actionButtonText: "Salva",
                    undoButtonText: "Indietro",
                  }
                  modalRef.result.then(r => {
                    if (r) {
                      let requestBp: PraticheNuova = {
                        id: this.businessPlanTemp.id, //BusinessPlan
                        idUtente: this.tokeStorageService.getUser().idUtente,
                        riferimentoBp: this.nomesalvataggio,
                        idAziendaRichiedente: this.businessPlanTemp.idAziendaRichiedente,
                        nomeAziendaRichiedente: this.businessPlanTemp.nomeAziendaRichiedente,
                        dataCreazione: this.businessPlanTemp.dataCreazione,
                        dataModifica: this.data,
                        idStato: 1,
                        idContoEconomico: this.businessPlanTemp.idContoEconomico,
                        idStatoPatrimonialeAttivo: this.businessPlanTemp.idStatoPatrimonialeAttivo,
                        idStatoPatrimonialePassivo: this.businessPlanTemp.idStatoPatrimonialePassivo,
                        bilancioAbbreviato: this.businessPlanTemp.bilancioAbbreviato,
                      }
                      this.questionarioTot = {
                        questionarioCompleto: this.questionarioBpToSave,
                        riferimentoBp: requestBp,
                      }
                      this.saveBpLavorazione()
                    } else {
                      // se torna ndietro nada
                    }
                  })

                } else {
                  /* caso cambia il nome del bp (creo un nuovo bp in prep e salvo ) */
                  let requestBp: PraticheNuova = {
                    id: 0, //BusinessPlan
                    idUtente: this.tokeStorageService.getUser().idUtente,
                    riferimentoBp: this.nomesalvataggio,
                    idAziendaRichiedente: this.businessPlanTemp.idAziendaRichiedente,
                    nomeAziendaRichiedente: this.businessPlanTemp.nomeAziendaRichiedente,
                    dataCreazione: this.data,
                    dataModifica: this.data,
                    idStato: 1,
                    idContoEconomico: this.businessPlanTemp.idContoEconomico,
                    idStatoPatrimonialeAttivo: this.businessPlanTemp.idStatoPatrimonialeAttivo,
                    idStatoPatrimonialePassivo: this.businessPlanTemp.idStatoPatrimonialePassivo,
                    bilancioAbbreviato: this.businessPlanTemp.bilancioAbbreviato,
                  }

                  this.questionarioTot = {
                    questionarioCompleto: this.questionarioBpToSave,
                    riferimentoBp: requestBp,
                  }
                  this.saveBpLavorazione()
                }
                //azzero l'id se cambia nome
              }
            } else {
              /* se clicca indietro riporto tutto allo stato di partenza */
              this.situazioneDebitoriaDirty = false;
              this.situazioneCreditiziaDirty = false;
              this.indicatoriGuidaBpDirty = false
              this.investimentiDisinvestimentiDirty = false
              this.ricaviCostiTotDirty = false
              this.magazzinoDirty = false

              this.situazioneDebitoriaValid = true;
              this.situazioneCreditiziaValid = true;
              this.indicatoriGuidaBpValid = true
              this.investimentiDisinvestimentiValid = true
              this.ricaviCostiTotValid = true
              this.magazzinoValid = true

              this.situazioneDebitoriaSaved = false;
              this.indicatoriGuidaBpSaved = false;
              this.investimentiDisinvestimentiSaved = false;
              this.magazzinoSaved = false;
              this.situazioneCreditiziaSaved = false;
              this.ricaviCostiTotSaved = false;

              for (let i = 0; i < this.ricaviCostiValid.length; i++) {
                this.ricaviCostiValid.splice(i, 1, true)
                this.ricaviCostiDirty.splice(i, 1, false)
                this.ricaviCostiSaved.splice(i, 1, false)
              }
            }
          })

        } else {
          const modalRef = this.modalService.open(ModalcontentComponent, {
            backdrop: 'static',
            backdropClass: 'customBackdrop',
          });
          modalRef.componentInstance.modalPar = {
            name: "errore",
            title: "Errore",
            description: "Le sezioni: " + (this.situazioneDebitoriaValid ? '' : ' Situazione debitoria,') + (this.situazioneCreditiziaValid ? '' : ' Situazione creditizia,')
              + (this.investimentiDisinvestimentiValid ? '' : ' investimenti/disinvestimenti,') + (this.indicatoriGuidaBpValid ? '' : ' Indicatori guida,')
              + (this.ricaviCostiTotValid ? '' : 'Pianificazione ricavi e costi,') + (this.magazzinoValid ? '' : ' Magazzino') + " non sono state compilate correttamente. Ricontrollare i dati inseriti.",

            undoButtonText: "Ok",
          }
          modalRef.result.then(r => {
          })

        }
      }
      // il salva non fa nulla
    }
  }

  saveBpPreparazione(questionarioTot: QuestionarioBpTot) {
    this.configurazioneBusinessPlanService.saveBusinessPlanPreparazione(questionarioTot).subscribe(response => {
      /* gli passo true o false a seconda se viene salvato o no */

      let ricaviCostiSavedSingolo: boolean = false/* serve solo per non far comparire la modale di errore salvatagghio */
      this.magazzinoSaved = response.magazzinoSaved
      this.situazioneCreditiziaSaved = response.situazioneCreditiziaSaved
      this.situazioneDebitoriaSaved = response.situazioneDebitoriaSaved
      this.investimentiDisinvestimentiSaved = response.investimentiDisinvestimentiSaved
      this.indicatoriGuidaBpSaved = response.indicatoriGuidaBpSaved

      this.ricaviCostiTotSaved = true;// non posso lasciarlo a false altrimenti rimane sempre false
      this.ricaviCostiSaved = []
      for (let i = 0; i < response.ricaviCostiSaved.length; i++) {

        this.ricaviCostiSaved.push(response.ricaviCostiSaved[i])
        this.ricaviCostiTotSaved = this.ricaviCostiTotSaved && this.ricaviCostiTotDirty && (this.ricaviCostiDirty[i] ? response.ricaviCostiSaved[i] : true) //label verde solo se tutte le linee modificate sopno state salvate sono tutti saved 
        ricaviCostiSavedSingolo = ricaviCostiSavedSingolo || response.ricaviCostiSaved[i]

        if (this.ricaviCostiSaved[i] && this.ricaviCostiDirty[i]) {
          /* mettere scritta verde se tutte le linee che ho sporcato sono anche state salvate(no erorri) */
          this.ricaviCostiValid.splice(i, 1, response.ricaviCostiSaved[i]) /* se la linea è salvata valid torna a true */
          this.ricaviCostiDirty.splice(i, 1, !response.ricaviCostiSaved[i]) /* se salvata correttamente la linea specifica non è piu dirty */

        } else if (!this.ricaviCostiSaved[i] && this.ricaviCostiDirty[i]) { /* se una linea è dirty ma non salvata */
          this.ricaviCostiTotValid = false
        }
      }
      if (this.ricaviCostiTotSaved) { /*  se tutte le linee modificate sono state salvate deve essere green */
        this.ricaviCostiTotDirty = false
        this.ricaviCostiTotValid = true
      }
      //ricarico le linee business se cè almeno una linea salvata
      if (ricaviCostiSavedSingolo) {
        this.inserimentoRicaviCostiComponent.getLineeBp()
      }
      /* per le sezioni salvate metto submitted a false e valid a true*/
      if (this.situazioneDebitoriaSaved) {
        this.situazioneDebitoriaDirty = false;
        this.situazioneDebitoriaValid = true;
        /* lo metto altrimenti il form rimane dirty */
        this.situazioneDebitoriaComponent.getSituazioneDebitoria()
      }
      if (this.situazioneCreditiziaSaved) {
        this.situazioneCreditiziaDirty = false;
        this.situazioneCreditiziaValid = true;
        this.situazioneCreditiziaComponent.getSituazioneCreditizia()
      }
      if (this.indicatoriGuidaBpSaved) {
        this.indicatoriGuidaBpDirty = false
        this.indicatoriGuidaBpValid = true;
        this.indicatoriGuidaBpComponent.getIndicatoriGuida();/*era presente nel salva singolo per sicurezza lo lasciamo */
      }
      if (this.investimentiDisinvestimentiSaved) {
        this.investimentiDisinvestimentiDirty = false
        this.investimentiDisinvestimentiValid = true
        this.investimentiDisinvestimentiComponent.getInvestimentiDisinvestimenti()
      }
      if (this.magazzinoSaved) {
        this.magazzinoDirty = false
        this.magazzinoValid = true
        this.configuarazioneMagazzinoComponent.getMagazzino()
      }
      if (response.magazzinoSaved || response.situazioneDebitoriaSaved || response.situazioneCreditiziaSaved || response.indicatoriGuidaBpSaved || response.investimentiDisinvestimentiSaved || ricaviCostiSavedSingolo) {
        //modale sezioni salvate con successo
        const modalRef = this.modalService.open(ModalcontentComponent, {
          backdrop: 'static',
          backdropClass: 'customBackdrop',
        });
        modalRef.componentInstance.modalPar = {
          name: "messaggiosalva",
          description: this.isRisultatiVolatile ? "Salvato con successo." : "Le sezioni: " + (this.situazioneDebitoriaSaved ? ' Situazione debitoria,' : '') + (this.situazioneCreditiziaSaved ? ' Situazione creditizia,' : '')
            + (this.investimentiDisinvestimentiSaved ? ' investimenti/disinvestimenti,' : '') + (this.indicatoriGuidaBpSaved ? ' Indicatori guida,' : '')
            + (this.ricaviCostiTotSaved ? 'Pianificazione ricavi e costi,' : '') + (this.magazzinoSaved ? ' Magazzino, ' : '') + " sono state salvate con successo.",

          undoButtonText: "Ok",
        }
        modalRef.result.then(r => {
          /* intestazione verde */
        })
      } else {
        //modale ricontrollare i dati di input
        const modalRef = this.modalService.open(ModalcontentComponent, {
          backdrop: 'static',
          backdropClass: 'customBackdrop',
        });
        modalRef.componentInstance.modalPar = {
          name: "errore",
          title: "Errore",
          description: "Alcune sezioni modificate non sono state compilate correttamente. Ricontrollare i dati inseriti.",
          actionButtonText: "",
          undoButtonText: "Ok",
        }
        modalRef.result.then(r => {

        })
      }
    })
  }
  saveBpLavorazione() {
    this.configurazioneBusinessPlanService.saveBusinessPlanLavorazione(this.questionarioTot, this.businessPlanTemp.id).subscribe((id) => {
      if (id != null) {
        const modalRef = this.modalService.open(ModalcontentComponent, {
          backdrop: 'static',
          backdropClass: 'customBackdrop',
        });
        modalRef.componentInstance.modalPar = {
          name: "messaggiosalva",
          description: "Business plan salvato correttamente.",

          undoButtonText: "Ok",
        }
        modalRef.result.then(r => {
          if (id == this.businessPlanTemp.id) {
            //se sovrascrivo rimango qui. Tutte le sezioni sono state salvate  quindi setto tutti i saved a true e riporto dirty e valid allo stato iniziale    
            this.configurazioneBusinessPlanService.deleteRisultati(id)// se sovrascrivo devo cancellare i risultiati
            this.situazioneDebitoriaSaved = true;
            this.indicatoriGuidaBpSaved = true;
            this.investimentiDisinvestimentiSaved = true;
            this.magazzinoSaved = true;
            this.situazioneCreditiziaSaved = true;
            this.ricaviCostiTotSaved = true;

            this.situazioneDebitoriaDirty = false;
            this.situazioneCreditiziaDirty = false;
            this.indicatoriGuidaBpDirty = false
            this.investimentiDisinvestimentiDirty = false
            this.ricaviCostiTotDirty = false
            this.magazzinoDirty = false

            this.situazioneDebitoriaValid = true;
            this.situazioneCreditiziaValid = true;
            this.indicatoriGuidaBpValid = true
            this.investimentiDisinvestimentiValid = true
            this.ricaviCostiTotValid = true
            this.magazzinoValid = true
            this.configurazioneBusinessPlanService.isRisultatiVolatile(false)
            this.ngOnInit()
          } else {
            //se genero nuovo bp torno a lista bplan e il nuovo bp è in preparazione
            this.router.navigate(['/listaBplanNuova'])
          }
        })

      }
    })
  }



  Elabora() {
    if (this.businessPlanTemp.idStato == 1 || this.businessPlanTemp.idStato == 2) {
      this.ricaviCostiDirty = []
      this.ricaviCostiValid = []

      //Controllo se la configurazione è dirty prima di impostare tutto a true; se è pristine non ricalcolo i risultati
      let isVolatile = this.businessPlanTemp.idStato == 1 || this.checkBpDirty() || this.isRisultatiVolatile;
      /* setto i dirty/submitted a true per una questione di colori e verifico validità () */
      this.situazioneDebitoriaDirty = true
      this.situazioneCreditiziaDirty = true
      this.indicatoriGuidaBpDirty = true
      this.investimentiDisinvestimentiDirty = true
      this.magazzinoDirty = true

      this.ricaviCostiTotDirty = true
      for (let i = 0; i < this.inserimentoRicaviCostiComponent.lineeBpDirty.length; i++) {
        this.ricaviCostiDirty.push(true)// inizializzo il vettore a true
      }

      /*this.ricaviCostiTotDirty = this.inserimentoRicaviCostiComponent.RicaviCostiDirtyElabora().ricaviCostiDirty
      per elabora dirty = false solo per le linee disabilitate. per salva dirty= false per tutte le linee sia disabilitate che valorizzate ma non modificate 
      this.ricaviCostiDirty = this.inserimentoRicaviCostiComponent.RicaviCostiDirtyElabora().lineeBpDirty*/

      this.situazioneDebitoriaValid = this.businessPlanTemp.idStatoPatrimonialePassivo != 0 ? this.situazioneDebitoriaComponent.isDebitiValid() : true;
      this.situazioneCreditiziaValid = this.businessPlanTemp.idStatoPatrimonialeAttivo != 0 ? this.situazioneCreditiziaComponent.isCreditiValid() : true;
      this.indicatoriGuidaBpValid = this.indicatoriGuidaBpComponent.isFormValid()
      this.investimentiDisinvestimentiValid = this.investimentiDisinvestimentiComponent.isFormValid()
      this.magazzinoValid = this.presenzaMagazzino ? this.configuarazioneMagazzinoComponent.isFormValid() : true
      this.ricaviCostiTotValid = this.inserimentoRicaviCostiComponent.RicaviCostiValidElabora().ricaviCostiFormValid; // è true solo se almeno una linea è stata inserita (sporcata)      
      /* this.inserimentoRicaviCostiComponent.ricaviCostiDisabled ? false : */
      this.ricaviCostiValid = this.inserimentoRicaviCostiComponent.RicaviCostiValidElabora().lineaBusinessArrayValid
      /* modale errore sezione */
      if (!this.situazioneDebitoriaValid || !this.situazioneCreditiziaValid || !this.indicatoriGuidaBpValid || !this.investimentiDisinvestimentiValid || !this.magazzinoValid || !this.ricaviCostiTotValid) {
        if (this.modalService.hasOpenModals) {
          this.modalService.dismissAll(); /*chiude tutto per sicurezza? */
        }
        /* modale salva */
        const modalRef = this.modalService.open(ModalcontentComponent, {
          backdrop: 'static',
          backdropClass: 'customBackdrop',
        });
        modalRef.componentInstance.modalPar = {
          name: "errore",
          title: "Errore",
          description: "Le seguenti sezioni risultano incomplete: " + (this.situazioneDebitoriaValid ? '' : ' Situazione debitoria,') + (!this.situazioneCreditiziaValid ? ' Situazione creditizia,' : '')
            + (!this.investimentiDisinvestimentiValid ? ' investimenti/disinvestimenti,' : '') + (!this.indicatoriGuidaBpValid ? ' Indicatori guida,' : '')
            + (!this.ricaviCostiTotValid ? 'Pianificazione ricavi e costi,' : '') + (this.magazzinoValid ? '' : ' Magazzino' + "."),
          actionButtonText: "",
          undoButtonText: "Ok",
        }
        modalRef.result.then(r => {
        })
      }
      if (this.situazioneDebitoriaValid && this.situazioneCreditiziaValid && this.indicatoriGuidaBpValid && this.investimentiDisinvestimentiValid && this.magazzinoValid && this.ricaviCostiTotValid) {

        this.questionarioBp = {
          situazioneDebitoria: this.businessPlanTemp.idStatoPatrimonialePassivo != 0 ? this.situazioneDebitoriaComponent.elaboraSituazioneDebitoria() : null,
          indicatoriGuidaBp: this.indicatoriGuidaBpComponent.elaboraIndicatoriGuidaBpComponent(),
          investimentiDisinvestimenti: this.investimentiDisinvestimentiComponent.elaboraInvestimentiDisinvestimenti(),
          magazzino: this.presenzaMagazzino ? this.configuarazioneMagazzinoComponent.elaboraConfiguarazioneMagazzino() : null,
          situazioneCreditizia: this.businessPlanTemp.idStatoPatrimonialeAttivo != 0 ? this.situazioneCreditiziaComponent.elaboraSituazioneCreditizia() : null,
          ricaviCosti: this.inserimentoRicaviCostiComponent.elaboraRicaviCosti(),
        }
        /* idContoEconomico */
        if (this.businessPlanTemp.idStatoPatrimonialePassivo == 0) {
          this.questionarioBp.situazioneDebitoria = {
            autoliqAccordato: 0,
            autoliqUtilizzato: 0,
            scadenzaAccordato: 0,
            scadenzaUtilizzato: 0,
            revocaAccordato: 0,
            revocaUtilizzato: 0,
            idBusinessPlan: this.businessPlanTemp.id,
            anno: 0,
            debitoTot: 0,
            debito: [],
            id: 0,
            idRichiedente: this.businessPlanTemp.idAziendaRichiedente

          };
        }
        if (this.businessPlanTemp.idStatoPatrimonialeAttivo == 0) {
          this.questionarioBp.situazioneCreditizia = {
            idBusinessPlan: this.businessPlanTemp.id,
            anno: 0,
            creditoTot: 0,
            credito: [],
            id: 0,
            idRichiedente: this.businessPlanTemp.idAziendaRichiedente
          };
        }

        this.configurazioneBusinessPlanService.isRisultatiVolatile(isVolatile)//this.configurazioneBpForm.touched
        if (isVolatile) {
          if (this.businessPlanTemp.idContoEconomico == 0) {
            this.configurazioneBusinessPlanService.inviaAnnoBilancio(0);

          }
          this.configurazioneBusinessPlanService.ElaboraBp(this.questionarioBp)
          this.configurazioneBusinessPlanService.changeBpSalvato(false);
        }


        this.router.navigate(['/preview'])
      } else {
        // non fa niente
      }
    } else {
      this.configurazioneBusinessPlanService.isRisultatiVolatile(false)
      this.router.navigate(['/preview'])
    }



  }
  onChangeTab() {
    //Indice 5 è il magazzino
    if (this.selectedIndex == 5) {
      this.ricaviInizialiArray = []
      this.ricaviInizialiArray = this.inserimentoRicaviCostiComponent.getRicaviComplessivi()
    }//Indice 4 è ricavi e costi
    else if (this.selectedIndex == 4) {
      this.inizioDurata = this.indicatoriGuidaBpComponent.returnAnniBp();
    }

    /* scrolla fino alla fine */
    /* var resizeBodyIndicatori = document.querySelector("#addPaddingInStickyIndicatori") as HTMLElement;
    if (resizeBodyIndicatori != undefined) {
      window.scrollTo(document.body.scrollWidth, document.body.scrollHeight - 56);
    }
    else {
      window.scrollTo(108, document.body.scrollHeight);
    } */
  }

  resetCountCostiEricavi() {
    this.configurazioneBusinessPlanService.resetRicaviCostiCount();
  }

  ngOnDestroy(): void {
    //this.risultatiVolatileSubscription.unsubscribe();
    this.currentBpSubscription.unsubscribe()
    this.checkBpSalvatoSubscription.unsubscribe();
    //this.calcolaStickySub.unsubscribe();
    //this.setPaddingTopRicaviCostiSub.unsubscribe();
    //this.setPaddingTopDebitoriaSub.unsubscribe()
  }
}
