<div class="container-fluid no padding sticky-bp" id="configurazione-ambiente" [formGroup]="configurazioneAmbienteForm">
  <div class="card-header">
    <h2 style="margin-bottom:0;">Configurazione ambiente per azienda</h2>
    <div class=" row d-flex justify-content-between">
      <div class="col-4" *ngIf="listaRichiedenti.length > 0">
        <div class="row margin-bottom-10">
          <div class="col-10">
            <Label>Scegliere l'azienda osservata</Label>
            <select type="text" formControlName="nomeAzienda" class="form-control form-select"
              (change)="onChangeNomeAzienda()" [ngClass]="{ 'is-invalid': submitted && f.nomeAzienda.errors }">
              <option [ngValue]="nomeAzienda" *ngFor="let nomeAzienda of listaNomiAzienda">{{nomeAzienda}}
              </option>
            </select>
            <div *ngIf="submitted && f.nomeAzienda.errors" class="invalid-feedback">
              <div *ngIf="f.nomeAzienda.errors.required">Campo obbligatorio</div>
            </div>
          </div>
          <div ngClass="col-1" *ngIf="currentUser.cliente.idTipoCliente==2"
            class="col-1 d-flex align-items-end justify-content-start">
            <button *ngIf="f.nomeAzienda.disabled" class=" btn btn-secondary" style="padding: 3px 12px" type="button"
              (click)="unlock()" placement="top" ngbTooltip="Sblocca per cambiare il nome azienda">Sblocca
            </button>
            <!-- <button *ngIf="f.nomeAzienda.disabled" class=" btn fa fa-lock fa-lg pointer" aria-hidden="true"
                            type="button" (click)="unlock()" placement="top"
                            ngbTooltip="Sblocca per cambiare il nome azienda">
                        </button>
                        <button *ngIf="f.nomeAzienda.enabled" class=" btn fa fa-unlock fa-lg pointer" aria-hidden="true"
                            type="button">
                        </button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- selectedIndex proprietà delle mat tab -->
  <div><!-- class="card-body" -->
    <mat-tab-group mat-stretch-tabs [(selectedIndex)]="selectedIndex" class="d-flex justify-content-around">
      <!-- (selectedTabChange)="resetScrollingSize()" -->
      <mat-tab label="Dossier amministrativi" [labelClass]="{'label-error':false, 'label-correct':false}">
        <!-- *ngIf="presenzaBilancio" -->
        <app-dossier-amministrativi [idRichiedenteTemp]="richiedenteTemp !=null ? richiedenteTemp.id : null"
          [tabIndex]="selectedIndex">
          <!-- [dossierAmministrativiForm]="f.dossierAmministrativi" -->
        </app-dossier-amministrativi>
      </mat-tab>
      <mat-tab label="Situazione finanziaria" *ngIf="richiedenteTemp!=null && !richiedenteTemp.isStartUp">
        <mat-tab-group mat-stretch-tabs (selectedIndex)="0" class="d-flex justify-content-around">
          <mat-tab label="Situazione creditizia">
            <app-situazione-creditizia-ambiente [creditiformGroup]="f.situazioneCreditizia"
              [idRichiedenteTemp]="richiedenteTemp !=null ? richiedenteTemp.id : null">
            </app-situazione-creditizia-ambiente>
          </mat-tab>
          <mat-tab label="Situazione debitoria">
            <app-situazione-debitoria-ambiente [debitiformGroup]="f.situazioneDebitoria"
              [idRichiedenteTemp]="richiedenteTemp !=null ? richiedenteTemp.id : null">
            </app-situazione-debitoria-ambiente>
          </mat-tab>
        </mat-tab-group>

      </mat-tab>
      <mat-tab label="Parametri generali">
        <app-parametri-generali [idRichiedenteTemp]="richiedenteTemp !=null ? richiedenteTemp.id : null"
          [atecoPrimario]="codiceAtecoPrimario" [submitted]="isDirtyParametri">
        </app-parametri-generali>
      </mat-tab>
      <mat-tab label="Linee di business">
        <app-linee-business [richiedenteTemp]="richiedenteTemp" [submitted]="isDirtyLinee">

        </app-linee-business>
      </mat-tab>

      <mat-tab label="Correlazioni linee business">
        <app-configurazione-correlazioni-ambiente
          [idRichiedenteTemp]="richiedenteTemp !=null ? richiedenteTemp.id : null"></app-configurazione-correlazioni-ambiente>
      </mat-tab>

      <mat-tab label="Distinta base prodotti">
        <app-distinta-base-prodotti [idRichiedenteTemp]="richiedenteTemp !=null ? richiedenteTemp.id : null"
          [isDirtyProdotti]="isDirtyProdotti" [tabIndex]="selectedIndex">

        </app-distinta-base-prodotti>
      </mat-tab>



    </mat-tab-group>
  </div>
</div>