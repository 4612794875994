import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faTrashAlt, faPlus, faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { BeanStatico } from 'src/app/condivisi/beanStatico';
import { PraticheNuova } from 'src/app/interface/Pratiche-nuova';
import { DebitoBp, DettaglioDebitoBp, SituazioneDebitoria, suddivisioneAffidamentiBp, suddivisioneDebitoBp } from 'src/app/interface/situazione-debitoria';
import { ToolTip } from 'src/app/interface/toolTip';
import { ModalcontentComponent } from 'src/app/modalcontent/modalcontent/modalcontent.component';
import { BilancioService } from 'src/app/_services/bilancio.service';
import { ConfigurazioneAmbienteService } from 'src/app/_services/configurazione-ambiente.service';
import { ConfigurazioneBusinessPlanService } from 'src/app/_services/configurazione-business-plan.service';
import { ToolTipService } from 'src/app/_services/tool-tip.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';

@Component({
  selector: 'app-situazione-debitoria-nuovo',
  templateUrl: './situazione-debitoria-nuovo.component.html',
  styleUrls: ['./situazione-debitoria-nuovo.component.css']
})
export class SituazioneDebitoriaNuovoComponent implements OnInit {

  @Input() businessPlanTemp: PraticheNuova
  @Input() submittedDebiti: boolean
  @Input() situazioneDebitoriaSaved: boolean
  @Input() situazioneDebitoriaVolatile: SituazioneDebitoria
  @Input() debitiformGroup: UntypedFormGroup;

  @Output() emitDebitoriaFormValid = new EventEmitter<any>()
  risultatiVolatileSubscription: Subscription
  isRisultatiVolatile: boolean = false

  faCaretUp = faCaretUp;
  faCaretDown = faCaretDown;
  faTrashAlt = faTrashAlt;
  faPlus = faPlus;

  tipsSubscription: Subscription

  erroreSommaTotale: boolean = false //per bilancio abbreviato dove non ho i campi già impostati
  erroreSommaParziale: boolean[];
  situazioneDebitoriaIsValid: boolean = true

  tipoDebitoList: number[] = [];
  situazioneDebitoria: SituazioneDebitoria
  totaleDebiti = 0;
  anniBilancio = 0;
  debitiDirty: boolean = false
  openCheckBoxComponent = false
  listaMesi: Date[] = [];
  listaTipoDebitoBean: BeanStatico[] = []
  currentAnno = new Date().getFullYear()
  bilancioAbbreviato: boolean = false //da sistemare servono chiamate di aggiornamento ogni volt CHE inserisce un nuovo bilancio
  listaTips: ToolTip[] = [];
  nomeDebito: string[] = []

  valoreMin: any = {};

  mesiList: Date[]
  listaNumeroRate: number[]
  tipoRateList: BeanStatico[]

  constructor(private formBuilder: UntypedFormBuilder,
    private configurazioneAmbienteService: ConfigurazioneAmbienteService,
    private confService: ConfigurazioneBusinessPlanService,
    private modalService: NgbModal,
    private toolTipService: ToolTipService,
    private tokenService: TokenStorageService,
    private bilancioService: BilancioService) {
  }
  get f() { return this.debitiformGroup.controls }
  get debitiArray(): UntypedFormArray { return <UntypedFormArray> this.f.arrayDebitoria; }
  get debitiFormArray(): UntypedFormGroup[] { return <UntypedFormGroup[]> this.debitiArray.controls; }

  ngOnInit(): void {
    this.debitiformGroup = this.formBuilder.group({
      arrayDebitoria: this.formBuilder.array([]),
    })
    this.listaNumeroRate = []
    for (let i = 0; i < 500; i++) {
      this.listaNumeroRate.push(i + 1)
    }
    if (this.businessPlanTemp != null && this.businessPlanTemp.bilancioAbbreviato) {
      //importo la lista debiti vergine dal serivice
      this.confService.getListaDebiti().subscribe(list => {
        list.forEach(item => {
          this.listaTipoDebitoBean.push({ id: item.id, descrizione: item.descrizione, checked: false })
        })
      })
    }
    this.listaMesi = []
    for (let i = 0; i < 12; i++) {
      this.listaMesi.push(new Date(this.currentAnno, i))
    }
    this.risultatiVolatileSubscription = this.confService.currentRisultatiVolatile.subscribe(siVolatile => {
      this.isRisultatiVolatile = siVolatile
    })
      ;
    this.configurazioneAmbienteService.getValoriMax('situazione debitoria').subscribe(response => {
      response.forEach(element => {
        if (element.error == null) {
          this.valoreMin[element.nomeCampo] = element.valoreMax;
        }

      })
    })
    this.tipsSubscription = this.toolTipService.tipsArray.subscribe(arr => {
      if (arr != null) {
        this.listaTips = arr
      }
    })
    this.configurazioneAmbienteService.getListaTipoRate().subscribe(list => {
      this.tipoRateList = list
      this.getSituazioneDebitoria();
    })
  }

  getSituazioneDebitoria() {
    /* rifare */
    if (this.businessPlanTemp) {
      this.nomeDebito = [];
      this.debitiArray.clear()
      this.tipoDebitoList = []
      this.erroreSommaParziale = []
      if (this.isRisultatiVolatile && this.situazioneDebitoriaVolatile != null) {
        this.anniBilancio = this.situazioneDebitoriaVolatile.anno
        this.situazioneDebitoria = this.situazioneDebitoriaVolatile;
        this.totaleDebiti = this.situazioneDebitoriaVolatile.debitoTot;
        if (this.totaleDebiti > 0) {
          if (this.situazioneDebitoriaVolatile.debito.length) {
            this.situazioneDebitoriaVolatile.debito.forEach((debito, j) => {
              this.nomeDebito.push(debito.nome)
              this.tipoDebitoList.push(debito.idTipoDebito)
              let suddivisioneDebitoForm: UntypedFormGroup[] = [];
              let suddivisioneAutoliqForm: UntypedFormGroup[] = [];
              let suddivisioneRevocaForm: UntypedFormGroup[] = [];

              this.erroreSommaParziale.push(false)
              if (debito.debitiBpList && debito.debitiBpList.length > 0) {

                debito.debitiBpList.forEach(dettaglio => {
                  //gestire tutte le select
                  let tipoRata: BeanStatico;
                  this.tipoRateList.forEach(tipo => {
                    if (tipo.id == dettaglio.idTipoRata) {
                      tipoRata = tipo
                    }
                  })
                  suddivisioneDebitoForm.push(this.formBuilder.group({
                    descrizione: [dettaglio.descrizione, Validators.required], //anche a scandenza
                    importo: [dettaglio.importo, Validators.required], //anche a scandenza
                    importoRinegoziato: [dettaglio.importoRinegoziato, this.tipoDebitoList[j] != 4 ? Validators.required : null],
                    numeroRate: [dettaglio.numeroRate, Validators.required], //anche a scandenza
                    annoInizioRimborso: [dettaglio.annoInizioRimborso, Validators.required],
                    meseInizioRimborso: [dettaglio.idMeseInizioRimborso, Validators.required], //anche a scandenza
                    tipologiaRata: [tipoRata, Validators.required], //anche a scandenza
                    //banche
                  }))
                })
              }
              if (this.tipoDebitoList[j] == 4) {
                if (debito.affidamentiBpList && debito.affidamentiBpList.length > 0) {
                  debito.affidamentiBpList.forEach(affidamenti => {
                    if (affidamenti.idTipoAffidamento == 1) {
                      let residuo = affidamenti.accordato - affidamenti.utilizzato
                      suddivisioneAutoliqForm.push(this.formBuilder.group({
                        autoliqDescrizione: [affidamenti.descrizione, Validators.required],
                        autoliqUtilizzato: [affidamenti.utilizzato, Validators.required],
                        autoliqAccordato: [affidamenti.accordato, Validators.required],
                        autoliqRimanenze: [{ value: residuo, disabled: true }, Validators.required],
                      }))
                    } else {
                      let residuo = affidamenti.accordato - affidamenti.utilizzato
                      suddivisioneRevocaForm.push(this.formBuilder.group({
                        revocaDescrizione: [affidamenti.descrizione, Validators.required],
                        revocaUtilizzato: [affidamenti.utilizzato, Validators.required],
                        revocaAccordato: [affidamenti.accordato, Validators.required],
                        revocaRimanenze: [{ value: residuo, disabled: true }, Validators.required],
                      }))
                    }

                  })
                }
              }
              this.debitiArray.push(this.formBuilder.group({
                totale: [{ value: debito.totale, disabled: true }, Validators.required],
                suddivisioneAutoliq: this.formBuilder.array(suddivisioneAutoliqForm),
                suddivisioneRevoca: this.formBuilder.array(suddivisioneRevocaForm),
                suddivisioneDebiti: this.formBuilder.array(suddivisioneDebitoForm),

              }))
              /* questa parte serve a gestire il valuechange sul totale di debiti fornitori. lo devo carica dopo altrimenti non posso mandarlo a disinvesatimenti */
              if (this.businessPlanTemp.bilancioAbbreviato && debito.idTipoDebito == 7) {
                this.debitiFormArray[j].controls.totale.valueChanges.subscribe(tot => {
                  this.bilancioService.checkDebitoFornitoriDebitoria(parseFloat(tot));
                })
                this.debitiFormArray[j].controls.totale.setValue(debito.totale)

              }
            })
          }
        }
        //this.aggiornaListaCheckBox() non serve più se lo faccio al click
      } else {
        this.confService.getSituazioneDebitoriaNew(this.businessPlanTemp.id, this.businessPlanTemp.idAziendaRichiedente).subscribe(response => {
          if (response.error == null) {
            this.situazioneDebitoria = response;
            this.anniBilancio = this.situazioneDebitoria.anno
            this.totaleDebiti = this.situazioneDebitoria.debitoTot;
            if (this.totaleDebiti != 0) {
              if (this.situazioneDebitoria.debito.length > 0) {
                this.situazioneDebitoria.debito.forEach((debito, j) => {
                  this.nomeDebito.push(debito.nome)
                  this.tipoDebitoList.push(debito.idTipoDebito)
                  let suddivisioneDebitoForm: UntypedFormGroup[] = [];
                  let suddivisioneAutoliqForm: UntypedFormGroup[] = [];
                  let suddivisioneRevocaForm: UntypedFormGroup[] = [];

                  this.erroreSommaParziale.push(false)
                  if (debito.debitiBpList && debito.debitiBpList.length > 0) {
                    debito.debitiBpList.forEach(dettaglio => {
                      //gestire tutte le select
                      let tipoRata: BeanStatico;
                      this.tipoRateList.forEach(tipo => {
                        if (tipo.id == dettaglio.idTipoRata) {
                          tipoRata = tipo
                        }
                      })
                      suddivisioneDebitoForm.push(this.formBuilder.group({
                        descrizione: [dettaglio.descrizione, Validators.required], //anche a scandenza
                        importo: [dettaglio.importo, Validators.required], //anche a scandenza
                        importoRinegoziato: [dettaglio.importoRinegoziato, this.tipoDebitoList[j] != 4 ? Validators.required : null],
                        numeroRate: [dettaglio.numeroRate, Validators.required], //anche a scandenza
                        annoInizioRimborso: [dettaglio.annoInizioRimborso, Validators.required],
                        meseInizioRimborso: [dettaglio.idMeseInizioRimborso, Validators.required], //anche a scandenza
                        tipologiaRata: [tipoRata, Validators.required], //anche a scandenza
                        //banche
                      }))
                    })
                  }
                  if (this.tipoDebitoList[j] == 4) {
                    if (debito.affidamentiBpList && debito.affidamentiBpList.length > 0) {
                      debito.affidamentiBpList.forEach(affidamenti => {
                        if (affidamenti.idTipoAffidamento == 1) {
                          let residuo = affidamenti.accordato - affidamenti.utilizzato
                          suddivisioneAutoliqForm.push(this.formBuilder.group({
                            autoliqDescrizione: [affidamenti.descrizione, Validators.required],
                            autoliqUtilizzato: [affidamenti.utilizzato, Validators.required],
                            autoliqAccordato: [affidamenti.accordato, Validators.required],
                            autoliqRimanenze: [{ value: residuo, disabled: true }, Validators.required],
                          }))
                        } else {
                          let residuo = affidamenti.accordato - affidamenti.utilizzato
                          suddivisioneRevocaForm.push(this.formBuilder.group({
                            revocaDescrizione: [affidamenti.descrizione, Validators.required],
                            revocaUtilizzato: [affidamenti.utilizzato, Validators.required],
                            revocaAccordato: [affidamenti.accordato, Validators.required],
                            revocaRimanenze: [{ value: residuo, disabled: true }, Validators.required],
                          }))
                        }

                      })
                    }
                  }
                  this.debitiArray.push(this.formBuilder.group({
                    totale: [{ value: debito.totale, disabled: true }, Validators.required],
                    suddivisioneAutoliq: this.formBuilder.array(suddivisioneAutoliqForm),
                    suddivisioneRevoca: this.formBuilder.array(suddivisioneRevocaForm),
                    suddivisioneDebiti: this.formBuilder.array(suddivisioneDebitoForm),

                  }))
                  /* questa parte serve a gestire il valuechange sul totale di debiti fornitori. lo devo carica dopo altrimenti non posso mandarlo a disinvesatimenti */
                  if (this.businessPlanTemp.bilancioAbbreviato && debito.idTipoDebito == 7) {
                    this.debitiFormArray[j].controls.totale.valueChanges.subscribe(tot => {
                      this.bilancioService.checkDebitoFornitoriDebitoria(parseFloat(tot));
                    })
                    this.debitiFormArray[j].controls.totale.setValue(debito.totale)
                  }
                })

              }
            }
          }
        })
      }
    }
  }
  //serve a tenere sempre aggiornata la lista dei debiti che l'utente inserisce
  /* modificare */
  aggiornaListaCheckBox() {
    if (this.openCheckBoxComponent == false) {
      for (let i = 0; i < this.listaTipoDebitoBean.length; i++) {
        this.listaTipoDebitoBean[i].checked = false;// setto sempre il checked della lista a false all 'inizio poi porto a true solo quelli che
        //soddisfano la cond (mi evita di mettere l'else a riga 393 che rompe la logica)
        if (this.tipoDebitoList.length > 0) {
          for (let j = 0; j < this.tipoDebitoList.length; j++) {
            if (this.listaTipoDebitoBean[i].id == this.tipoDebitoList[j]) {
              this.listaTipoDebitoBean[i].checked = true;
            }
          }
        }
      }
    }
    this.openCheckBoxComponent = !this.openCheckBoxComponent
  }
  /* modificare */
  ModificaListaDebiti(listaDebitiNew: BeanStatico[]) {// se cambio il numero di debiti devo aggiornare l'array
    this.debitiformGroup.markAsDirty()
    let suddivisioneDebitoForm: UntypedFormGroup[] = [];
    let suddivisioneAutoliqForm: UntypedFormGroup[] = [];
    let suddivisioneRevocaForm: UntypedFormGroup[] = [];
    suddivisioneAutoliqForm.push(this.formBuilder.group({
      autoliqDescrizione: ['', Validators.required],
      autoliqUtilizzato: ['', Validators.required],
      autoliqAccordato: ['', Validators.required],
      autoliqRimanenze: [{ value: '', disabled: true }, Validators.required],
    }))
    suddivisioneRevocaForm.push(this.formBuilder.group({
      revocaDescrizione: ['', Validators.required],
      revocaUtilizzato: ['', Validators.required],
      revocaAccordato: ['', Validators.required],
      revocaRimanenze: [{ value: '', disabled: true }, Validators.required],
    }))
    suddivisioneDebitoForm.push(this.formBuilder.group({
      descrizione: ['', Validators.required], //anche a scandenza
      importo: ['', Validators.required], //anche a scandenza
      importoRinegoziato: ['0', Validators.required],
      numeroRate: ['', Validators.required], //anche a scandenza
      annoInizioRimborso: ['0', Validators.required], //ho dovuto levare il validatore per i rischi a scadenza
      meseInizioRimborso: ['', Validators.required], //anche a scandenza
      tipologiaRata: ['', Validators.required], //anche a scandenza
      //banche
    }))
    if (this.debitiArray.length > 0) {
      for (let i = 0; i < listaDebitiNew.length; i++) {
        if (listaDebitiNew[i].checked) {
          //includes controlla se è presente un elemento di listaDebitiNew[i] senza ciclare su tipoDebitoList
          if (!this.tipoDebitoList.includes(listaDebitiNew[i].id)) {
            this.nomeDebito.push(listaDebitiNew[i].descrizione)
            this.erroreSommaParziale.push(false)
            this.debitiArray.push(this.formBuilder.group({
              totale: ['0', Validators.required],
              suddivisioneAutoliq: this.formBuilder.array(listaDebitiNew[i].id == 4 ? suddivisioneAutoliqForm : []),
              suddivisioneRevoca: this.formBuilder.array(listaDebitiNew[i].id == 4 ? suddivisioneRevocaForm : []),
              suddivisioneDebiti: this.formBuilder.array(suddivisioneDebitoForm),
            }))
          }
          this.tipoDebitoList.push(listaDebitiNew[i].id)
          //eliminare pezzi
        } else {
          let arr = []
          //arr contiene gli elementi dell'Array che soddisfano la condizione del filter
          //in questo caso avrà sempre solo un elmento perchè la condizione è su un id
          arr = this.tipoDebitoList.filter(obj => { return obj == listaDebitiNew[i].id })
          if (arr.length > 0) {
            this.nomeDebito.splice(this.tipoDebitoList.indexOf(arr[0]), 1)
            this.debitiArray.removeAt(this.tipoDebitoList.indexOf(arr[0]));
            this.debitiArray.updateValueAndValidity()
            this.tipoDebitoList.splice(this.tipoDebitoList.indexOf(arr[0]), 1)
          }
        }
      }
    } else {
      //caso t=0  form vuoto (devo pushare tutto da 0)
      listaDebitiNew.forEach(debito => {
        if (debito.checked) {
          this.nomeDebito.push(debito.descrizione)
          this.tipoDebitoList.push(debito.id)
          //errori liste
          this.debitiArray.push(this.formBuilder.group({
            totale: ['0', Validators.required],
            suddivisioneAutoliq: this.formBuilder.array(suddivisioneAutoliqForm),
            suddivisioneRevoca: this.formBuilder.array(suddivisioneRevocaForm),
            suddivisioneDebiti: this.formBuilder.array(suddivisioneDebitoForm),
          })
          )
        }
      })
    }

    this.openCheckBoxComponent = false;
    //alla fine bisogna ri aggiornare la lista delle checkbox (forse anche l'idTipoDebito) e tutti gli array di errore

  }

  isDebitiValid(): boolean {
    let sommaTot = 0
    let sommaParzialeIsValid = true;
    this.situazioneDebitoriaIsValid = true
    let debitiFormGroupValid = true;
    for (let i = 0; i < this.debitiFormArray.length; i++) {
      sommaTot += +this.debitiFormArray[i].controls.totale.value
      let suddivisioneDebitiGroup = <UntypedFormGroup[]> (<UntypedFormArray> this.debitiFormArray[i].controls.suddivisioneDebiti).controls
      let sommaParziale = 0
      //controllo somma parziale
      suddivisioneDebitiGroup.forEach(debitoForm => {
        sommaParziale += +debitoForm.controls.importo.value
        debitoForm.updateValueAndValidity()

        debitiFormGroupValid = debitiFormGroupValid && debitoForm.valid
      })
      if (this.tipoDebitoList[i] == 4) {

        let suddivisioneAutoliqGroup = <UntypedFormGroup[]> (<UntypedFormArray> this.debitiFormArray[i].controls.suddivisioneAutoliq).controls
        let suddivisioneRevocaGroup = <UntypedFormGroup[]> (<UntypedFormArray> this.debitiFormArray[i].controls.suddivisioneRevoca).controls
        suddivisioneAutoliqGroup.forEach(autoliqForm => {
          sommaParziale += +autoliqForm.controls.autoliqUtilizzato.value
          debitiFormGroupValid = debitiFormGroupValid && autoliqForm.valid
        })
        suddivisioneRevocaGroup.forEach(revocaForm => {
          sommaParziale += +revocaForm.controls.revocaUtilizzato.value
          debitiFormGroupValid = debitiFormGroupValid && debitiFormGroupValid
        })

      }
      if (sommaParziale != +this.debitiFormArray[i].controls.totale.value) {
        this.erroreSommaParziale.splice(i, 1, true)
      } else {
        this.erroreSommaParziale.splice(i, 1, false)
      }

      sommaParzialeIsValid = sommaParzialeIsValid && !this.erroreSommaParziale[i]

    }
    //somma importo debit==totale (per debiti bilancio abbreviato dove li inserisce lui manualmente)
    if (sommaTot != this.totaleDebiti) {
      this.erroreSommaTotale = true
    } else {
      this.erroreSommaTotale = false
    }

    return this.situazioneDebitoriaIsValid = !this.erroreSommaTotale && sommaParzialeIsValid && debitiFormGroupValid
  }
  /* modificare */
  onSubmitDebiti() {
    if (this.submittedDebiti) {//this.situazioneDebitoriaValid
      let request: SituazioneDebitoria;
      let listDebiti: DebitoBp[] = []
      for (let index = 0; index < this.debitiFormArray.length; index++) {
        let suddivisioneDebitoForm = <UntypedFormGroup[]> (<UntypedFormArray> this.debitiFormArray[index].controls.suddivisioneDebiti).controls;
        let listaDettaglioDebito: suddivisioneDebitoBp[] = []
        let listaAffidamenti: suddivisioneAffidamentiBp[] = []
        for (let j = 0; j < suddivisioneDebitoForm.length; j++) {
          listaDettaglioDebito.push({
            id: 0,
            idBusinessPlan: this.businessPlanTemp.id,
            idTipoDebito: this.tipoDebitoList[index],
            idTipoRata: suddivisioneDebitoForm[j].controls.tipologiaRata.value.id,
            descrizione: suddivisioneDebitoForm[j].controls.descrizione.value,
            importo: suddivisioneDebitoForm[j].controls.importo.value,
            importoRinegoziato: suddivisioneDebitoForm[j].controls.importoRinegoziato.value,
            numeroRate: suddivisioneDebitoForm[j].controls.numeroRate.value,
            idMeseInizioRimborso: suddivisioneDebitoForm[j].controls.meseInizioRimborso.value,
            annoInizioRimborso: suddivisioneDebitoForm[j].controls.annoInizioRimborso.value,
          })
        }

        if (this.tipoDebitoList[index] == 4) {
          let suddivisionRevocaForm = <UntypedFormGroup[]> (<UntypedFormArray> this.debitiFormArray[index].controls.suddivisioneRevoca).controls;
          let suddivisioneAutoliqForm = <UntypedFormGroup[]> (<UntypedFormArray> this.debitiFormArray[index].controls.suddivisioneAutoliq).controls;
          for (let j = 0; j < suddivisionRevocaForm.length; j++) {
            listaAffidamenti.push({
              id: 0,
              idBusinessPlan: this.businessPlanTemp.id,
              idTipoAffidamento: 2,
              descrizione: suddivisionRevocaForm[j].controls.revocaDescrizione.value,
              accordato: suddivisionRevocaForm[j].controls.revocaAccordato.value,
              utilizzato: suddivisionRevocaForm[j].controls.revocaUtilizzato.value,

            })
          }
          for (let j = 0; j < suddivisioneAutoliqForm.length; j++) {
            listaAffidamenti.push({
              id: 0,
              idBusinessPlan: this.businessPlanTemp.id,
              idTipoAffidamento: 1,
              descrizione: suddivisioneAutoliqForm[j].controls.autoliqDescrizione.value,
              accordato: suddivisioneAutoliqForm[j].controls.autoliqAccordato.value,
              utilizzato: suddivisioneAutoliqForm[j].controls.autoliqUtilizzato.value,
            })
          }

        }

        listDebiti.push({
          id: 0,
          idTipoDebito: this.tipoDebitoList[index],
          nome: this.situazioneDebitoria.debito[index].nome,
          totale: this.totaleDebiti,
          debitiBpList: listaDettaglioDebito,
          affidamentiBpList: listaAffidamenti
        })
      }
      request = {
        id: 0,
        idRichiedente: this.businessPlanTemp.id,
        debito: listDebiti,
        debitoTot: this.situazioneDebitoria.debitoTot,
        anno: this.situazioneDebitoria.anno,
        idBusinessPlan: this.businessPlanTemp.id,

        error: null,
      }
      this.markFormDebitoPristine()
      return request
      /* } */
    } else {
      return null
    }
  }
  /* modificare */
  elaboraSituazioneDebitoria() {
    if (this.situazioneDebitoriaIsValid) {

      let request: SituazioneDebitoria;
      let listDebiti: DebitoBp[] = []

      for (let index = 0; index < this.debitiFormArray.length; index++) {
        let suddivisioneDebitoForm = <UntypedFormGroup[]> (<UntypedFormArray> this.debitiFormArray[index].controls.suddivisioneDebiti).controls;
        let listaDettaglioDebito: suddivisioneDebitoBp[] = []
        let listaAffidamenti: suddivisioneAffidamentiBp[] = []
        for (let j = 0; j < suddivisioneDebitoForm.length; j++) {
          listaDettaglioDebito.push({
            id: 0,
            idBusinessPlan: this.businessPlanTemp.id,
            idTipoDebito: this.tipoDebitoList[index],
            idTipoRata: suddivisioneDebitoForm[j].controls.tipologiaRata.value.id,
            descrizione: suddivisioneDebitoForm[j].controls.descrizione.value,
            importo: suddivisioneDebitoForm[j].controls.importo.value,
            importoRinegoziato: suddivisioneDebitoForm[j].controls.importoRinegoziato.value,
            numeroRate: suddivisioneDebitoForm[j].controls.numeroRate.value,
            idMeseInizioRimborso: suddivisioneDebitoForm[j].controls.meseInizioRimborso.value,
            annoInizioRimborso: suddivisioneDebitoForm[j].controls.annoInizioRimborso.value,
          })
        }

        if (this.tipoDebitoList[index] == 4) {
          let suddivisionRevocaForm = <UntypedFormGroup[]> (<UntypedFormArray> this.debitiFormArray[index].controls.suddivisioneRevoca).controls;
          let suddivisioneAutoliqForm = <UntypedFormGroup[]> (<UntypedFormArray> this.debitiFormArray[index].controls.suddivisioneAutoliq).controls;
          for (let j = 0; j < suddivisionRevocaForm.length; j++) {
            listaAffidamenti.push({
              id: 0,
              idBusinessPlan: this.businessPlanTemp.id,
              idTipoAffidamento: 2,
              descrizione: suddivisionRevocaForm[j].controls.revocaDescrizione.value,
              accordato: suddivisionRevocaForm[j].controls.revocaAccordato.value,
              utilizzato: suddivisionRevocaForm[j].controls.revocaUtilizzato.value,

            })
          }
          for (let j = 0; j < suddivisioneAutoliqForm.length; j++) {
            listaAffidamenti.push({
              id: 0,
              idBusinessPlan: this.businessPlanTemp.id,
              idTipoAffidamento: 1,
              descrizione: suddivisioneAutoliqForm[j].controls.autoliqDescrizione.value,
              accordato: suddivisioneAutoliqForm[j].controls.autoliqAccordato.value,
              utilizzato: suddivisioneAutoliqForm[j].controls.autoliqUtilizzato.value,
            })
          }

        }

        listDebiti.push({
          id: 0,
          idTipoDebito: this.tipoDebitoList[index],
          nome: this.situazioneDebitoria.debito[index].nome,
          totale: this.situazioneDebitoria.debito[index].totale,
          debitiBpList: listaDettaglioDebito,
          affidamentiBpList: listaAffidamenti
        })
      }
      request = {
        id: 0,
        idRichiedente: this.businessPlanTemp.idAziendaRichiedente,
        debito: listDebiti,
        debitoTot: this.situazioneDebitoria.debitoTot,
        anno: this.situazioneDebitoria.anno,
        idBusinessPlan: this.businessPlanTemp.id,

        error: null,
      }
      this.confService.inviaAnnoBilancio(this.anniBilancio);
      return request
    }
  }

  isFormDebitoDirty() {
    this.submittedDebiti = this.debitiformGroup.dirty
    return this.submittedDebiti;
  }

  markFormDebitoPristine() {
    this.debitiformGroup.markAsPristine()
    this.debitiDirty = false
  }

  annullaDebiti() {
    if (this.debitiformGroup.dirty) {
      this.submittedDebiti = false;
      if (this.modalService.hasOpenModals) {
        this.modalService.dismissAll();
      }
      const modalRef = this.modalService.open(ModalcontentComponent, {
        backdrop: 'static',
        backdropClass: 'customBackdrop',
      });
      modalRef.componentInstance.modalPar = {
        name: "annulla",
        title: "Sicuro di voler annullare ?",
        description: "Cliccando su 'Annulla' tutte le modifiche non salvate su questa pagina andranno perse.",
        undoButtonText: "Indietro",
        actionButtonText: "Annulla",
      }
      modalRef.result.then((r) => {
        if (r) {
          this.situazioneDebitoriaIsValid = true
          this.openCheckBoxComponent = false;
          this.debitiformGroup.reset();
          this.getSituazioneDebitoria();
          this.markFormDebitoPristine()
        } else { this.submittedDebiti = true }
      })
    }
  }

  suddividiDebito(index: number) {
    this.debitiformGroup.markAsDirty()
    if (this.tipoDebitoList[index] == 4) {
      (<UntypedFormArray> this.debitiFormArray[index].controls.suddivisioneAutoliq).controls.push(this.formBuilder.group({
        autoliqDescrizione: ['', Validators.required],
        autoliqUtilizzato: ['0', Validators.required],
        autoliqAccordato: ['0', Validators.required],
        autoliqRimanenze: [{ value: '0', disabled: true }, Validators.required],
      }));

      (<UntypedFormArray> this.debitiFormArray[index].controls.suddivisioneRevoca).controls.push(this.formBuilder.group({
        revocaDescrizione: ['', Validators.required],
        revocaUtilizzato: ['0', Validators.required],
        revocaAccordato: ['0', Validators.required],
        revocaRimanenze: [{ value: '0', disabled: true }, Validators.required],
      }))
    }
    (<UntypedFormArray> this.debitiFormArray[index].controls.suddivisioneDebiti).controls.push(this.formBuilder.group({
      descrizione: ['', Validators.required], //anche a scandenza
      importo: ['0', Validators.required], //anche a scandenza
      importoRinegoziato: ['0', this.tipoDebitoList[index] == 4 ? null : Validators.required],
      numeroRate: [1, Validators.required], //anche a scandenza
      annoInizioRimborso: ['', Validators.required],
      meseInizioRimborso: ['', Validators.required], //anche a scandenza
      tipologiaRata: ['', Validators.required],
    }))

  }

  //modifica importo rinegoziato
  setValoreRinegoziatoDebito(i: number, j: number) {
    let formgroup = <UntypedFormGroup[]> (<UntypedFormArray> this.debitiFormArray[i].controls.suddivisioneDebiti).controls
    if (formgroup[j].controls.importo.value != null) {
      formgroup[j].controls.importoRinegoziato.setValue(formgroup[j].controls.importo.value)
    } else {
      formgroup[j].controls.importoRinegoziato.setValue('0')
    }
  }
  // modifica il residuo in base a cosa cambio in accordato e utilizzato
  setResiduoAutoliquidante(i: number, j: number) {
    let formgroup = <UntypedFormGroup[]> (<UntypedFormArray> this.debitiFormArray[i].controls.suddivisioneAutoliq).controls
    if (formgroup[j].controls.autoliqAccordato.value != null && formgroup[j].controls.autoliqUtilizzato.value != null) {
      formgroup[j].controls.autoliqRimanenze.setValue(parseFloat(formgroup[j].controls.autoliqAccordato.value) - parseFloat(formgroup[j].controls.autoliqUtilizzato.value))
    }
  }
  setResiduoRevoca(i: number, j: number) {
    let formgroup = <UntypedFormGroup[]> (<UntypedFormArray> this.debitiFormArray[i].controls.suddivisioneRevoca).controls
    if (formgroup[j].controls.revocaAccordato.value != null && formgroup[j].controls.revocaUtilizzato.value != null) {
      formgroup[j].controls.revocaRimanenze.setValue(parseFloat(formgroup[j].controls.revocaAccordato.value) - parseFloat(formgroup[j].controls.revocaUtilizzato.value))
    }
  }

  onChangeAnno(i: number, j: number){
    let formgroup = <UntypedFormGroup[]> (<UntypedFormArray> this.debitiFormArray[i].controls.suddivisioneDebiti).controls
    if (formgroup[j].controls.annoInizioRimborso.value != null && formgroup[j].controls.annoInizioRimborso.value != "" && formgroup[j].controls.annoInizioRimborso.value <= this.anniBilancio) {
      formgroup[j].controls.annoInizioRimborso.setValue(this.anniBilancio +1)
    }
  }

  aggiungiAutoliq(index: number) {
    this.debitiformGroup.markAsDirty();
    (<UntypedFormArray> this.debitiFormArray[index].controls.suddivisioneAutoliq).controls.push(this.formBuilder.group({
      autoliqDescrizione: ['', Validators.required],
      autoliqUtilizzato: ['0', Validators.required],
      autoliqAccordato: ['0', Validators.required],
      autoliqRimanenze: [{ value: '0', disabled: true }, Validators.required],
    }));
  }
  aggiungiRevoca(index: number) {
    this.debitiformGroup.markAsDirty();
    (<UntypedFormArray> this.debitiFormArray[index].controls.suddivisioneRevoca).controls.push(this.formBuilder.group({
      revocaDescrizione: ['0', Validators.required],
      revocaUtilizzato: ['0', Validators.required],
      revocaAccordato: ['0', Validators.required],
      revocaRimanenze: [{ value: '0', disabled: true }, Validators.required],
    }))
  }
  aggiungiDebito(index: number) {
    this.debitiformGroup.markAsDirty();
    (<UntypedFormArray> this.debitiFormArray[index].controls.suddivisioneDebiti).controls.push(this.formBuilder.group({
      descrizione: ['', Validators.required], //anche a scandenza
      importo: ['0', Validators.required], //anche a scandenza
      importoRinegoziato: ['0', this.tipoDebitoList[index] == 4 ? null : Validators.required],
      numeroRate: [1, Validators.required], //anche a scandenza
      annoInizioRimborso: ['', Validators.required],
      meseInizioRimborso: ['', Validators.required], //anche a scandenza
      tipologiaRata: ['', Validators.required],
    }))
  }



  eliminaDebito(index: number, index2: number) {
    this.debitiformGroup.markAsDirty();
    if ((<UntypedFormArray> this.debitiFormArray[index].controls.suddivisioneDebiti).length > 1) {
      (<UntypedFormArray> this.debitiFormArray[index].controls.suddivisioneDebiti).removeAt(index2)
    }
  }
  eliminaRevoca(index: number, index2: number) {
    this.debitiformGroup.markAsDirty();
    if ((<UntypedFormArray> this.debitiFormArray[index].controls.suddivisioneRevoca).length > 1) {
      (<UntypedFormArray> this.debitiFormArray[index].controls.suddivisioneRevoca).removeAt(index2)
    }
  }
  eliminaAutoliq(index: number, index2: number) {
    this.debitiformGroup.markAsDirty();
    if ((<UntypedFormArray> this.debitiFormArray[index].controls.suddivisioneAutoliq).length > 1) {
      (<UntypedFormArray> this.debitiFormArray[index].controls.suddivisioneAutoliq).removeAt(index2)
    }
  }



  valoreRinegoziatoMax(i: number, j: number) {
    let formgroup = <UntypedFormGroup[]> (<UntypedFormArray> this.debitiFormArray[i].controls.suddivisioneDebiti).controls
    if (formgroup[j].controls.importo.value != null) {
      if (formgroup[j].controls.importoRinegoziato.value > formgroup[j].controls.importo.value)
        formgroup[j].controls.importoRinegoziato.setValue(formgroup[j].controls.importo.value)
    } else {
      formgroup[j].controls.importoRinegoziato.setValue('0')
    }
  }

  ngOnDestroy(): void {
    this.risultatiVolatileSubscription.unsubscribe()
    this.tipsSubscription.unsubscribe()
  }
}
