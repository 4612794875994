import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalcontentComponent } from '../modalcontent/modalcontent/modalcontent.component';
import { ConfigurazioneAmbienteService } from '../_services/configurazione-ambiente.service';
import { ConfigurazioneBusinessPlanService } from '../_services/configurazione-business-plan.service';
import { RichiedenteService } from '../_services/richiedente.service';
import { TokenStorageService } from '../_services/token-storage.service';
import { PraticheNuova } from './../interface/Pratiche-nuova';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { DettaglioRisultatiService } from '../_services/dettaglio-risultati.service';
import { SplashpageServiceService } from '../_services/splashpage-service.service';
import { MenuPulsantieraService } from '../header/services/menu-pulsantiera.service';
import { DatePipe } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { BeanStatico } from '../condivisi/beanStatico';
import { SmartBpService } from '../_services/smart-bp.service';



export interface BusinessPlanElement {
  riferimentoBp: string,
  idBusinessPlan: number,
  idAziendaRichiedente: number,
  nomeAzienda: number,
  dataCreazione: string,
  dataModifica: string,
  idStato: number,
  azioni: string[]
}
export interface BusinessPlanList {
  rows: BusinessPlanElement[];
}

@Component({
  selector: 'app-lista-bplan-nuova',
  templateUrl: './lista-bplan-nuova.component.html',
  styleUrls: ['./lista-bplan-nuova.component.css']
})
export class ListaBplanNuovaComponent implements OnInit {

  displayedColumns: string[] = ['nomeAzienda', 'riferimentoBp', 'dataModifica', 'dataCreazione', 'idStato', 'azioni'];
  @ViewChild(MatSort) sort: MatSort;//per sortare la tabella
  @ViewChild(MatPaginator) paginator!: MatPaginator; /* definizione del paginator della tabella*/
  listaIdRichiedenti: number[] = [];
  listaRichiedenti: any[] = [];
  bpList: PraticheNuova[] = [];
  listaBp: BusinessPlanList = { rows: [] }; //serve solo per tab material
  dataSource: MatTableDataSource<BusinessPlanElement>;
  listaRichiedentiConAmbiente: BeanStatico[] = [];


  currentUser: any;
  nuovoBp: PraticheNuova;
  constructor(
    private tokenService: TokenStorageService,
    private router: Router,
    private modalService: NgbModal,
    private configurazioneBpService: ConfigurazioneBusinessPlanService,
    private configurazioneAmbienteService: ConfigurazioneAmbienteService,
    private dettService: DettaglioRisultatiService,
    private splashService: SplashpageServiceService,
    private pulsantieraService: MenuPulsantieraService,
    private smartBpService: SmartBpService
  ) {
    this.dataSource = new MatTableDataSource(this.listaBp.rows);
  }


  ngOnInit(): void {
    this.configurazioneBpService.cancellaTutto();
    this.dettService.cancellaTutto();
    this.splashService.cancellaTutto();
    this.smartBpService.cancellaTutto();
    this.pulsantieraService.clickOnSintesi();
    this.configurazioneBpService.changeBpSalvato(false);
    this.currentUser = this.tokenService.getUser();
    this.listaBp.rows = [];
    this.bpList = [];

    this.configurazioneAmbienteService.listaRichiedentiConAmbiente(this.currentUser.idUtente).subscribe(response => {
      this.listaRichiedentiConAmbiente = response;

      const datePipe = new DatePipe('it');
      /* restituisce la lista di tutti i bp se ce ne sono */
      this.configurazioneBpService.getAllBpRichiedente(this.currentUser.idUtente).subscribe(response => {
        this.listaRichiedenti = response.listaRichiedente;
        this.bpList = response.listaBp;
        if (response.listaBp.length > 0) {
          response.listaBp.forEach((bp) => {
            let record: BusinessPlanElement = {
              riferimentoBp: bp.riferimentoBp,
              idBusinessPlan: bp.id,
              idAziendaRichiedente: bp.idAziendaRichiedente,
              nomeAzienda: bp.nomeAziendaRichiedente,
              dataCreazione: datePipe.transform(bp.dataCreazione, 'dd/MM/yyyy'),
              dataModifica: datePipe.transform(bp.dataModifica, 'dd/MM/yyyy'),
              idStato: bp.idStato,
              azioni: bp.idStato == 3 ? ['Visualizza', 'Duplica'] : bp.idStato == 4 ? ['Visualizza', 'Elimina'] : ['Modifica', 'Elimina']
            }
            this.listaBp.rows.push(record)
            this.dataSource = new MatTableDataSource(this.listaBp.rows);

            this.sort.sort(({ id: 'nomeAzienda', start: 'asc', disableClear: true }) as MatSortable); // sorting by nome azienda, comincia asc e non sparisce la
            this.dataSource.sort = this.sort
            this.paginator.pageSize = 10;
            this.dataSource.paginator = this.paginator;
          })
        } else {
          //lo uso per compilare la lista dei bp
          this.nuovaPratica();
        }
      })
    })



  }

  nuovaPratica() {
    /* serve una chiamata dei richiedenti scremata per conf. ambiente */
    if (this.listaRichiedentiConAmbiente.length > 0) { // null o vuoto
      const modalRef = this.modalService.open(ModalcontentComponent);
      modalRef.componentInstance.modalPar = {
        name: "salvaBusinessPlanNuovo",
        listaBp: this.listaBp.rows,//serve per il checckare se esiste gia un bp su stessa azienda e stessa data
        listaAziende: this.listaRichiedentiConAmbiente,
        title: "Genera nuovo Business Plan",
        actionButtonText: "Genera Business Plan",
        undoButtonText: "Annulla",
      }
      modalRef.result.then(r => {
        if (r.promise) {
          this.salvaBpNuovo(r);
        } else if (!r) {
          this.router.navigate(['/listaBplanNuova']);
        }
      })
    }

  }

  salvaBpNuovo(response: any) {
    this.nuovoBp = {
      id: 0, //BusinessPlan
      idUtente: this.tokenService.getUser().idUtente,
      riferimentoBp: response.nuovoBp.riferimentoBp,
      idAziendaRichiedente: response.nuovoBp.idAziendaRichiedente,
      nomeAziendaRichiedente: response.nuovoBp.nomeAzienda,
      dataCreazione: response.nuovoBp.dataCreazione,
      dataModifica: response.nuovoBp.dataCreazione,
      idStato: 1,
      idContoEconomico: 0,
      idStatoPatrimonialeAttivo: 0,
      idStatoPatrimonialePassivo: 0,
      bilancioAbbreviato: false,
    };/* da caricare con gli id corretti che vanno presi a BE */
    this.configurazioneBpService.saveBusinessPlanNuovo(this.nuovoBp).subscribe(response => {
      if (response != null) {
        this.configurazioneBpService.LoadCurrentBusinessPlan(response);
        const modalRef = this.modalService.open(ModalcontentComponent);
        modalRef.componentInstance.modalPar = {
          name: "messaggiosalva",
          description: "Nuovo Business plan salvato con successo",
          actionButtonText: "",
          undoButtonText: "Ok",
        }
        //funzione di salvataggio del bp nuovo
        this.configurazioneBpService.changeBpSalvato(false);
        this.router.navigate(['/configurazioneBP']);
      }
    })
  }

  modificaOVisualizzaBp(idSelectedBp: number) {
    this.bpList.forEach(bp => {
      if (bp.id == idSelectedBp) {
        this.configurazioneBpService.LoadCurrentBusinessPlan(bp);
        this.configurazioneBpService.changeBpSalvato(true);
        this.configurazioneBpService.isRisultatiVolatile(false);

        if (bp.idStato == 1) {
          this.router.navigate(['/configurazioneBP']);
        } else {
          this.router.navigate(['/preview']);
        }
      }
    })
  }

  Elimina(selectedBp: BusinessPlanElement) {
    if (this.modalService.hasOpenModals) {
      this.modalService.dismissAll();
    }
    const modalRef = this.modalService.open(ModalcontentComponent, {
      backdrop: 'static',
      backdropClass: 'customBackdrop',
    });
    modalRef.componentInstance.modalPar = {
      name: "eliminaBp",
      description: "Sei sicuro di voler eliminare il Business Plan: " + selectedBp.riferimentoBp + " relativo all'azienda " + selectedBp.nomeAzienda + "?",
      undoButtonText: "Annulla",
      actionButtonText: "Elimina",
    }
    modalRef.result.then(r => {
      if (r) {
        this.configurazioneBpService.deleteBusinessPlan(selectedBp.idBusinessPlan, selectedBp.idStato).subscribe(response => {
          if (response) {
            const modalRef = this.modalService.open(ModalcontentComponent, {
              backdrop: 'static',
              backdropClass: 'customBackdrop',
            });
            modalRef.componentInstance.modalPar = {
              name: "messaggioelimina",
              description: "Business Plan eliminato con successo",
              actionButtonText: "",
              undoButtonText: "Ok",
            }
            this.ngOnInit()
          } else {
            const modalRef = this.modalService.open(ModalcontentComponent);
            modalRef.componentInstance.modalPar = {
              name: "messaggioerrore",
              description: "Impossibile eliminare il Business Plan",
              actionButtonText: "",
              undoButtonText: "Ok",
            }
            modalRef.result.then(r => {
            })
          }
        })
      }
    })
  }

  visualizzaBp(idSelectedBp: number) { }
  duplicaBp(selectedBp: BusinessPlanElement) { }

}


