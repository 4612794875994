import { Component, OnInit, OnDestroy } from '@angular/core';
import { TokenStorageService } from '../_services/token-storage.service';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { ClienteService } from './../_services/cliente.service';
import { ConfigurazioneAmbienteService } from '../_services/configurazione-ambiente.service';
import { RichiedenteService } from '../_services/richiedente.service';
import { ToolTipService } from '../_services/tool-tip.service';
import { ConfigurazioneBusinessPlanService } from './../_services/configurazione-business-plan.service';

@Component({
  selector: 'app-funzioni',
  templateUrl: './funzioni.component.html',
  styleUrls: ['./funzioni.component.css']
})

/* query di ricerca sui parametri di conf ambiente cerco se per quell'id richiedente la conf. ambiente
 se manca qualcosa non si apre la card di businessplan.
 per azienda singola passo dal cliente
 per commercialista cerco se ci sono par di conf ambiente in almeno un richiedente. se si, si attiva e presento solo
 l'azienda con i parametri settati*/
export class FunzioniComponent implements OnInit, OnDestroy {
  currentUser: any;
  ambienteIsPresent: boolean = false;
  numeroPivaUtilizzate: number;
  bpIsPresent: boolean;


  /* inizializzo a vuoto altrimenti da errore che non trova i campi */
  /* anagraficaCliente: any = {
    formaGiuridica: {},
    regione: {},
    provincia: {},
    comune: {}, 

  }; */

  constructor(private token: TokenStorageService,
    private clienteService: ClienteService,
    private richiedenteService: RichiedenteService,
    private configurazioneAmbienteService: ConfigurazioneAmbienteService,
    private confBpService: ConfigurazioneBusinessPlanService,
    private toolTipService: ToolTipService,
  ) { }

  ngOnInit(): void {
    this.currentUser = this.token.getUser();
    /* serve almeno un azienda con l'ambiente settato */
    this.configurazioneAmbienteService.configurazioneAmbienteIsPresent(this.currentUser.idUtente).subscribe((response) => {
      this.ambienteIsPresent = response;
    })

    /* serve per l'html */
    /* solo per commercialisti */
    if (this.currentUser.cliente && this.currentUser.cliente.idTipoCliente == 2) {
      this.clienteService.getNumeroPivaUtilizzate().subscribe(
        response => {
          this.numeroPivaUtilizzate = response;
          //this.richiedenteService.updateRichiedenteIdList(response)
        }
      )
    }

    /* deve esistere almeno un bp associato ai richiedenti */
    this.confBpService.listaBplanIsPresent(this.currentUser.idUtente).subscribe((response) => {
      this.bpIsPresent = response

      //chiamata per caricare il service con l'id dell'azienda
    })

  }
  ngOnDestroy() {

  }


}
