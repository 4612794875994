import { Router } from '@angular/router';
/* -------------
 * COMPONENT APP
 * ------------- */
import { Component, OnInit } from '@angular/core';
import { TokenStorageService } from './_services/token-storage.service';
import { delay } from 'rxjs/operators';
import { LoadingService } from './loading.service';
import { PraticheService } from './_services/pratiche.service'; /* importo il pratiche service */
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalcontentComponent } from './modalcontent/modalcontent/modalcontent.component'; /* importo il component della modale */
import { InterazioneRisultatiQuestionarioService } from './interazione-risultati-questionario.service';
import { BeanStatico } from './condivisi/beanStatico';
import { ClienteService } from './_services/cliente.service';
import { DatiQuestionarioService } from './_services/dati-questionario.service';
import { ConfigurazioneBusinessPlanService } from './_services/configurazione-business-plan.service';
import { MenuPulsantieraService } from './header/services/menu-pulsantiera.service';
import { AuthService } from './_services/auth.service';
import { interval, Observable, timer, Subscription } from 'rxjs';
import { JwtHelperService } from "@auth0/angular-jwt";
import { UserIdleService } from 'angular-user-idle';
import { SmartBpService } from './_services/smart-bp.service';


@Component({

  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})


export class AppComponent implements OnInit {
  private roles: BeanStatico;
  user: any;
  refreshTokenInterval: number;
  nomeCliente: string;
  isLoggedIn = false;
  showAdminBoard = false;
  showFrontOfficeBoard = false;
  username?: string;
  isLoading: boolean = false;
  //definitivo: boolean;
  //questionarioSalvato = false;
  bpVolatile = false;
  smartBp = false;
  intervalSub: Subscription;
  userIdleTimeOutSub: Subscription
  title = 'project-fas-fe';
  nomeAziendaSmart: string = "";


  constructor(private loading: LoadingService, private tokenStorageService: TokenStorageService,
    private headerService: MenuPulsantieraService, private authService: AuthService,
    public router: Router, public modalService: NgbModal,
    private clienteService: ClienteService, private serviceBp: ConfigurazioneBusinessPlanService, private userIdle: UserIdleService,
    private smartBpService: SmartBpService) {

    /*
    if (!!this.tokenStorageService.getToken()) {
      router.navigate(['funzioni']);
    }
    */
  }

  ngOnInit(): void { /* ci metto tutte le operazioni per caricare i dati all'inizio */

    this.listenToLoading();
    this.tokenStorageService.currentLoggedIn.subscribe(logIn => {
      this.isLoggedIn = logIn //!!this.tokenStorageService.getToken()  !ritorna l'oggetto come booleano. l'altro ! da il not
      if (this.isLoggedIn || !!this.tokenStorageService.getToken()) {
        //if (this.isLoggedIn)
        this.tokenStorageService.saveTokenExpirationTime()// refresho il valore solo al log in e non al reload pag
        // comincio il controllo sull idle. controlla automaticamente l'attività sulla pag
        this.userIdle.startWatching()

        this.userIdle.onTimerStart().subscribe(count => {// fa partire il CD di uscita dopo N min di inattività
          console.log(count)
          this.authService.saveIdleCd(count)
          if (count == 1) {
            if (this.modalService.hasOpenModals) {
              this.modalService.dismissAll();
            }
            const modalref = this.modalService.open(ModalcontentComponent);
            modalref.componentInstance.modalPar = {
              name: "idleCD",
              title: "ENDY \u00ae: Sei ancora operativo?",
              description: "Clicca Si entro ",
              description2: " per continuare.",
              undoButtonText: "Esci",
              actionButtonText: "Si",

            }
            modalref.result.then(r => {
              if (r) {
                this.userIdle.stopTimer()
                this.userIdle.stopWatching()
                this.userIdle.startWatching()
              } else { this.logout() }  // esce subito se 
            })
          }
        }
          //qui si aprirà la modale con il coutndown
        );
        this.userIdleTimeOutSub = this.userIdle.onTimeout().subscribe(() => {
          console.log('Sessione scaduta.')
          this.logout()
        })



        this.isLoggedIn = true // lo imposto a true in caso di reload della pag. serve a far comparire logout nell header
        this.user = this.tokenStorageService.getUser();
        this.roles = this.user.roles;
        /* riporto il nome cliente solo se l'utente non è amministrazione fas */
        if (this.user.cliente) {
          this.clienteService.getNomeClienteById().subscribe((data) => {
            this.nomeCliente = data.nomeUtente;
          })
        } else {
          this.nomeCliente = 'amministrazione Fas';
        }

        this.showAdminBoard = this.roles.descrizione.includes('ROLE_ADMIN');
        this.showFrontOfficeBoard = this.roles.descrizione.includes('ROLE_FRONTOFFICE');

        this.username = this.user.username;

        this.serviceBp.currentRisultatiVolatile.subscribe(volatile => {
          this.bpVolatile = volatile;
        });

                //RIVEDERE
                this.smartBpService.currentRichiedenteSmartBp.subscribe(ric => {
                  if(ric != null && ric.id!=0) {
                      this.smartBp = true;
                      this.nomeAziendaSmart = ric.ragioneSociale;
                  
                  }else{
                      this.smartBp = false;
                      this.nomeAziendaSmart = "";
                  }
                });

        this.tokenStorageService.currentExpiredTime.subscribe(time => {
          if (time > 0) {
            if (this.intervalSub != null) {
              this.intervalSub.unsubscribe()
            }
            this.refreshTokenInterval = time - 40000;
            this.refreshToken();
          }
        });
      }

    })
  }
  refreshToken() {
    //dopo refreshTokenInterval millisec fa partire il refresh token ogni refreshTokenInterval millisec
    this.intervalSub = interval(this.refreshTokenInterval).subscribe((x) => { // will execute every 30 seconds
      //console.log(x)
      //console.log(this.tokenStorageService.getToken())
      this.authService.refreshToken(this.tokenStorageService.getToken()).subscribe(
        data => {
          //console.log(data.newToken)
          this.tokenStorageService.saveToken(data.newToken);// salvo il nuovo token e il nuovo expirationtime
          //se torna errore setto loggein a false
        },
        error => {
          //console.log(error.message);
          this.logout()
          //this.router.navigate(["/login"])
        });
    });

  }
  /* //ascolta gli eneti dell'utente
  @HostListener('window:keydown', ['$event']) KBInteraction() {
    this.counterKB++;
    console.log('counterKB: ', this.counterKB)
  }
  //mousemove bombarda di eventi
  @HostListener('window:mousemove') mouseMoveInteraction() {
    this.counterClick++;
    console.log("counterClick: ", this.counterMove)
  }
  @HostListener('window:click') clickInteraction() {
    this.counterClick++;
    console.log("counterClick: ", this.counterClick)
  }
  @HostListener('window:scroll') scrollInteraction() {
    this.counterScroll++;
    console.log("counterScroll: ", this.counterScroll)
  } */



  logout(): void {
    this.isLoggedIn = false
    this.userIdle.stopWatching()
    if (this.intervalSub != null) {
      this.intervalSub.unsubscribe();
    }
    if (this.userIdleTimeOutSub != null) this.userIdleTimeOutSub.unsubscribe()

    this.tokenStorageService.signOut();
    window.location.reload();
    // unsubscribe l'interval
  }


  /**
   * Listen to the loadingSub property in the LoadingService class. This drives the
   * display of the loading spinner.
   */
  listenToLoading(): void {
    this.loading.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.isLoading = loading;
      });
  }


  checkRoute(): void {

    let check: any = null;
    if (this.router.url == "/configurazioneBP") {
      if (this.headerService.isFormDirty()) {
        check = this.openModal();
        check.result.then(r => {
          if (r) {
            this.router.navigate([""]);
          }
        });
      } else {
        this.router.navigate([""]);
      }
    } else if (this.router.url == "/configurazioneambiente") {
      /* if (this.headerService.isFormDirty()) {
        check = this.openModal(); 
        check.result.then(r => { 
          if (r) {
            this.router.navigate([""]);
          }
        });
      } else { */
      this.router.navigate([""]);
      //}
    } else if (this.router.url == "/gestioneanagrafica") {
      /* if (this.headerService.isFormDirty()) {
        check = this.openModal(); 
        check.result.then(r => { 
          if (r) {
            this.router.navigate([""]);
          }
        });
      } else { */
      this.router.navigate([""]);
      //}
    } else if (this.router.url == "/censiementorichiedente") {
      /* if (this.headerService.isFormDirty()) {
        check = this.openModal(); 
        check.result.then(r => { 
          if (r) {
            this.router.navigate([""]);
          }
        });
      } else { */
      this.router.navigate([""]);
      //}
    } else if (this.router.url == "/preview") { //sezione preview. modale appare solo se il bp è volatile
      if (this.bpVolatile && !this.smartBp) {
        check = this.openModal();
        check.result.then(r => {
          if (r) {
            this.router.navigate([""]);
          }
        });
      } else {
        this.router.navigate([""]);
        this.smartBpService.cancellaTutto();
      }
    }
    else {
      /* porta la nvigazione alla home */
      this.router.navigate([""]);
      this.smartBpService.cancellaTutto();

    }
  }

  openModal(): any {
    if (this.modalService.hasOpenModals) {
      this.modalService.dismissAll();
    }
    const modalref = this.modalService.open(ModalcontentComponent);
    modalref.componentInstance.modalPar = {
      name: "esci",
      title: "Sicuro di voler tornare uscire?",
      description: "Cliccando su 'Esci' le eventuali modifiche non salvate andranno perse.",
      undoButtonText: "Annulla",
      actionButtonText: "Esci",

    }
    return modalref;
  }

}
