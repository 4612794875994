import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { UntypedFormGroup, FormControl, UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LineaBusinessBp } from 'src/app/condivisi/linea-business-bp';
import { ConfigurazioneAmbienteService } from 'src/app/_services/configurazione-ambiente.service';
import { ConfigurazioneBusinessPlanService } from 'src/app/_services/configurazione-business-plan.service';
import { RichiedenteService } from 'src/app/_services/richiedente.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { ToolTipService } from 'src/app/_services/tool-tip.service';
import { Magazzino } from 'src/app/interface/magazzino';
import { ModalcontentComponent } from 'src/app/modalcontent/modalcontent/modalcontent.component';
import { PraticheNuova } from 'src/app/interface/Pratiche-nuova';
import { ToolTip } from 'src/app/interface/toolTip';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-configuarazione-magazzino',
  templateUrl: './configuarazione-magazzino.component.html',
  styleUrls: ['./configuarazione-magazzino.component.css']
})
export class ConfiguarazioneMagazzinoComponent implements OnInit, OnDestroy {
  @Input() businessPlanTemp: PraticheNuova
  @Input() magazzinoVolatile: Magazzino[]
  @Input() ricaviInizialiLineeBp: any[];
  @Input() magazzino: UntypedFormGroup;
  @Input() submitted: boolean //è comandato da dirty
  @Output() emitMagazzinoFormValid = new EventEmitter<any>()


  warningMagazzinoRibasso: boolean[] = [];
  warningMagazzinoRialzo: boolean[] = [];
  warningMagazzinoScarto: boolean[] = [];
  warningMagazzinoDrift: boolean[] = [];
  risultatiVolatileSubscription: Subscription;
  tipsSubscription: Subscription;
  valoreMax = {}
  sumValoriIniziali: number = 0;

  magazzinoDirty: boolean = false;
  sumValoriInizialiBool: boolean = true;
  rimanenzeTotMagazzino: number = 0;
  dataAggiornamento: number = 0;
  isRisultatiVolatile: boolean
  magazzinoValid: boolean = true;
  magazzinoarray: UntypedFormArray;
  lineeBusinessMagazzino: Magazzino[] = [];
  listaTips: ToolTip[] = [];

  constructor(private formBuilder: UntypedFormBuilder,
    private configurazioneAmbienteService: ConfigurazioneAmbienteService,
    private configurazioneBpService: ConfigurazioneBusinessPlanService,
    private httpClient: HttpClient,
    private toolTipService: ToolTipService,
    private modalService: NgbModal,
    private tokenStorageService: TokenStorageService) {


  }

  ngOnInit(): void {
    this.risultatiVolatileSubscription = this.configurazioneBpService.currentRisultatiVolatile.subscribe(siVolatile => {
      this.isRisultatiVolatile = siVolatile
    })
    this.magazzino = this.formBuilder.group({
      oscillazioniMagazzino: [{ value: '', disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
      magazzinoarray: this.formBuilder.array([])
    });

    this.configurazioneBpService.getValoriMax("magazzino").subscribe(
      values => {
        values.forEach(element => {
          if (element.error == null) {
            this.valoreMax[element.nomeCampo] = element.valoreMax;
          }
        })
      });

    this.tipsSubscription = this.toolTipService.tipsArray.subscribe(arr => {
      if (arr != null) {
        this.listaTips = arr
      }
    })
    this.getMagazzino()

  }

  setIndiceRotazione(i) {
    if (this.ricaviInizialiLineeBp && this.ricaviInizialiLineeBp.length > 0) {
      for (let j = 0; j < this.ricaviInizialiLineeBp.length; j++) {
        if (this.ricaviInizialiLineeBp[j]) {
          if (this.ricaviInizialiLineeBp[j].ricavi != null) {
            if (this.magazzinoFormArray[i].controls.indiceRotazione.value === null) {
              if (this.ricaviInizialiLineeBp[j].nomeLinea == this.lineeBusinessMagazzino[i].nomeLinea && this.magazzinoFormArray[i].controls.valoreIniziale.value != 0) {
                this.magazzinoFormArray[i].controls.indiceRotazione.setValue(this.ricaviInizialiLineeBp[j].ricavi / this.magazzinoFormArray[i].controls.valoreIniziale.value)
                this.magazzinoFormArray[i].controls.indiceRotazione.updateValueAndValidity()
              }
            }
          }
        }
      }
    }
  }

  getMagazzino() {
    if (this.businessPlanTemp) {
      this.lineeBusinessMagazzino = []
      this.configurazioneBpService.getDatiBilancio(this.businessPlanTemp.idStatoPatrimonialeAttivo).subscribe(
        infoBilancio => {
          this.rimanenzeTotMagazzino = infoBilancio.totaleRimanenze;
          this.dataAggiornamento = infoBilancio.anno;
        }
      );
      this.warningMagazzinoRibasso = [];
      this.warningMagazzinoRialzo = [];
      this.warningMagazzinoScarto = [];
      this.warningMagazzinoDrift = [];

      if (this.isRisultatiVolatile && this.magazzinoVolatile != null) {
        this.magazzinoArray.clear();
        this.f.oscillazioniMagazzino.setValue(this.magazzinoVolatile[0].oscillazioni)
        this.magazzinoVolatile.forEach(lineaBusinessMagazzino => {
          if (lineaBusinessMagazzino.error == null) {

            this.lineeBusinessMagazzino.push(lineaBusinessMagazzino);
            let magazzinoForm = this.formBuilder.group({
              valoreIniziale: [{ value: lineaBusinessMagazzino.magazIniz, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
              valoreInizialeNumerico: { value: this.rimanenzeTotMagazzino != 0 ? (this.rimanenzeTotMagazzino * lineaBusinessMagazzino.magazIniz) / 100 : 0, disabled: true },
              indiceRotazione: [{ value: lineaBusinessMagazzino.indRotMagaz != null ? lineaBusinessMagazzino.indRotMagaz : 0, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],
              ribasso: { value: lineaBusinessMagazzino.magazRibasso ? lineaBusinessMagazzino.magazRibasso : 0, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) },
              rialzo: { value: lineaBusinessMagazzino.magazRialzo ? lineaBusinessMagazzino.magazRialzo : 0, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) },
              scarto: { value: lineaBusinessMagazzino.magazScarto ? lineaBusinessMagazzino.magazScarto : 0, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) },
              drift: [{ value: lineaBusinessMagazzino.magazDrift ? lineaBusinessMagazzino.magazDrift : 0, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],
            });
            if (this.rimanenzeTotMagazzino > 0) {
              magazzinoForm.controls.valoreIniziale.setValidators(Validators.compose([Validators.required, Validators.max(this.valoreMax["sommaValoriIniziali"])]));

            } else {
              magazzinoForm.controls.valoreIniziale.setValidators(Validators.required);
            }
            //console.log(lineaBusinessMagazzino);
            this.magazzinoFormArray.push(magazzinoForm);
            this.magazzinoFormArray[this.magazzinoFormArray.length - 1].controls.valoreIniziale.updateValueAndValidity()
            this.magazzinoFormArray[this.magazzinoFormArray.length - 1].controls.valoreInizialeNumerico.updateValueAndValidity()
            this.magazzinoFormArray[this.magazzinoFormArray.length - 1].controls.indiceRotazione.updateValueAndValidity()
            this.magazzinoFormArray[this.magazzinoFormArray.length - 1].controls.ribasso.updateValueAndValidity()
            this.magazzinoFormArray[this.magazzinoFormArray.length - 1].controls.rialzo.updateValueAndValidity();
            this.magazzinoFormArray[this.magazzinoFormArray.length - 1].controls.scarto.updateValueAndValidity()
            this.magazzinoFormArray[this.magazzinoFormArray.length - 1].controls.drift.updateValueAndValidity()
            /* this.currentIndex=0; */
          }
        })
        this.abilitaOscillazioni()
        this.f.oscillazioniMagazzino.updateValueAndValidity()
      } else {
        this.configurazioneBpService.getValoriMagazzino(this.businessPlanTemp.id).subscribe(
          giacenza => {
            /* giancenza ha length >0 altrimenti non ci sarebbe questa sezione */
            this.magazzinoArray.clear();
            this.f.oscillazioniMagazzino.setValue(giacenza[0].oscillazioni)
            giacenza.forEach(lineaBusinessMagazzino => {
              if (lineaBusinessMagazzino.error == null) {
                this.warningMagazzinoRibasso.push(false);
                this.warningMagazzinoRialzo.push(false);
                this.warningMagazzinoScarto.push(false);
                this.warningMagazzinoDrift.push(false);

                this.lineeBusinessMagazzino.push(lineaBusinessMagazzino);
                let magazzinoForm = this.formBuilder.group({
                  valoreIniziale: [{ value: lineaBusinessMagazzino.magazIniz, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
                  indiceRotazione: [{ value: lineaBusinessMagazzino.indRotMagaz != null ? lineaBusinessMagazzino.indRotMagaz : 0, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],
                  valoreInizialeNumerico: { value: this.rimanenzeTotMagazzino != 0 ? (this.rimanenzeTotMagazzino * lineaBusinessMagazzino.magazIniz) / 100 : 0, disabled: true },
                  ribasso: { value: lineaBusinessMagazzino.magazRibasso ? lineaBusinessMagazzino.magazRibasso : 0, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) },
                  rialzo: { value: lineaBusinessMagazzino.magazRialzo ? lineaBusinessMagazzino.magazRialzo : 0, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) },
                  scarto: { value: lineaBusinessMagazzino.magazScarto ? lineaBusinessMagazzino.magazScarto : 0, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) },
                  drift: [{ value: lineaBusinessMagazzino.magazDrift ? lineaBusinessMagazzino.magazDrift : 0, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],
                });
                if (this.rimanenzeTotMagazzino > 0) {
                  magazzinoForm.controls.valoreIniziale.setValidators(Validators.compose([Validators.required, Validators.max(this.valoreMax["sommaValoriIniziali"])]));
                } else {
                  magazzinoForm.controls.valoreIniziale.setValidators(Validators.required);

                }
                //console.log(lineaBusinessMagazzino);
                this.magazzinoFormArray.push(magazzinoForm);
                this.magazzinoFormArray[this.magazzinoFormArray.length - 1].controls.valoreIniziale.updateValueAndValidity()
                this.magazzinoFormArray[this.magazzinoFormArray.length - 1].controls.valoreInizialeNumerico.updateValueAndValidity()
                this.magazzinoFormArray[this.magazzinoFormArray.length - 1].controls.indiceRotazione.updateValueAndValidity()
                this.magazzinoFormArray[this.magazzinoFormArray.length - 1].controls.ribasso.updateValueAndValidity()
                this.magazzinoFormArray[this.magazzinoFormArray.length - 1].controls.rialzo.updateValueAndValidity();
                this.magazzinoFormArray[this.magazzinoFormArray.length - 1].controls.scarto.updateValueAndValidity()
                this.magazzinoFormArray[this.magazzinoFormArray.length - 1].controls.drift.updateValueAndValidity()
              }
            })
            this.f.oscillazioniMagazzino.updateValueAndValidity()
            this.abilitaOscillazioni()
          }
        );
      }

    }
  }

  get f() { return this.magazzino.controls; }
  get magazzinoArray(): UntypedFormArray { return <UntypedFormArray> this.f.magazzinoarray; }
  get magazzinoFormArray(): UntypedFormGroup[] { return <UntypedFormGroup[]> this.magazzinoArray.controls; }

  /*getLineeBusiness() {

    this.configurazioneBpService.getLineeBusiness(this.businessPlanTemp.id, this.businessPlanTemp.idAziendaRichiedente).subscribe(response => {
       inserire la chiamata get a magazzino per compilare i campi 
    });
  }*/

  Annulla() {
    if (this.isFormDirty()) {
      if (this.modalService.hasOpenModals) {
        this.modalService.dismissAll();
      }
      const modalRef = this.modalService.open(ModalcontentComponent, {
        backdrop: 'static',
        backdropClass: 'customBackdrop',
      });
      modalRef.componentInstance.modalPar = {
        name: "annulla",
        title: "Sicuro di voler annullare ?",
        description: "Cliccando su 'Annulla' tutte le modifiche non salvate su questa pagina andranno perse.",
        undoButtonText: "Indietro",
        actionButtonText: "Annulla",
      }
      modalRef.result.then((r) => {
        if (r) {
          this.getMagazzino()
          this.disabilitaWarning()
          this.submitted = false
          this.sumValoriInizialiBool = true
          this.magazzinoValid = true
          this.emitMagazzinoFormValid.emit({ magazzinoValid: this.magazzinoValid, magazzinoDirty: this.submitted })
          this.markFormPristine()
        }
      })
    }
  }

  elaboraConfiguarazioneMagazzino() {
    if (this.magazzinoValid) {
      this.disabilitaWarning();
      for (let i = 0; i < this.lineeBusinessMagazzino.length; i++) {

        this.lineeBusinessMagazzino[i].magazIniz = this.magazzinoFormArray[i].controls.valoreIniziale.value;
        this.lineeBusinessMagazzino[i].indRotMagaz = this.magazzinoFormArray[i].controls.indiceRotazione.value;
        this.lineeBusinessMagazzino[i].magazRibasso = this.f.oscillazioniMagazzino.value ? this.magazzinoFormArray[i].controls.ribasso.value : 0;
        this.lineeBusinessMagazzino[i].magazRialzo = this.f.oscillazioniMagazzino.value ? this.magazzinoFormArray[i].controls.rialzo.value : 0;
        this.lineeBusinessMagazzino[i].magazScarto = this.f.oscillazioniMagazzino.value ? this.magazzinoFormArray[i].controls.scarto.value : 1;
        this.lineeBusinessMagazzino[i].magazDrift = this.magazzinoFormArray[i].controls.drift.value
        this.lineeBusinessMagazzino[i].oscillazioni = this.f.oscillazioniMagazzino.value
        this.lineeBusinessMagazzino[i].percentuale = this.rimanenzeTotMagazzino > 0;
      }
      //this.configurazioneBpService.elaboraConfiguarazioneMagazzino(this.lineeBusinessMagazzino)
      return this.lineeBusinessMagazzino
    }
  }

  isFormDirty() {
    for (let i = 0; i < this.magazzinoFormArray.length; i++) {
      this.magazzinoFormArray[i].updateValueAndValidity();
      if (this.magazzinoFormArray[i].dirty) {
        this.magazzinoDirty = true;
      }
    }
    if (this.magazzinoDirty || this.f.oscillazioniMagazzino.dirty) {
      this.magazzino.markAsDirty()
      this.magazzino.updateValueAndValidity();
    } else {
      this.magazzino.markAsPristine()
      this.magazzinoDirty = false;
      this.magazzino.updateValueAndValidity();
    }
    this.submitted = this.magazzino.dirty;
    return this.submitted;
  }


  isFormValid() {
    for (let i = 0; i < this.magazzinoFormArray.length; i++) {
      this.magazzinoFormArray[i].updateValueAndValidity()
    }
    this.magazzinoArray.updateValueAndValidity()
    this.magazzino.updateValueAndValidity()
    if (this.rimanenzeTotMagazzino != 0) {
      this.sumValoriInizialiBool = this.checkValoriInizialeSum();
    }
    this.magazzinoValid = this.magazzino.valid && this.sumValoriInizialiBool
    return this.magazzinoValid
  }

  onSubmit(): Magazzino[] {
    this.disabilitaWarning()
    if (this.submitted) {//this.magazzinoValid
      if (this.magazzinoVolatile) {
        for (let i = 0; i < this.lineeBusinessMagazzino.length; i++) {
          this.magazzinoFormArray[i].controls.valoreIniziale.updateValueAndValidity()
          this.magazzinoFormArray[i].controls.ribasso.updateValueAndValidity()
          this.magazzinoFormArray[i].controls.indiceRotazione.updateValueAndValidity()
          this.magazzinoFormArray[i].controls.rialzo.updateValueAndValidity();
          this.magazzinoFormArray[i].controls.scarto.updateValueAndValidity()
          this.magazzinoFormArray[i].controls.drift.updateValueAndValidity()

          this.lineeBusinessMagazzino[i].magazIniz = this.magazzinoFormArray[i].controls.valoreIniziale.value;
          this.lineeBusinessMagazzino[i].indRotMagaz = this.magazzinoFormArray[i].controls.indiceRotazione.value;
          this.lineeBusinessMagazzino[i].magazRibasso = this.f.oscillazioniMagazzino.value ? this.magazzinoFormArray[i].controls.ribasso.value : 0;
          this.lineeBusinessMagazzino[i].magazRialzo = this.f.oscillazioniMagazzino.value ? this.magazzinoFormArray[i].controls.rialzo.value : 0;
          this.lineeBusinessMagazzino[i].magazScarto = this.f.oscillazioniMagazzino.value ? this.magazzinoFormArray[i].controls.scarto.value : 1;
          this.lineeBusinessMagazzino[i].magazDrift = this.magazzinoFormArray[i].controls.drift.value
          this.lineeBusinessMagazzino[i].oscillazioni = this.f.oscillazioniMagazzino.value
          this.lineeBusinessMagazzino[i].percentuale = this.rimanenzeTotMagazzino > 0;
        }
      } else {

        for (let i = 0; i < this.lineeBusinessMagazzino.length; i++) {
          this.magazzinoFormArray[i].controls.valoreIniziale.updateValueAndValidity()
          this.magazzinoFormArray[i].controls.ribasso.updateValueAndValidity()
          this.magazzinoFormArray[i].controls.indiceRotazione.updateValueAndValidity()
          this.magazzinoFormArray[i].controls.rialzo.updateValueAndValidity();
          this.magazzinoFormArray[i].controls.scarto.updateValueAndValidity()
          this.magazzinoFormArray[i].controls.drift.updateValueAndValidity()

          this.lineeBusinessMagazzino[i].magazIniz = this.magazzinoFormArray[i].controls.valoreIniziale.value;
          this.lineeBusinessMagazzino[i].indRotMagaz = this.magazzinoFormArray[i].controls.indiceRotazione.value;
          this.lineeBusinessMagazzino[i].magazRibasso = this.f.oscillazioniMagazzino.value ? this.magazzinoFormArray[i].controls.ribasso.value : 0;
          this.lineeBusinessMagazzino[i].magazRialzo = this.f.oscillazioniMagazzino.value ? this.magazzinoFormArray[i].controls.rialzo.value : 0;
          this.lineeBusinessMagazzino[i].magazScarto = this.f.oscillazioniMagazzino.value ? this.magazzinoFormArray[i].controls.scarto.value : 1;
          this.lineeBusinessMagazzino[i].magazDrift = this.magazzinoFormArray[i].controls.drift.value;
          this.lineeBusinessMagazzino[i].oscillazioni = this.f.oscillazioniMagazzino.value
          this.lineeBusinessMagazzino[i].percentuale = this.rimanenzeTotMagazzino > 0;
        }
      }
      /* return unico */
      this.markFormPristine()
      return this.lineeBusinessMagazzino
    } else {
      return null;
    }

  }
  markFormPristine() {
    this.magazzino.markAsPristine()
    this.magazzinoDirty = false;
  }



  checkValoriInizialeSum(): boolean {
    this.sumValoriIniziali = 0;
    for (let i = 0; i < this.magazzinoFormArray.length; i++) {
      if (this.magazzinoFormArray[i].controls.valoreIniziale.value != null) {
        this.sumValoriIniziali = this.sumValoriIniziali + this.magazzinoFormArray[i].controls.valoreIniziale.value; 0
      }
    }
    if (this.sumValoriIniziali == this.valoreMax["sommaValoriIniziali"]) {
      return true;
    }
    return false;

  }

  checkValMax(i: number, nomeCampo: string) {
    if (nomeCampo == "magazzinoRibasso") {
      if (this.magazzinoFormArray[i].controls.ribasso.value != null) {
        if (this.magazzinoFormArray[i].controls.ribasso.value > this.valoreMax["ribasso"]) {
          this.warningMagazzinoRibasso.splice(i, 1, true);
        } else {
          this.warningMagazzinoRibasso.splice(i, 1, false);
        }
      }
    } else if (nomeCampo == "magazzinoRialzo") {
      if (this.magazzinoFormArray[i].controls.rialzo.value != null) {
        if (this.magazzinoFormArray[i].controls.rialzo.value > this.valoreMax["rialzo"]) {
          this.warningMagazzinoRialzo.splice(i, 1, true);
        } else {
          this.warningMagazzinoRialzo.splice(i, 1, false);
        }
      }
    } else if (nomeCampo == "magazzinoScarto") {
      if (this.magazzinoFormArray[i].controls.scarto.value != null) {
        if (this.magazzinoFormArray[i].controls.scarto.value > this.valoreMax["scarto"]) {
          this.warningMagazzinoScarto.splice(i, 1, true);
        } else {
          this.warningMagazzinoScarto.splice(i, 1, false);
        }
      }
    } else if (nomeCampo == "magazzinoDrift") {
      if (this.magazzinoFormArray[i].controls.drift.value != null) {
        if (this.magazzinoFormArray[i].controls.drift.value > this.valoreMax["drift"]) {
          this.warningMagazzinoDrift.splice(i, 1, true);
        } else {
          this.warningMagazzinoDrift.splice(i, 1, false);
        }
      }
    }
  }

  disabilitaWarning() {
    for (let i = 0; i < this.magazzinoFormArray.length; i++) {
      this.warningMagazzinoRibasso.splice(i, 1, false);
      this.warningMagazzinoRialzo.splice(i, 1, false);
      this.warningMagazzinoScarto.splice(i, 1, false);
      this.warningMagazzinoDrift.splice(i, 1, false);
    }
  }
  abilitaOscillazioni() {
    this.f.oscillazioniMagazzino.updateValueAndValidity()
    for (let index = 0; index < this.magazzinoFormArray.length; index++) {
      if (this.f.oscillazioniMagazzino.value) {
        this.magazzinoFormArray[index].controls.rialzo.setValidators(Validators.required);
        this.magazzinoFormArray[index].controls.ribasso.setValidators(Validators.required);
        this.magazzinoFormArray[index].controls.scarto.setValidators(Validators.required);
      } else {
        this.magazzinoFormArray[index].controls.rialzo.clearValidators();
        this.magazzinoFormArray[index].controls.ribasso.clearValidators();
        this.magazzinoFormArray[index].controls.scarto.clearValidators();
      }
      this.magazzinoFormArray[index].controls.rialzo.updateValueAndValidity();
      this.magazzinoFormArray[index].controls.ribasso.updateValueAndValidity();
      this.magazzinoFormArray[index].controls.scarto.updateValueAndValidity();
    }
  }
  applicaPerc(i: number) {
    if (this.magazzinoFormArray[i].controls.valoreIniziale.value != '' && this.magazzinoFormArray[i].controls.valoreIniziale.value <= 100) {
      this.magazzinoFormArray[i].controls.valoreInizialeNumerico.setValue((this.rimanenzeTotMagazzino * this.magazzinoFormArray[i].controls.valoreIniziale.value) / 100)
    } else {
      this.magazzinoFormArray[i].controls.valoreInizialeNumerico.setValue(0)
    }
  }

  ngOnDestroy(): void {
    this.risultatiVolatileSubscription.unsubscribe()
    this.tipsSubscription.unsubscribe()
  }

}
