
import { Component, OnInit, ViewChild } from '@angular/core';
import { RichiedenteService } from '../_services/richiedente.service';
import { TokenStorageService } from '../_services/token-storage.service';
import { Validators, FormControl, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Richiedente } from '../interface/richiedente';
import { ConfigurazioneAmbienteService } from '../_services/configurazione-ambiente.service';
import { ModalcontentComponent } from '../modalcontent/modalcontent/modalcontent.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DossierAmministrativiComponent } from './dossier-amministrativi/dossier-amministrativi.component';
import { DistintaBaseProdottiComponent } from './distinta-base-prodotti/distinta-base-prodotti.component';
import { LineaBusinessComponent } from './distinta-base-prodotti/linea-business/linea-business.component';
import { LineeBusinessComponent } from './linee-business/linee-business.component';
import { ParametriGeneraliComponent } from './parametri-generali/parametri-generali.component';
import { BilancioService } from '../_services/bilancio.service';
import { Router } from '@angular/router';
import { ToolTipService } from '../_services/tool-tip.service';
import { SituazioneCreditiziaAmbienteComponent } from './situazione-creditizia-ambiente/situazione-creditizia-ambiente.component';
import { SituazioneDebitoriaAmbienteComponent } from './situazione-debitoria-ambiente/situazione-debitoria-ambiente.component';


@Component({
  selector: 'app-configurazione-ambiente',
  templateUrl: './configurazione-ambiente.component.html',
  styleUrls: ['./configurazione-ambiente.component.css'],
})
export class ConfigurazioneAmbienteComponent implements OnInit {

  @ViewChild(DossierAmministrativiComponent, { static: false }) dossierAmministrativiComponent: DossierAmministrativiComponent;
  @ViewChild(DistintaBaseProdottiComponent, { static: false }) distintaBaseProdottiComponent: DistintaBaseProdottiComponent;
  @ViewChild(ParametriGeneraliComponent, { static: false }) parametriGeneraliComponent: ParametriGeneraliComponent;
  @ViewChild(LineeBusinessComponent, { static: false }) lineeBusinessComponent: LineeBusinessComponent;
  @ViewChild(SituazioneCreditiziaAmbienteComponent, { static: false }) SituazioneCreditiziaAmbienteComponent: SituazioneCreditiziaAmbienteComponent;
  @ViewChild(SituazioneDebitoriaAmbienteComponent, { static: false }) SituazioneDebitoriaAmbienteComponent: SituazioneDebitoriaAmbienteComponent;

  selectedIndex = 0;
  submitted = false;
  codiceAtecoPrimario: string;
  isStartUp: boolean = false;
  timer: any = 0
  aziendaSelected: boolean = false;
  /* non mi importa il valore di queste variabili. basta che cambino */
  isDirtyParametri: boolean = false;
  isDirtyLinee: boolean = false;
  isDirtyProdotti: boolean[];
  isDirtyForm: boolean[] = null;
  configurazioneAmbienteForm: UntypedFormGroup
  currentAnno = new Date().getFullYear()
  listaNomiAzienda: string[] = [];
  richiedenteTemp: Richiedente;
  //dataBilObj: any = null;
  //ciccio: FormControl = new FormControl();
  listaRichiedenti: Richiedente[] = [];
  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    private configurazioneAmbienteService: ConfigurazioneAmbienteService,
    private bilancioService: BilancioService,
    private tokeStorageService: TokenStorageService,
    private richiedenteService: RichiedenteService,
    private toolTipService: ToolTipService,
    private router: Router) {
    /* form per nome azienda */

    this.configurazioneAmbienteForm = this.formBuilder.group({
      nomeAzienda: ['', Validators.required],
      dossierAmministrativi: this.formBuilder.group({}),
      situazioneDebitoria: this.formBuilder.group({}),
      situazioneCreditizia: this.formBuilder.group({})
    })
  }
  get currentUser() { return this.tokeStorageService.getUser() }
  get f() { return this.configurazioneAmbienteForm.controls }
  ngOnInit(): void {
    this.toolTipService.getAllTipsContent('CAM')//carico tutti i tip di GBP sul service
    /* cambiare chiamata inserendo il check sul bilancio */
    this.richiedenteService.getAnagraficaRichiedenteByidCliente().subscribe(
      response => {
        if (response.length > 0) {
          this.listaRichiedenti = response;  //vediamo se mi serve
          response.forEach((element) => {
            this.listaNomiAzienda.push(element.ragioneSociale)
          })
          /* setta l'id e docice ateco per l'azineda singola */
          if (response.length == 1) {
            this.f.nomeAzienda.setValue(response[0].ragioneSociale);
            this.f.nomeAzienda.disable();
            this.aziendaSelected = true
            this.richiedenteTemp = response[0];
            this.codiceAtecoPrimario = response[0].codiceAtecoPrimario;
            this.isStartUp = response[0].isStartUp
            /* this.dataBilObj null o pieno differenzia azienda nuova da vecchia con bilanci in regola (da verificare) */
            if (this.currentAnno - response[0].annoCostituzione <= 2) {
              this.bilancioService.loadDataBilObj(null)
              /* azienda giovane prosegui */
              this.bilancioService.getAziendaRichiedente(response[0])
            } else {
              this.bilancioService.getDataLastBilancio(this.richiedenteTemp.id).subscribe(checkBil => {
                if (checkBil != null) {
                  if (this.currentAnno - checkBil.anno >= 2) {
                    /* (manca dal 2020)modale uscita  */
                    if (this.modalService.hasOpenModals()) {
                      this.modalService.dismissAll();
                    }
                    const modalRef = this.modalService.open(ModalcontentComponent, {
                      backdrop: 'static',
                      backdropClass: 'customBackdrop',
                    });
                    modalRef.componentInstance.modalPar = {
                      name: "assenzaBilancio",
                      title: "",
                      description: this.currentAnno - checkBil.anno == 3 ? "Non sono stati trovati, sul registro imprese, i bilanci relativi all'azienda " + this.listaNomiAzienda[0] +
                        " dell'anno " + (checkBil.anno).toString() + ", pertanto non è possibile proseguire con la configurazione dell'ambiente. La preghiamo di contattare l'assistenza Endy." : (this.currentAnno - checkBil.anno == 4 ?
                          "Non sono stati trovati, sul registro imprese, i bilanci relativi all'azienda " + this.listaNomiAzienda[0] +
                          " degli anni " + (checkBil.anno - 1).toString() + ", " + (checkBil.anno).toString() + ", pertanto non è possibile proseguire con la configurazione dell'ambiente. La preghiamo di contattare l'assistenza Endy." :
                          "Non sono stati trovati, sul registro imprese, i bilanci relativi all'azienda " + this.listaNomiAzienda[0] +
                          " degli anni " + (checkBil.anno - 2).toString() + ", " + (checkBil.anno - 1).toString() + ", " + (checkBil.anno).toString() + ", pertanto non è possibile proseguire con la configurazione dell'ambiente. La preghiamo di contattare l'assistenza Endy."),
                      undoButtonText: "Esci",
                    }
                    modalRef.result.then((r) => {
                      if (!r) {
                        this.router.navigate(['/funzioni'])
                      }
                    })
                  } else {
                    /* prosegui */
                    this.bilancioService.loadDataBilObj(checkBil)
                    //this.dataBilObj = checkBil
                    this.bilancioService.getAziendaRichiedente(response[0])
                  }
                } else {
                  /* modale che mi fa uscire */
                  if (this.modalService.hasOpenModals()) {
                    this.modalService.dismissAll();
                  }
                  const modalRef = this.modalService.open(ModalcontentComponent, {
                    backdrop: 'static',
                    backdropClass: 'customBackdrop',
                  });
                  modalRef.componentInstance.modalPar = {
                    name: "assenzaBilancio",
                    title: "",
                    description: "Non è stato trovato sul registro imprese alcun bilancio relativo all'azienda " + this.listaNomiAzienda[0] + ", pertanto non è possibile proseguire con la configurazione dell'ambiente. La preghiamo di contattare l'assistenza Endy.",
                    undoButtonText: "Esci",
                  }
                  modalRef.result.then((r) => {
                    if (!r) {
                      this.router.navigate(['/funzioni'])
                    }
                  })
                }
              })
            }
          }
        }
      });

  }

  unlock() {
    this.f.nomeAzienda.enable();
    this.aziendaSelected = false;


    this.timer = setTimeout(() => {

      this.f.nomeAzienda.disable(),
        this.aziendaSelected = true;
      this.f.nomeAzienda.updateValueAndValidity()
    }, 7000)
    this.f.nomeAzienda.updateValueAndValidity()
  }

  onChangeNomeAzienda() {
    clearTimeout(this.timer)
    this.isStartUp = false

    for (let i = 0; i < this.listaRichiedenti.length; i++) {
      if (this.f.nomeAzienda.value === this.listaRichiedenti[i].ragioneSociale) {
        //this.selectedIndex = 0;
        if (this.currentAnno - this.listaRichiedenti[i].annoCostituzione <= 2) {
          this.bilancioService.loadDataBilObj(null)
          /* azienda giovane prosegui */
          this.bilancioService.getAziendaRichiedente(this.listaRichiedenti[i])
          this.richiedenteTemp = this.listaRichiedenti[i];
          this.codiceAtecoPrimario = this.listaRichiedenti[i].codiceAtecoPrimario;
          this.isStartUp = this.listaRichiedenti[i].isStartUp
          this.f.nomeAzienda.disable();
          this.aziendaSelected = true;
          this.f.nomeAzienda.updateValueAndValidity();
        } else {
          this.bilancioService.getDataLastBilancio(this.listaRichiedenti[i].id).subscribe(checkBil => {
            if (checkBil != null) {
              if (this.currentAnno - checkBil.anno >= 3) {
                if (this.modalService.hasOpenModals()) {
                  this.modalService.dismissAll();
                }
                const modalRef = this.modalService.open(ModalcontentComponent, {
                  backdrop: 'static',
                  backdropClass: 'customBackdrop',
                });
                modalRef.componentInstance.modalPar = {
                  name: "assenzaBilancio",
                  title: "",
                  description: this.currentAnno - checkBil.anno == 3 ? "Non sono stati trovati, sul registro imprese, i bilanci relativi all'azienda " + this.listaNomiAzienda[i] +
                    " per l'anno " + (checkBil.anno).toString() + ", pertanto non è possibile proseguire con la configurazione dell'ambiente. La preghiamo di contattare l'assistenza Endy." : (this.currentAnno - checkBil.anno == 4 ?
                      "Non sono stati trovati, sul registro imprese, i bilanci relativi all'azienda " + this.listaNomiAzienda[i] +
                      " per gli anni " + (checkBil.anno - 1).toString() + ", " + (checkBil.anno).toString() + ", pertanto non è possibile proseguire con la configurazione dell'ambiente. La preghiamo di contattare l'assistenza Endy." :
                      "Non sono stati trovati, sul registro imprese, i bilanci relativi all'azienda " + this.listaNomiAzienda[i] +
                      " per gli anni " + (checkBil.anno - 2).toString() + ", " + (checkBil.anno - 1).toString() + ", " + (checkBil.anno).toString() + ", pertanto non è possibile proseguire con la configurazione dell'ambiente. La preghiamo di contattare l'assistenza Endy."),
                  undoButtonText: "Esci",
                }
                modalRef.result.then((r) => {
                  if (!r) {
                    this.f.nomeAzienda.setValue('')
                    this.unlock()
                    clearTimeout(this.timer)
                    this.richiedenteTemp = null
                  }
                })
              } else {
                /* prosegui */
                //this.dataBilObj = checkBil
                this.bilancioService.loadDataBilObj(checkBil)
                this.richiedenteTemp = this.listaRichiedenti[i];
                this.codiceAtecoPrimario = this.listaRichiedenti[i].codiceAtecoPrimario;
                this.isStartUp = this.listaRichiedenti[i].isStartUp

                this.bilancioService.getAziendaRichiedente(this.listaRichiedenti[i])
                this.f.nomeAzienda.disable();
                this.aziendaSelected = true;
                this.f.nomeAzienda.updateValueAndValidity();
              }
            } else {
              /* modale che mi fa uscire */
              if (this.modalService.hasOpenModals()) {
                this.modalService.dismissAll();
              }
              const modalRef = this.modalService.open(ModalcontentComponent, {
                backdrop: 'static',
                backdropClass: 'customBackdrop',
              });
              modalRef.componentInstance.modalPar = {
                name: "assenzaBilancio",
                title: "",
                description: "Non è stato trovato sul registro imprese alcun bilancio relativo all'azienda " + this.listaNomiAzienda[i] + ", pertanto non è possibile proseguire con la configurazione dell'ambiente. La preghiamo di contattare l'assistenza Endy.",
                undoButtonText: "Esci",
              }
              modalRef.result.then((r) => {
                if (!r) {
                  this.f.nomeAzienda.setValue('')
                  this.unlock()
                  clearTimeout(this.timer)
                  this.richiedenteTemp = null
                }
              })
            }
          })
        }

      }


    }

  }

  resetCountdistintaBase() {
    this.configurazioneAmbienteService.resetDistintaBaseCount()
  }
}
