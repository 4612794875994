<div class="sfondo">
  <nav class="navbar navbar-expand navbar-custom">

    <ul class="navbar-nav me-auto" routerLinkActive="active">
      <li class="nav-item">
        <img *ngIf="this.router.url == '/login' else logoNonlogin" class="pointer" src="./assets/img/logo_goalgo.png"
          alt="" (click)="checkRoute()" style="margin-top: -20px;">
        <ng-template #logoNonlogin>
          <img class="pointer" src="./assets/img/logo_goalgo.png" alt="" (click)="checkRoute()">
        </ng-template>
      </li>
      <li class="loggato" *ngIf="isLoggedIn">

        <h5 (click)="checkRoute()"><br>&nbsp;<span class="arancione-goalgo">E</span>nterprise <span class="verde-goalgo">B</span>uddy</h5>
      </li>
      <li class="nav-item" *ngIf="showAdminBoard">
        <a href="/admin" class="nav-link" routerLink="admin">Admin Board</a>
      </li>
      <li class="nav-item" *ngIf="showFrontOfficeBoard">
        <a href="/frontoffice" class="nav-link" routerLink="mod">Front Office Board</a>
      </li>
    </ul>
    <ul class="navbar-nav ms-auto" style="margin-right: 50px" *ngIf="!isLoggedIn">
      <!--
      <li class="nav-item">
        <a href="/register" class="nav-link" routerLink="register"><i class="fa fa-user fa-fw fa-lg"
            aria-hidden="true"></i>Registrati</a>
      </li>
      -->
      <li class="nav-item">
        <a href="/login" class="nav-link" routerLink="login"><i class="fa fa-lock fa-fw fa-lg"
            aria-hidden="true"></i>Login</a>
      </li>
    </ul>
    <ul class="navbar-nav ms-auto" style="margin-right: 50px" *ngIf="isLoggedIn">
      <li class="nav-item margin-left-right-15" *ngIf="smartBp">
        <a href="#" class="nav-link">{{nomeAziendaSmart}}</a>
      </li>      
      <li class="nav-item margin-left-right-15">
        <a href="#" class="nav-link">{{nomeCliente}}</a>
      </li>
      <li class="nav-item margin-left-right-15">
        <a href="/profile" class="nav-link" routerLink="profile">{{ username }}</a>
      </li>
      <li *ngIf="user.roles.id == 0" class="nav-item margin-left-right-15">
        <a href="/tooltip" class="nav-link" routerLink="tooltip">Gestione Help</a>
      </li>
      <li class="nav-item margin-left-right-15">
        <a href class="nav-link" (click)="logout()"><i class="fa fa-unlock fa-fw fa-lg"></i>LogOut</a>
      </li>
    </ul>
  </nav>

  <app-header></app-header>

  <div class="container contenitore-principale" id="cont-princ" style="max-width: 100vw;">
    <div class="loading-container" *ngIf="isLoading">
      <div class="spinner-border text-primary" style="width: 50px; height: 50px" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <router-outlet></router-outlet>

  </div>
</div>