<div class="container-fluid" [formGroup]="ricaviEcostiForm">
    <div class="row">
        <div class="col-12" *ngIf="lineaBpSaved">
            <h2 class="saved">Linea business salvata</h2>
        </div>
    </div>
    <div class="row margin-top-20 nopadding" id="saveLinee">
        <!-- style="width: 94.45% !important;" -->
        <div class="col-4 d-flex align-items-center nopadding">
            <h2 class="align-middle labelLineeBusiness">Scelta valorizzazione voci Ricavi/Costi:&nbsp;&nbsp;<i
                    style="margin-top:-10px" id="icon" class="fa fa-1x fa-info-circle" aria-hidden="true"
                    placement="top" container="body" [ngbTooltip]="listaTips[7]?.descrizione"></i></h2>
        </div>

        <div class="col-5 d-flex align-items-center justify-content-start nopadding">
            <div class="row">
                <div class="col-12 col-xl-10 d-flex align-items-start">
                    <select formControlName="valorizzazione" type="text" class="form-control form-select"
                        [ngClass]="{ 'is-invalid':submitted && f.valorizzazione.errors }"
                        (change)="onChangeValorizzazione();onChange()" style="width: 100%;">
                        <option [ngValue]="valorizzazione" *ngFor="let valorizzazione of listaValorizzazione">
                            {{valorizzazione.descrizione}}
                        </option>
                    </select>
                    <div *ngIf="submitted && f.valorizzazione.errors" class="invalid-feedback margin-left-20">
                        <div *ngIf="f.valorizzazione.errors.required">Campo obbligatorio</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-3 d-flex justify-content-end align-items-center margin-bottom-10 margin-top-10">
            <button
                *ngIf="businessPlanTemp!=null && f.valorizzazione.value != '' && (businessPlanTemp.idStato==1 ||businessPlanTemp.idStato==2)"
                class="btn btn-secondary margin-right-20" type="button" (click)="resetLinea()">
                Disabilita linea
            </button>
            <button *ngIf="businessPlanTemp!=null && (businessPlanTemp.idStato==1 ||businessPlanTemp.idStato==2)"
                class="btn btn-secondary" type="button" (click)="Annulla()">
                Annulla modifiche
            </button>
        </div>
    </div>
    <!--SU BASE-->
    <hr>
    <!-- <div id="container-lineebp-body-bp"> -->
    <div *ngIf="f.valorizzazione && f.valorizzazione.value.id==1" class="margin-top-20">
        <!--  id="addPaddingInStickyRicavi" -->

        <div class="row">
            <div class="col-5 d-flex align-items-center">
                <h2 class="align-middle labelLineeBusiness">Inserimento valori ricavi/costi complessivi :
                    &nbsp;&nbsp;<i style="margin-top:-10px" id="icon" class="fa fa-1x fa-info-circle" aria-hidden="true"
                        placement="top" container="body" [ngbTooltip]="listaTips[11]?.descrizione"></i></h2>
            </div>
        </div>
        <div class="row">
            <div class=" col-4 col-xl-5 d-flex align-items-center margin-bottom-20 margin-top-20">
                <h3 class="align-middle labelLineeBusiness">Abilita oscillazioni per Linea di
                    Business&nbsp;&nbsp;<i id="icon" class="fa fa-1x fa-info-circle" aria-hidden="true" placement="top"
                        container="body" [ngbTooltip]="listaTips[12]?.descrizione"></i> </h3>
                <input class="margin-left-20" type="checkbox" formControlName="oscillazioniComplessive"
                    (change)="onChangeOscillazioniComplessive()">
            </div>
        </div>
        <div class="row">
            <div class="col-4 col-xl-3 .margin-right-20">
                <label class="align-middle">Evoluzione:</label>
                <select formControlName="evoluzioneComplessiva" type="text" value="" class="form-control form-select"
                    [ngClass]="{ 'is-invalid':submitted && f.evoluzioneComplessiva.errors }" style="width:100%;"
                    (change)="onChangeEvoluzioneComplessiva()">
                    <option [ngValue]="evoluzioneComplessiva" *ngFor="let evoluzioneComplessiva of listaEvoluzione">
                        {{evoluzioneComplessiva.descrizione}}
                    </option>
                </select>
                <div *ngIf="submitted && f.evoluzioneComplessiva.errors" class="invalid-feedback">
                    <div *ngIf="f.evoluzioneComplessiva.errors.required">Campo obbligatorio</div>
                </div>
            </div>
        </div>
        <div class="row margin-bottom-20 margin-top-20" [formGroup]="ricavi">
            <div class="col-2 col-xl-1 d-flex align-items-center">
                <label class="text-center margin-top-10 responsive-size-label"> Ricavi:</label>
            </div>
            <div class="col-2">
                <label class="responsive-size-label">Ricavi iniziali(€)</label>
                <input type="text" class="form-control" formControlName="ricaviIniziali" currencyMask
                    [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                    [ngClass]="{ 'is-invalid':submitted && ricavi.controls.ricaviIniziali.errors,'warning-control':warningLinea }"
                    onkeyup="if(this.value<0){this.value= this.value *-1}" (keyup)="checkMaggiore()" />
                <div *ngIf=" submitted && ricavi.controls.ricaviIniziali.errors" class="invalid-feedback">
                    <div *ngIf="ricavi.controls.ricaviIniziali.errors.required">Campo obbligatorio</div>
                </div>
            </div>
            <div class="col-2" *ngIf="f.oscillazioniComplessive.value">
                <label class="responsive-size-label">Ribasso (%)</label>
                <input type="number" class="form-control" formControlName="ribasso" min="0" style="width: 50%;"
                    onkeyup="if(this.value<0){this.value= this.value *-1}" (keyup)="checkValMax('ricaviRibasso')"
                    [ngClass]="{ 'is-invalid':submitted && ricavi.controls.ribasso.errors,'warning-control':warningRicaviRibasso }" />
                <div *ngIf=" submitted && ricavi.controls.ribasso.errors" class="invalid-feedback">
                    <div *ngIf="ricavi.controls.ribasso.errors.required">Campo obbligatorio</div>
                </div>
                <div *ngIf="warningRicaviRibasso" class="warning-label">
                    <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo di mantenere
                    il Ribasso sotto il {{valoreMax.ribasso}}%
                </div>
            </div>
            <div class="col-2" *ngIf="f.oscillazioniComplessive.value">
                <label class="responsive-size-label">Rialzo (%)</label>
                <input type="number" class="form-control" formControlName="rialzo" min="0" style="width: 50%;"
                    [ngClass]="{ 'is-invalid':submitted && ricavi.controls.rialzo.errors,'warning-control':warningRicaviRialzo }"
                    onkeyup="if(this.value<0){this.value= this.value *-1}" (keyup)="checkValMax('ricaviRialzo')" />
                <div *ngIf=" submitted && ricavi.controls.rialzo.errors" class="invalid-feedback">
                    <div *ngIf="ricavi.controls.rialzo.errors.required">Campo obbligatorio</div>
                </div>
                <div *ngIf="warningRicaviRialzo" class="warning-label">
                    <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo di mantenere
                    il Rialzo sotto il {{valoreMax.rialzo}}%
                </div>
            </div>
            <div class="col-2" *ngIf="f.oscillazioniComplessive.value">
                <label class="responsive-size-label">Scarto (%)&nbsp;<i id="icon" class="fa fa-1x fa-info-circle"
                        aria-hidden="true" container="body" placement="top"
                        ngbTooltip={{listaTips[8]?.descrizione}}></i></label>
                <input type="number" class="form-control" formControlName="scarto" min="0" style="width: 50%;"
                    [ngClass]="{ 'is-invalid':submitted && ricavi.controls.scarto.errors,'warning-control':warningRicaviScarto }"
                    onkeyup="if(this.value<0){this.value= this.value *-1}" (keyup)="checkValMax('ricaviScarto')" />
                <div *ngIf=" submitted && ricavi.controls.scarto.errors" class="invalid-feedback">
                    <div *ngIf="ricavi.controls.scarto.errors.required">Campo obbligatorio</div>
                </div>
                <div *ngIf="warningRicaviScarto" class="warning-label">
                    <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo di mantenere
                    lo Scarto sotto il {{valoreMax.scarto}}%
                </div>
            </div>
            <div class="col-2" *ngIf="f.evoluzioneComplessiva.value.id==2">
                <label class="responsive-size-label">Drift (%)&nbsp;<i id="icon" class="fa fa-1x fa-info-circle"
                        aria-hidden="true" container="body" placement="top"
                        ngbTooltip={{listaTips[9]?.descrizione}}></i></label>
                <input type="number" class="form-control" formControlName="drift" style="width: 50%;"
                    [ngClass]="{ 'is-invalid':submitted && ricavi.controls.drift.errors ,'warning-control':warningRicaviDrift }"
                    (keyup)="checkValMax('ricaviDrift')" />
                <div *ngIf="submitted && ricavi.controls.drift.errors" class="invalid-feedback">
                    <div *ngIf="ricavi.controls.drift.errors.required">Campo obbligatorio</div>
                </div>
                <div *ngIf="warningRicaviDrift" class="warning-label">
                    <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo di mantenere
                    il Drift sotto il {{valoreMax.drift}}%
                </div>
            </div>
            <div class="col-12" *ngIf="f.evoluzioneComplessiva.value && f.evoluzioneComplessiva.value.id==1">
                <div formArrayName="driftRicaviAnnuale" class="row margin-top-20 d-flex align-items-center">
                    <div class="col-1"><label class="responsive-size-label">Drift annuale ricavi(%)&nbsp;<i id="icon"
                                class="fa fa-1x fa-info-circle" aria-hidden="true" container="body" placement="top"
                                ngbTooltip={{listaTips[9]?.descrizione}}></i></label></div>
                    <div class="col-2 col-xl-1" *ngFor="let annoFormRicavi of driftRicaviAnnualeFormArray;let i =index">
                        <label>% al {{arrayAnni[i]}}</label>
                        <input type="number" [formControlName]="i" class="form-control" style="width: 100%;"
                            [ngClass]="{ 'is-invalid':submitted && driftRicaviAnnualeFormArray[i].errors,'warning-control':warningRicaviDriftAnnuale[i]}"
                            (keyup)="checkValMaxDiftRicaviAnnuale(i)" />
                        <div *ngIf=" submitted && driftRicaviAnnualeFormArray[i].errors" class="invalid-feedback">
                            <div *ngIf="driftRicaviAnnualeFormArray[i].errors.required">
                                Campo
                                obbligatorio
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row margin-bottom-20 margin-top-20" *ngIf="warningRicaviDriftAnnualeTotale">
            <div class="col-1"></div>
            <div class="warning-label col-4">
                <strong style="font-size: 1em">Warning!</strong> Valore elevato. Consigliamo di mantenere
                il Drift sotto il {{valoreMax.drift}}%
            </div>
        </div>
        <hr *ngIf="f.evoluzioneComplessiva.value && f.evoluzioneComplessiva.value.id==1">
        <div class="row" [formGroup]="costi">
            <div class="col-2 col-xl-1 d-flex align-items-center">
                <label class="text-center margin-top-10 responsive-size-label"> Costi:</label>
            </div>
            <div class="col-2">
                <label class="responsive-size-label responsive-size-label">Costi iniziali(€)</label>
                <input type="text" class="form-control" formControlName="costiIniziali" min="0"
                    onkeyup="if(this.value<0){this.value= this.value *-1}" currencyMask
                    [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                    [ngClass]="{ 'is-invalid':submitted && costi.controls.costiIniziali.errors,'warning-control':warningLinea}"
                    (keyup)="checkMaggiore()" />
                <div *ngIf=" submitted && costi.controls.costiIniziali.errors" class="invalid-feedback">
                    <div *ngIf="costi.controls.costiIniziali.errors.required">Campo obbligatorio</div>
                </div>
            </div>
            <div class="col-2" *ngIf="f.oscillazioniComplessive.value">
                <label class="responsive-size-label responsive-size-label">Ribasso (%)</label>
                <input type="number" class="form-control" formControlName="ribasso" min="0" style="width: 50%;"
                    onkeyup="if(this.value<0){this.value= this.value *-1} " (keyup)="checkValMax('costiRibasso')"
                    [ngClass]="{ 'is-invalid':submitted && costi.controls.ribasso.errors,'warning-control':warningCostiRibasso }" />
                <div *ngIf=" submitted && costi.controls.ribasso.errors" class="invalid-feedback">
                    <div *ngIf="costi.controls.ribasso.errors.required">Campo obbligatorio</div>
                </div>
                <div *ngIf="warningCostiRibasso" class="warning-label">
                    <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo di mantenere
                    il Ribasso sotto il {{valoreMax.ribasso}}%
                </div>
            </div>
            <div class="col-2" *ngIf="f.oscillazioniComplessive.value">
                <label class="responsive-size-label">Rialzo (%)</label>
                <input type="number" class="form-control" formControlName="rialzo" min="0" style="width: 50%;"
                    onkeyup="if(this.value<0){this.value= this.value *-1}"
                    [ngClass]="{ 'is-invalid':submitted && costi.controls.rialzo.errors,'warning-control':warningCostiRialzo }"
                    (keyup)="checkValMax('costiRialzo')" />
                <div *ngIf=" submitted && costi.controls.rialzo.errors" class="invalid-feedback">
                    <div *ngIf="costi.controls.rialzo.errors.required">Campo obbligatorio</div>
                </div>
                <div *ngIf="warningCostiRialzo" class="warning-label">
                    <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo di mantenere
                    il Rialzo sotto il {{valoreMax.rialzo}}%
                </div>
            </div>
            <div class="col-2" *ngIf="f.oscillazioniComplessive.value">
                <label class="responsive-size-label">Scarto (%)&nbsp;<i id="icon" class="fa fa-1x fa-info-circle"
                        aria-hidden="true" container="body" placement="top"
                        ngbTooltip={{listaTips[8]?.descrizione}}></i></label>
                <input type="number" class="form-control" formControlName="scarto" min="0" style="width: 50%;"
                    onkeyup="if(this.value<0){this.value= this.value *-1}"
                    [ngClass]="{ 'is-invalid':submitted && costi.controls.scarto.errors,'warning-control':warningCostiScarto }"
                    (keyup)="checkValMax('costiScarto')" />
                <div *ngIf=" submitted && costi.controls.scarto.errors" class="invalid-feedback">
                    <div *ngIf="costi.controls.scarto.errors.required">Campo obbligatorio</div>
                </div>
                <div *ngIf="warningCostiScarto" class="warning-label">
                    <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo di mantenere
                    lo Scarto sotto il {{valoreMax.scarto}}%
                </div>
            </div>
            <div class="col-2" *ngIf="f.evoluzioneComplessiva.value && f.evoluzioneComplessiva.value.id==2">
                <label class="responsive-size-label">Drift (%)&nbsp;<i id="icon" class="fa fa-1x fa-info-circle"
                        aria-hidden="true" container="body" placement="top"
                        ngbTooltip={{listaTips[9]?.descrizione}}></i></label>
                <input type="number" class="form-control" formControlName="drift" style="width: 50%;"
                    [ngClass]="{ 'is-invalid':submitted && costi.controls.drift.errors,'warning-control':warningCostiDrift }"
                    (keyup)="checkValMax('costiDrift')" />
                <div *ngIf=" submitted && costi.controls.drift.errors" class="invalid-feedback">
                    <div *ngIf="costi.controls.drift.errors.required">Campo obbligatorio</div>
                </div>
                <div *ngIf="warningCostiDrift" class="warning-label">
                    <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo di mantenere
                    il Drift sotto il {{valoreMax.drift}}%
                </div>
            </div>
            <div class="col-12" *ngIf="f.evoluzioneComplessiva.value && f.evoluzioneComplessiva.value.id==1">
                <div formArrayName="driftCostiAnnuale" class="row margin-top-20 d-flex align-items-center">
                    <div class="col-1"><label class="responsive-size-label">Drift annuale costi (%)&nbsp;<i id="icon"
                                class="fa fa-1x fa-info-circle" aria-hidden="true" container="body" placement="top"
                                ngbTooltip={{listaTips[9]?.descrizione}}></i></label></div>
                    <div class="col-2 col-xl-1 margin-bottom-10"
                        *ngFor="let annoFormCosti of driftCostiAnnualeFormArray;let i =index">
                        <label>% al {{arrayAnni[i]}}</label>
                        <input type="number" [formControlName]="i" class="form-control" style="width: 100%;"
                            [ngClass]="{ 'is-invalid':submitted && driftCostiAnnualeFormArray[i].errors,'warning-control':warningCostiDriftAnnuale[i]}"
                            (keyup)="checkValMaxDiftCostiAnnuale(i)" />
                        <div *ngIf=" submitted && driftCostiAnnualeFormArray[i].errors" class="invalid-feedback">
                            <div *ngIf="driftCostiAnnualeFormArray[i].errors.required">
                                Campo
                                obbligatorio
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row margin-bottom-20 margin-top-20" *ngIf="warningCostiDriftAnnualeTotale">
            <div class="col-1"></div>
            <div class="warning-label col-4">
                <strong style="font-size: 1em">Warning!</strong><br> Valore elevato. Consigliamo di mantenere
                il Drift sotto il {{valoreMax.drift}}%
            </div>
        </div>
        <div class="row" *ngIf=" warningLinea">
            <div class=" col-1 "></div>
            <div class="col-3 warning-label">
                <strong style="font-size: 1em">Warning!</strong> I Costi inseriti risultano maggiori dei Ricavi
            </div>
        </div>
    </div>

    <!--IN DISTINTA BASE-->
    <div *ngIf="f.valorizzazione.value && f.valorizzazione.value.id==2" class=" margin-top-20"
        id="addPaddingInStickyRicavi">
        <div class="row">
            <div class="col-4 col-xl-3 .margin-right-20">
                <label class="align-middle">Evoluzione:</label>
                <select formControlName="evoluzioneUnitaria" type="text" value="" class="form-control form-select"
                    [ngClass]="{ 'is-invalid':submitted && f.evoluzioneUnitaria.errors }" style="width:100%;"
                    (change)="onChangeEvoluzioneUnitaria()">
                    <option [ngValue]="evoluzioneUnitaria" *ngFor="let evoluzioneUnitaria of listaEvoluzione">
                        {{evoluzioneUnitaria.descrizione}}
                    </option>
                </select>
                <div *ngIf="submitted && f.evoluzioneUnitaria.errors" class="invalid-feedback">
                    <div *ngIf="f.evoluzioneUnitaria.errors.required">Campo obbligatorio</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-3 col-xl-2 d-flex align-items-center">
                <div class="d-flex align-items-center">
                    <h3 class="align-middle">Abilita oscillazioni&nbsp;&nbsp; <i style="margin-top:-10px" id="icon"
                            class="fa fa-1x fa-info-circle" aria-hidden="true" placement="top" container="body"
                            [ngbTooltip]="listaTips[13]?.descrizione"></i></h3>
                    <input class="margin-left-20" type="checkbox" formControlName="oscillazioniLinea"
                        (change)="onChangeOscillazionilinea()">
                </div>

            </div>

            <div class="col-8">
                <table class="table table-borderless">
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            <th *ngIf="f.oscillazioniLinea.value" scope="col" class="text-left responsive-size-label">%
                                Ribasso
                            </th>
                            <th *ngIf="f.oscillazioniLinea.value" scope="col" class="text-left responsive-size-label">%
                                Rialzo
                            </th>
                            <th *ngIf="f.oscillazioniLinea.value" scope="col" class="text-left responsive-size-label">%
                                Scarto&nbsp;<i id="icon" class="fa fa-1x fa-info-circle" aria-hidden="true"
                                    container="body" placement="top" ngbTooltip={{listaTips[8]?.descrizione}}></i>
                            </th>
                            <th *ngIf="f.evoluzioneUnitaria.value && f.evoluzioneUnitaria.value.id==2"
                                [ngClass]="{'drift-width': !f.oscillazioniLinea.value}" scope="col"
                                class="text-left responsive-size-label">% Drift&nbsp;<i id="icon"
                                    class="fa fa-1x fa-info-circle" aria-hidden="true" container="body" placement="top"
                                    ngbTooltip={{listaTips[9]?.descrizione}}></i>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr formGroupName="quantitaVenduta">
                            <th *ngIf="(f.oscillazioniLinea.value || f.evoluzioneUnitaria.value && f.evoluzioneUnitaria.value.id==2) && !(lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1)"
                                scope="row" class="align-middle text-right" style="width: 25%;">Q.tà venduta:
                            </th>
                            <th *ngIf="(f.oscillazioniLinea.value || f.evoluzioneUnitaria.value && f.evoluzioneUnitaria.value.id==2) && (lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1)"
                            scope="row" class="align-middle text-right" style="width: 25%;">Resa previsionale:
                            </th>
                            <td *ngIf="f.oscillazioniLinea.value"><input formControlName="ribasso" type="number"
                                    class="form-control" min="0" onkeyup="if(this.value<0){this.value= this.value *-1}"
                                    (keyup)="checkValMax('quantitaVendutaRibasso')"
                                    [ngClass]="{ 'is-invalid':submitted && quantitaVenduta.controls.ribasso.errors,'warning-control':warningQuantitaVendutaRibasso }" />
                                <div *ngIf=" submitted && quantitaVenduta.controls.ribasso.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="quantitaVenduta.controls.ribasso.errors.required">
                                        Campo
                                        obbligatorio
                                    </div>
                                </div>
                                <div *ngIf="warningQuantitaVendutaRibasso" class="warning-label">
                                    <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo
                                    di
                                    mantenere
                                    il Ribasso sotto il {{valoreMax.ribasso}}%
                                </div>
                            </td>
                            <td *ngIf="f.oscillazioniLinea.value"><input formControlName="rialzo" type="number"
                                    class="form-control" min="0" onkeyup="if(this.value<0){this.value= this.value *-1}"
                                    (keyup)="checkValMax('quantitaVendutaRialzo')"
                                    [ngClass]="{ 'is-invalid':submitted && quantitaVenduta.controls.rialzo.errors ,'warning-control':warningQuantitaVendutaRialzo }" />
                                <div *ngIf=" submitted && quantitaVenduta.controls.rialzo.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="quantitaVenduta.controls.rialzo.errors.required">
                                        Campo
                                        obbligatorio
                                    </div>
                                </div>
                                <div *ngIf="warningQuantitaVendutaRialzo" class="warning-label">
                                    <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo
                                    di
                                    mantenere
                                    il Rialzo sotto il {{valoreMax.rialzo}}%
                                </div>
                            </td>
                            <td *ngIf="f.oscillazioniLinea.value"><input formControlName="scarto" type="number"
                                    class="form-control" min="0" onkeyup="if(this.value<0){this.value= this.value *-1}"
                                    (keyup)="checkValMax('quantitaVendutaScarto')"
                                    [ngClass]="{ 'is-invalid':submitted && quantitaVenduta.controls.scarto.errors ,'warning-control':warningQuantitaVendutaScarto}" />
                                <div *ngIf=" submitted && quantitaVenduta.controls.scarto.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="quantitaVenduta.controls.scarto.errors.required">
                                        Campo obbligatorio</div>
                                </div>
                                <div *ngIf="warningQuantitaVendutaScarto" class="warning-label">
                                    <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo
                                    di
                                    mantenere
                                    lo Scarto sotto il {{valoreMax.scarto}}%
                                </div>
                            </td>
                            <td *ngIf="f.evoluzioneUnitaria.value && f.evoluzioneUnitaria.value.id==2"
                                [ngClass]="{'drift-width': !f.oscillazioniLinea.value}"><input formControlName="drift"
                                    type="number" class="form-control" (keyup)="checkValMax('quantitaVendutaDrift')"
                                    [ngClass]="{ 'is-invalid':submitted && quantitaVenduta.controls.drift.errors ,'warning-control':warningQuantitaVendutaDrift }" />
                                <div *ngIf=" submitted && quantitaVenduta.controls.drift.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="quantitaVenduta.controls.drift.errors.required">
                                        Campo
                                        obbligatorio
                                    </div>
                                </div>
                                <div *ngIf="warningQuantitaVendutaDrift" class="warning-label">
                                    <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo
                                    di
                                    mantenere
                                    il Drift sotto il {{valoreMax.drift}}%
                                </div>
                            </td>
                        </tr>
                        <tr formGroupName="prezzoIniziale">
                            <th *ngIf="(f.oscillazioniLinea.value || f.evoluzioneUnitaria.value && f.evoluzioneUnitaria.value.id==2) && !(lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1)"
                                scope="row" class="align-middle text-right" style="width: 25%;">Prezzo iniziale:
                            </th>
                            <th *ngIf="(f.oscillazioniLinea.value || f.evoluzioneUnitaria.value && f.evoluzioneUnitaria.value.id==2) && (lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1)"
                            scope="row" class="align-middle text-right" style="width: 25%;">Ricavi previsionali:
                            </th>

                            <td *ngIf="f.oscillazioniLinea.value"><input formControlName="ribasso" type="number"
                                    class="form-control" min="0" onkeyup="if(this.value<0){this.value= this.value *-1}"
                                    (keyup)="checkValMax('prezzoInizialeRibasso')"
                                    [ngClass]="{ 'is-invalid':submitted && prezzoIniziale.controls.ribasso.errors ,'warning-control':warningPrezzoInizialeRibasso }" />
                                <div *ngIf=" submitted && prezzoIniziale.controls.ribasso.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="prezzoIniziale.controls.ribasso.errors.required">
                                        Campo
                                        obbligatorio
                                    </div>
                                </div>
                                <div *ngIf="warningPrezzoInizialeRibasso" class="warning-label">
                                    <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo
                                    di
                                    mantenere
                                    il Ribasso sotto il {{valoreMax.ribasso}}%
                                </div>
                            </td>
                            <td *ngIf="f.oscillazioniLinea.value"><input formControlName="rialzo" type="number"
                                    class="form-control" min="0" onkeyup="if(this.value<0){this.value= this.value *-1}"
                                    (keyup)="checkValMax('prezzoInizialeRialzo')"
                                    [ngClass]="{ 'is-invalid':submitted && prezzoIniziale.controls.rialzo.errors  ,'warning-control':warningPrezzoInizialeRialzo }" />
                                <div *ngIf=" submitted && prezzoIniziale.controls.rialzo.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="prezzoIniziale.controls.rialzo.errors.required">
                                        Campo
                                        obbligatorio
                                    </div>
                                </div>
                                <div *ngIf="warningPrezzoInizialeRialzo" class="warning-label">
                                    <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo
                                    di
                                    mantenere
                                    il Rialzo sotto il {{valoreMax.rialzo}}%
                                </div>
                            </td>
                            <td *ngIf="f.oscillazioniLinea.value"><input formControlName="scarto" type="number"
                                    class="form-control" min="0.1"
                                    onkeyup="if(this.value<0){this.value= this.value *-1}"
                                    (keyup)="checkValMax('prezzoInizialeScarto')"
                                    [ngClass]="{ 'is-invalid':submitted && prezzoIniziale.controls.scarto.errors  ,'warning-control':warningPrezzoInizialeScarto}" />
                                <div *ngIf=" submitted && prezzoIniziale.controls.scarto.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="prezzoIniziale.controls.scarto.errors.required">
                                        Campo
                                        obbligatorio
                                    </div>
                                </div>
                                <div *ngIf="warningPrezzoInizialeScarto" class="warning-label">
                                    <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo
                                    di
                                    mantenere
                                    il Scarto sotto il {{valoreMax.scarto}}%
                                </div>
                            </td>
                            <td *ngIf="f.evoluzioneUnitaria.value && f.evoluzioneUnitaria.value.id==2"
                                [ngClass]="{'drift-width': !f.oscillazioniLinea.value}"><input formControlName="drift"
                                    type="number" class="form-control" (keyup)="checkValMax('prezzoInizialeDrift')"
                                    [ngClass]="{ 'is-invalid':submitted && prezzoIniziale.controls.drift.errors  ,'warning-control':warningPrezzoInizialeDrift}" />
                                <div *ngIf=" submitted && prezzoIniziale.controls.drift.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="prezzoIniziale.controls.drift.errors.required">
                                        Campo
                                        obbligatorio
                                    </div>
                                </div>
                                <div *ngIf="warningPrezzoInizialeDrift" class="warning-label">
                                    <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo
                                    di
                                    mantenere
                                    il Drift sotto il {{valoreMax.drift}}%
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="f.oscillazioniLinea.value || f.evoluzioneUnitaria.value && f.evoluzioneUnitaria.value.id==2"
                            formGroupName="costoIniziale">
                            <th scope="row" class="align-middle text-right" style="width: 25%;" *ngIf="!(lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1)">Costo iniziale:
                            </th>
                            <th scope="row" class="align-middle text-right" style="width: 25%;" *ngIf="lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1">Costi previsionali:
                            </th>

                            <td *ngIf="f.oscillazioniLinea.value"><input formControlName="ribasso" type="number"
                                    class="form-control" min="0" onkeyup="if(this.value<0){this.value= this.value *-1}"
                                    (keyup)="checkValMax('costoInizialeRibasso')"
                                    [ngClass]="{ 'is-invalid':submitted && costoIniziale.controls.ribasso.errors  ,'warning-control':warningCostoInizialeRibasso}" />
                                <div *ngIf=" submitted && costoIniziale.controls.ribasso.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="costoIniziale.controls.ribasso.errors.required">
                                        Campo
                                        obbligatorio
                                    </div>
                                </div>
                                <div *ngIf="warningCostoInizialeRibasso" class="warning-label">
                                    <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo
                                    di
                                    mantenere
                                    il Ribasso sotto il {{valoreMax.ribasso}}%
                                </div>
                            </td>
                            <td *ngIf="f.oscillazioniLinea.value"><input formControlName="rialzo" type="number"
                                    class="form-control" min="0" onkeyup="if(this.value<0){this.value= this.value *-1}"
                                    (keyup)="checkValMax('costoInizialeRialzo')"
                                    [ngClass]="{ 'is-invalid':submitted && costoIniziale.controls.rialzo.errors ,'warning-control':warningCostoInizialeRialzo }" />
                                <div *ngIf=" submitted && costoIniziale.controls.rialzo.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="costoIniziale.controls.rialzo.errors.required">
                                        Campo
                                        obbligatorio
                                    </div>
                                </div>
                                <div *ngIf="warningCostoInizialeRialzo" class="warning-label">
                                    <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo
                                    di
                                    mantenere
                                    il Rialzo sotto il {{valoreMax.rialzo}}%
                                </div>
                            </td>
                            <td *ngIf="f.oscillazioniLinea.value"><input formControlName="scarto" type="number"
                                    class="form-control" min="0" onkeyup="if(this.value<0){this.value= this.value *-1}"
                                    (keyup)="checkValMax('costoInizialeScarto')"
                                    [ngClass]="{ 'is-invalid':submitted && costoIniziale.controls.scarto.errors ,'warning-control':warningCostoInizialeScarto }" />
                                <div *ngIf=" submitted && costoIniziale.controls.scarto.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="costoIniziale.controls.scarto.errors.required">
                                        Campo
                                        obbligatorio
                                    </div>
                                </div>
                                <div *ngIf="warningCostoInizialeScarto" class="warning-label">
                                    <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo
                                    di
                                    mantenere
                                    lo Scarto sotto il {{valoreMax.scarto}}%
                                </div>
                            </td>
                            <td *ngIf="f.evoluzioneUnitaria.value && f.evoluzioneUnitaria.value.id==2"
                                [ngClass]="{'drift-width': !f.oscillazioniLinea.value}"><input formControlName="drift"
                                    type="number" class="form-control"
                                    (keyup)="checkValMax('costoInizialeDrift')"
                                    [ngClass]="{ 'is-invalid':submitted && costoIniziale.controls.drift.errors ,'warning-control':warningCostoInizialeDrift}" />
                                <div *ngIf=" submitted && costoIniziale.controls.drift.errors" class="invalid-feedback">
                                    <div *ngIf="costoIniziale.controls.drift.errors.required">
                                        Campo
                                        obbligatorio
                                    </div>
                                </div>
                                <div *ngIf="warningCostoInizialeDrift" class="warning-label">
                                    <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo
                                    di
                                    mantenere
                                    il Drift sotto il {{valoreMax.drift}}%
                                </div>
                            </td>
                        </tr>
                    </tbody>

                </table>
            </div>
        </div>
        <div *ngIf="f.evoluzioneUnitaria.value && f.evoluzioneUnitaria.value.id==1"
            class="row d-flex align-items-center">

            <div class="col-3 col-xl-2">
                <h3 class="responsive-size-label">Drift
                    annuale(%)</h3>
            </div>
            <div class="col-9 col-xl-10">
                <div formArrayName="driftQuantitaInizialeAnnuale" class="row margin-top-10 d-flex align-items-center">
                    <div class="col-2"><label class="responsive-size-label"><strong *ngIf="!(lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1)">Q.ta&grave; venduta:</strong>
                        <strong *ngIf="lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1">Resa previsionale:</strong>
                        </label></div>
                    <div class="col-2 col-xl-1"
                        *ngFor="let annoFormCosti of driftQuantitaInizialeAnnualeFormArray;let i =index">
                        <label>% al {{arrayAnni[i]}}</label>
                        <input type="number" [formControlName]="i" class="form-control" style="width: 100%;"
                            [ngClass]="{ 'is-invalid':submitted && driftQuantitaInizialeAnnualeFormArray[i].errors,'warning-control':warningQuantitaInizialeDriftAnnuale[i]}"
                            (keyup)="checkValMaxDriftQuantitaInizialeAnnuale(i)" />
                        <div *ngIf=" submitted && driftQuantitaInizialeAnnualeFormArray[i].errors"
                            class="invalid-feedback">
                            <div *ngIf="driftQuantitaInizialeAnnualeFormArray[i].errors.required">
                                Campo
                                obbligatorio
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-1 margin-bottom-10 margin-top-10" *ngIf="warningQuantitaInizialeDriftAnnualeTot"></div>
                <div class="warning-label col-4 margin-bottom-10 margin-top-10"
                    *ngIf="warningQuantitaInizialeDriftAnnualeTot">
                    <strong style="font-size: 1em">Warning!</strong> Valore elevato. Consigliamo di mantenere
                    il Drift sotto il {{valoreMax.drift}}%
                </div>
                <div formArrayName="driftPrezzoInizialeAnnuale" class="row margin-top-10 d-flex align-items-center">
                    <div class="col-2"><label class="responsive-size-label"><strong *ngIf="!(lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1)">Prezzo iniziale:</strong>
                        <strong *ngIf="lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1">Ricavi previsionali:</strong>

                        </label>
                    </div>
                    <div class="col-2 col-xl-1"
                        *ngFor="let annoFormCosti of driftPrezzoInizialeAnnualeFormArray;let i =index">
                        <label>% al {{arrayAnni[i]}}</label>
                        <input type="number" [formControlName]="i" class="form-control" style="width: 100%;"
                            [ngClass]="{ 'is-invalid':submitted && driftPrezzoInizialeAnnualeFormArray[i].errors,'warning-control':warningPrezzoInizialeDriftAnnuale[i]}"
                            (keyup)="checkValMaxDriftPrezzoInizialeAnnuale(i)" />
                        <div *ngIf=" submitted && driftPrezzoInizialeAnnualeFormArray[i].errors"
                            class="invalid-feedback">
                            <div *ngIf="driftPrezzoInizialeAnnualeFormArray[i].errors.required">
                                Campo
                                obbligatorio
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-1 margin-bottom-10 margin-top-10" *ngIf="warningPrezzoInizialeDriftAnnualeTot">
                </div>
                <div class="warning-label col-4 margin-bottom-10 margin-top-10"
                    *ngIf="warningPrezzoInizialeDriftAnnualeTot">
                    <strong style="font-size: 1em">Warning!</strong> Valore elevato. Consigliamo di mantenere
                    il Drift sotto il {{valoreMax.drift}}%

                </div>
                <div formArrayName="driftCostoInizialeAnnuale" class="row margin-top-10 d-flex align-items-center">
                    <div class="col-2"><label class="responsive-size-label"><strong *ngIf="!(lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1)">Costo iniziale:</strong>
                        <strong *ngIf="lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1">Costi previsionali:</strong>
                        </label>
                    </div>
                    <div class="col-2 col-xl-1 margin-bottom-10"
                        *ngFor="let annoFormCosti of driftCostoInizialeAnnualeFormArray;let i =index">
                        <label>% al {{arrayAnni[i]}}</label>
                        <input type="number" [formControlName]="i" class="form-control" style="width: 100%;"
                            [ngClass]="{ 'is-invalid':submitted && driftCostoInizialeAnnualeFormArray[i].errors,'warning-control':warningCostoInizialeDriftAnnuale[i]}"
                            (keyup)="checkValMaxDriftCostoInizialeAnnuale(i)" />
                        <div *ngIf=" submitted && driftCostoInizialeAnnualeFormArray[i].errors"
                            class="invalid-feedback">
                            <div *ngIf="driftCostoInizialeAnnualeFormArray[i].errors.required">
                                Campo
                                obbligatorio
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-1 margin-bottom-10 margin-top-10" *ngIf="warningCostoInizialeDriftAnnualeTot"></div>
                <div class="warning-label col-4 margin-bottom-10 margin-top-10"
                    *ngIf="warningCostoInizialeDriftAnnualeTot">
                    <strong style="font-size: 1em">Warning!</strong> Valore elevato. Consigliamo di mantenere
                    il Drift sotto il {{valoreMax.drift}}%

                </div>
            </div>


        </div>

        <!-- prodotti -->
        <div formArrayName="prodotti" style="padding-right: 0px;">
            <div *ngFor="let prodotto of prodottiFormArray;let i = index" [formGroupName]="i" class=" margin-top-20">
                <hr>
                <h2 *ngIf="!(lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1)">Prodotto {{i+1}}</h2>
                <h2 *ngIf="lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1">{{prodottiTempArray[i].descrizione}}</h2>
                <div class="row no padding">
                    <div class="col-3" *ngIf="!(lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1)">
                        <label class="responsive-size-label">Descrizione prodotto</label>
                        <input type="text" formControlName="nomeProdotto" class="form-control"
                            [ngClass]="{ 'is-invalid':submitted && prodotto.controls.nomeProdotto.errors }" />
                        <div *ngIf=" submitted && prodotto.controls.nomeProdotto.errors" class="invalid-feedback">
                            <div *ngIf="prodotto.controls.nomeProdotto.errors.required">Campo obbligatorio
                            </div>
                        </div>
                    </div>
                    <div class="col-2" *ngIf="lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1">
                        <label class="responsive-size-label">Provincia</label>
                        <select formControlName="provincia"  class="form-control form-select"
                        style="width: 100%;">
                        <option [ngValue]="provincia.id" *ngFor="let provincia of listaProvince">
                            {{provincia.provincia}}
                        </option>
                    </select>
                    </div>

                    <div class="col-2" *ngIf="lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1">
                        <label class="responsive-size-label">Ettari</label>
                        <input type="number" formControlName="ettari" class="form-control" />
                       
                    </div>
                    <div class="col-2">
                        <label class="responsive-size-label" *ngIf="lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1">Resa previsionale complessiva (Q.li)</label>
                        <label class="responsive-size-label" *ngIf="!(lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1)">Q.tà venduta</label>
                        <input type="number" class="form-control" formControlName="quantitaVendutaProdotto" min="0"
                            onkeyup="if(this.value<0){this.value= this.value *-1}"
                            [ngClass]="{ 'is-invalid':submitted && prodotto.controls.quantitaVendutaProdotto.errors }" />
                        <div *ngIf=" submitted && prodotto.controls.quantitaVendutaProdotto.errors"
                            class="invalid-feedback">
                            <div *ngIf="prodotto.controls.quantitaVendutaProdotto.errors.required">Campo
                                obbligatorio
                            </div>
                        </div>
                    </div>
                    <div class="col-2">
                        <label class="responsive-size-label" *ngIf="lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1">Ricavi previsionali complessivi (€)</label>
                        <label class="responsive-size-label" *ngIf="!(lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1)">Prezzo iniziale(€)</label>
                        <input type="text" class="form-control" currencyMask min="0" style="min-width:120px;"
                            [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 2 , suffix: ' €', allowNegative: false }"
                            formControlName="prezzoInizialeProdotto"
                            [ngClass]="{ 'is-invalid':submitted && prodotto.controls.prezzoInizialeProdotto.errors,'warning-control':warningProdotti[i] }"
                            (keyup)=" checkMaggioreProdotti(i)" />

                        <div *ngIf=" submitted && prodotto.controls.prezzoInizialeProdotto.errors"
                            class="invalid-feedback">
                            <div *ngIf="prodotto.controls.prezzoInizialeProdotto.errors.required">Campo obbligatorio
                            </div>
                        </div>
                    </div>
                    <div class="col-2">
                        <label class="responsive-size-label" *ngIf="lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1">Costi previsionali complessivi (€)</label>
                        <label class="responsive-size-label" *ngIf="!(lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1)">Costo iniziale(€)</label>
                        <input type="text" class="form-control warning-control" currencyMask style="min-width:120px;"
                            [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 2 , suffix: ' €', allowNegative: false }"
                            formControlName="costoInizialeProdotto"
                            [ngClass]="{ 'is-invalid':submitted && prodotto.controls.costoInizialeProdotto.errors ,'warning-control':warningProdotti[i] }"
                            (keyup)=" checkMaggioreProdotti(i)" />
                        <!--  -->
                        <div *ngIf=" submitted && prodotto.controls.costoInizialeProdotto.errors"
                            class="invalid-feedback">
                            <div *ngIf="prodotto.controls.costoInizialeProdotto.errors.required">Campo obbligatorio
                            </div>
                        </div>
                    </div>

                    <div class="col-2" *ngIf="lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1">
                        <label class="responsive-size-label">Modalit&agrave; di vendita</label>
                        <select formControlName="canaleDistribuzione"  class="form-control form-select"
                        [ngClass]="{ 'is-invalid':submitted && prodotto.controls.canaleDistribuzione.errors }"
                         style="width: 100%;">
                        <option [ngValue]="canale.id" *ngFor="let canale of listaCanaliDistribuzione">
                            {{canale.descrizione}}
                        </option>
                    </select>
                         <div *ngIf=" submitted && prodotto.controls.canaleDistribuzione.errors" class="invalid-feedback">
                            <div *ngIf="prodotto.controls.canaleDistribuzione.errors.required">Campo obbligatorio
                            </div>
                        </div>
                    </div>

                    <div class="text-left warning-label" *ngIf=" warningProdotti[i]" [ngClass]="lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1 ? 'col-6 offset-6':'col-3'">
                        <strong style="font-size: 1em">Warning!</strong><br> I costi inseriti risultano maggiori dei
                        ricavi
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- </div> -->
    <!--  <hr style="margin-top: 10px;"> -->
</div>