import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ConfigurazioneAmbienteService } from 'src/app/_services/configurazione-ambiente.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalcontentComponent } from 'src/app/modalcontent/modalcontent/modalcontent.component';
import { LineaBusiness } from 'src/app/condivisi/linea-business';
import { ToolTip } from 'src/app/interface/toolTip';
import { ToolTipService } from 'src/app/_services/tool-tip.service';
import { BeanStatico } from 'src/app/condivisi/beanStatico';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { Richiedente } from './../../interface/richiedente';
import { regione } from 'src/app/interfaces/user-key';

@Component({
  selector: 'app-linee-business',
  templateUrl: './linee-business.component.html',
  styleUrls: ['./linee-business.component.css']
})
export class LineeBusinessComponent implements OnInit, OnDestroy {



  @Input() submitted: boolean;
  /* prendo in input  idRichiedenteTemp*/
  @Input() set richiedenteTemp(richiedenteTemp: Richiedente) {
    this.richiedenteTemp1 = richiedenteTemp
    this.saved = false;
    this.lineeBusinessForm.markAsPristine();
    if (richiedenteTemp != undefined) {
      this.idRichiedenteTemp = richiedenteTemp.id;
      this.lineeBusinessArray.clear();
      this.idLineeBusinessTempArray = [];
      this.onLoadLineeBusiness();
      this.submitted = false;
      this.addLineaDisabled = false;
      this.disabledSalva = false;
      this.agricoltura = richiedenteTemp.codiceAtecoPrimario.slice(0, 2) == '01' ||
        (richiedenteTemp.codiceAtecoSecondario1 != null && richiedenteTemp.codiceAtecoSecondario1 == '01') ||
        (richiedenteTemp.codiceAtecoSecondario2 != null && richiedenteTemp.codiceAtecoSecondario2 == '01')

      /* console.log(this.idLineeBusinessTempArray)
      console.log(this.lineeBusinessArray) */
    } else {
      this.disabledSalva = true;
    }
  }
  idRichiedenteTemp: number
  richiedenteTemp1: Richiedente;
  agricoltura: boolean = false;
  disabledSalva: boolean = true;
  addLineaDisabled: boolean = false;
  saved: boolean = false;
  valoreMax: any = {};
  idLineeBusinessTempArray: number[] = [];
  lineeBusinessForm: UntypedFormGroup;
  listaTips: ToolTip[] = [];
  listaEvoluzioneLinea: BeanStatico[];
  tipsSubscription: Subscription

  tipologieAttivita: BeanStatico[] = [];
  categorieProd: any[] = [];
  regioni: regione[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private tokeStorageService: TokenStorageService,
    private configurazioneAmbienteService: ConfigurazioneAmbienteService,
    private toolTipService: ToolTipService,
    private httpClient: HttpClient,
    private modalService: NgbModal,
  ) {
    this.lineeBusinessForm = this.formBuilder.group({
      lineeBusiness: this.formBuilder.array([])
    })
  }
  ngOnDestroy(): void {
    this.tipsSubscription.unsubscribe()
  }
  ngOnInit(): void {
    this.configurazioneAmbienteService.getTipologiaAttivita().subscribe(list => {
      this.tipologieAttivita = list
    })

    this.configurazioneAmbienteService.getCategorie().subscribe(list => {
      this.categorieProd = list
    })

    this.configurazioneAmbienteService.getRegioni().subscribe(list => {
      this.regioni = list
    })


    this.httpClient.get<BeanStatico[]>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneAmbiente/parametriGenerali/getModalitaEvoluzione').subscribe(response => {
      this.listaEvoluzioneLinea = response
    })

    this.configurazioneAmbienteService.getValoriMax('linee business').subscribe(response => {
      response.forEach(element => {
        if (element.error == null) {
          this.valoreMax[element.nomeCampo] = element.valoreMax;
        }
      });
      //console.log(this.valoreMax);
    })
    /* this.initialValues = this.lineeBusinessForm.value; */

    this.tipsSubscription = this.toolTipService.tipsArray.subscribe(arr => {
      if (arr != null) {
        this.listaTips = arr
      }
    })
  }
  onLoadLineeBusiness() {
    this.configurazioneAmbienteService.getLineeBusiness(this.idRichiedenteTemp, this.tokeStorageService.getUser().cliente.idCliente).subscribe(response => {
      /* console.log(response) */
      this.lineeBusinessArray.clear();
      this.idLineeBusinessTempArray = []  //serve per azienda singola
      if (response.error == null) {
        var value: BeanStatico = null;
        for (let i = 0; i < response.lineeBusiness.length; i++) {
          this.addLineaDisabled = response.lineeBusiness.length >= 10;
          //pusho l'id
          this.listaEvoluzioneLinea.forEach(element => {
            if (element.id == response.lineeBusiness[i].evoluzioneLineaDiBusiness.id) {
              value = element;
            }
          })
          let tipologiaAttTemp: BeanStatico = null
          this.tipologieAttivita.forEach(element => {
            if (element.id == response.lineeBusiness[i].idTipologiaAtt) {
              tipologiaAttTemp = element
            }
          })
          let categoriaProdTemp: BeanStatico = null
          this.categorieProd.forEach(element => {
            if (element.id == response.lineeBusiness[i].idCategoriaProd) {
              categoriaProdTemp = element
            }
          })
          let regioneTemp: regione = null
          this.regioni.forEach(element => {
            if (element.id == response.lineeBusiness[i].idRegione) {
              regioneTemp = element
            }
          })
          this.idLineeBusinessTempArray.push(response.lineeBusiness[i].id);
          this.lineeBusinessFormArray.push(this.formBuilder.group({
            nomeLineaBusiness: [response.lineeBusiness[i].nomeLineaBusiness, Validators.required],
            tipologiaAtt: [tipologiaAttTemp, this.agricoltura ? Validators.required : null],
            categoriaProd: [categoriaProdTemp, this.agricoltura ? Validators.required : null],
            sitoProduttivo: [regioneTemp, this.agricoltura ? Validators.required : null],
            presenzaMagazzino: [this.agricoltura ? false : response.lineeBusiness[i].presenzaMagazzino, this.agricoltura ? Validators.required : null],
            evoluzioneLineaDiBusiness: [value, Validators.required],/* MODIFICATO DA ANDREA.C 26/09/22 */
            quantitaVenduta: this.formBuilder.group({
              ribasso: [response.lineeBusiness[i].quantitaVenduta.ribasso, [Validators.required, Validators.max(this.valoreMax.ribasso)]],/* Validators.required */
              rialzo: [response.lineeBusiness[i].quantitaVenduta.rialzo, [Validators.required, Validators.max(this.valoreMax.rialzo)]],
              scarto: [response.lineeBusiness[i].quantitaVenduta.scarto, [Validators.min(0.1), Validators.required, Validators.max(this.valoreMax.scarto)]],
              drift: [response.lineeBusiness[i].quantitaVenduta.drift, [Validators.required, Validators.max(this.valoreMax.drift)]]
            }),
            prezzoIniziale: this.formBuilder.group({
              ribasso: [response.lineeBusiness[i].prezzoIniziale.ribasso, [Validators.required, Validators.max(this.valoreMax.ribasso)]],
              rialzo: [response.lineeBusiness[i].prezzoIniziale.rialzo, [Validators.required, Validators.max(this.valoreMax.rialzo)]],
              scarto: [response.lineeBusiness[i].prezzoIniziale.scarto, [Validators.min(0.1), Validators.required, Validators.max(this.valoreMax.scarto)]],
              drift: [response.lineeBusiness[i].prezzoIniziale.drift, [Validators.required, Validators.max(this.valoreMax.drift)]],
            }),
            costoIniziale: this.formBuilder.group({
              ribasso: [response.lineeBusiness[i].costoIniziale.ribasso, [Validators.required, Validators.max(this.valoreMax.ribasso)]],
              rialzo: [response.lineeBusiness[i].costoIniziale.rialzo, [Validators.required, Validators.max(this.valoreMax.rialzo)]],
              scarto: [response.lineeBusiness[i].costoIniziale.scarto, [Validators.min(0.1), Validators.required, Validators.max(this.valoreMax.scarto)]],
              drift: [response.lineeBusiness[i].costoIniziale.drift, [Validators.required, Validators.max(this.valoreMax.drift)]],
            }),
          }));
          this.lineeBusinessFormArray[i].controls.nomeLineaBusiness.setValue(response.lineeBusiness[i].nomeLineaBusiness);

          this.lineeBusinessFormArray[i].controls.nomeLineaBusiness.updateValueAndValidity();
          this.quantitaVendutaForm(i).controls.ribasso.updateValueAndValidity();
          this.quantitaVendutaForm(i).controls.rialzo.updateValueAndValidity();
          this.quantitaVendutaForm(i).controls.drift.updateValueAndValidity();
          this.quantitaVendutaForm(i).controls.scarto.updateValueAndValidity();

          this.prezzoInizialeForm(i).controls.ribasso.updateValueAndValidity();
          this.prezzoInizialeForm(i).controls.rialzo.updateValueAndValidity();
          this.prezzoInizialeForm(i).controls.drift.updateValueAndValidity();
          this.prezzoInizialeForm(i).controls.scarto.updateValueAndValidity();

          this.costoInizialeForm(i).controls.ribasso.updateValueAndValidity();
          this.costoInizialeForm(i).controls.rialzo.updateValueAndValidity();
          this.costoInizialeForm(i).controls.drift.updateValueAndValidity();
          this.costoInizialeForm(i).controls.scarto.updateValueAndValidity();

          this.lineeBusinessFormArray[i].updateValueAndValidity();
        }
        this.f.lineeBusiness.updateValueAndValidity();
      } else {
      }
      /* console.log(this.idLineeBusinessTempArray)
      console.log(this.lineeBusinessArray) */
    })

  }


  get f() { return this.lineeBusinessForm.controls; }
  get lineeBusinessArray(): UntypedFormArray { return <UntypedFormArray> this.f.lineeBusiness; }
  get lineeBusinessFormArray(): UntypedFormGroup[] { return <UntypedFormGroup[]> this.lineeBusinessArray.controls; };
  /* casting valido solo per il tutti i sottoform form. la funzione ritorna il control come un formGroup*/
  quantitaVendutaForm(i: number): UntypedFormGroup { return <UntypedFormGroup> this.lineeBusinessFormArray[i].controls.quantitaVenduta; }
  prezzoInizialeForm(i: number): UntypedFormGroup { return <UntypedFormGroup> this.lineeBusinessFormArray[i].controls.prezzoIniziale; }
  costoInizialeForm(i: number): UntypedFormGroup { return <UntypedFormGroup> this.lineeBusinessFormArray[i].controls.costoIniziale; }


  addLineaBusiness() {
    if (this.lineeBusinessArray.length <= 10) {
      this.lineeBusinessForm.markAsDirty();
      this.lineeBusinessFormArray.push(this.formBuilder.group({
        nomeLineaBusiness: ['', Validators.required],
        tipologiaAtt: ['', this.agricoltura ? Validators.required : null],
        categoriaProd: ['', this.agricoltura ? Validators.required : null],
        sitoProduttivo: ['', this.agricoltura ? Validators.required : null],
        evoluzioneLineaDiBusiness: [true, Validators.required],/* MODIFICATO DA ANDREA.C 26/09/22 */
        presenzaMagazzino: [this.agricoltura ? false : '', Validators.required],
        quantitaVenduta: this.formBuilder.group({
          ribasso: [0, [Validators.required, Validators.max(this.valoreMax.ribasso)]],/* Validators.required */
          rialzo: [0, [Validators.required, Validators.max(this.valoreMax.rialzo)]],/* Validators.required */
          scarto: [1, [Validators.min(0.1), Validators.required, Validators.max(this.valoreMax.scarto)]],/* [Validators.min(0.1),Validators.required] */
          drift: [0, [Validators.required, Validators.max(this.valoreMax.drift)]],/* Validators.required */
        }),
        prezzoIniziale: this.formBuilder.group({
          ribasso: [0, [Validators.required, Validators.max(this.valoreMax.ribasso)]],/* Validators.required */
          rialzo: [0, [Validators.required, Validators.max(this.valoreMax.rialzo)]],/* Validators.required */
          scarto: [1, [Validators.min(0.1), Validators.required, Validators.max(this.valoreMax.scarto)]],/* [Validators.min(0.1),Validators.required] */
          drift: [0, [Validators.required, Validators.max(this.valoreMax.drift)]],/* Validators.required */
        }),
        costoIniziale: this.formBuilder.group({
          ribasso: [0, [Validators.required, Validators.max(this.valoreMax.ribasso)]],/* Validators.required */
          rialzo: [0, [Validators.required, Validators.max(this.valoreMax.rialzo)]],/* Validators.required */
          scarto: [1, [Validators.min(0.1), Validators.required, Validators.max(this.valoreMax.scarto)]],/* [Validators.min(0.1),Validators.required] */
          drift: [0, [Validators.required, Validators.max(this.valoreMax.drift)]],/* Validators.required */
        }),
      }));
      /*se aggiungo un form aggiungo un id= 0 all'array degli id delle linee di business */
      this.idLineeBusinessTempArray.push(0);
      /* update value and validity dei campi dell'ultimo form pushato */
      this.lineeBusinessFormArray[this.lineeBusinessFormArray.length - 1].controls.nomeLineaBusiness.updateValueAndValidity();
      this.lineeBusinessFormArray[this.lineeBusinessFormArray.length - 1].controls.presenzaMagazzino.updateValueAndValidity();
      this.quantitaVendutaForm(this.lineeBusinessFormArray.length - 1).controls.ribasso.updateValueAndValidity();
      this.quantitaVendutaForm(this.lineeBusinessFormArray.length - 1).controls.rialzo.updateValueAndValidity();
      this.quantitaVendutaForm(this.lineeBusinessFormArray.length - 1).controls.drift.updateValueAndValidity();
      this.quantitaVendutaForm(this.lineeBusinessFormArray.length - 1).controls.scarto.updateValueAndValidity();

      this.prezzoInizialeForm(this.lineeBusinessFormArray.length - 1).controls.ribasso.updateValueAndValidity();
      this.prezzoInizialeForm(this.lineeBusinessFormArray.length - 1).controls.rialzo.updateValueAndValidity();
      this.prezzoInizialeForm(this.lineeBusinessFormArray.length - 1).controls.drift.updateValueAndValidity();
      this.prezzoInizialeForm(this.lineeBusinessFormArray.length - 1).controls.scarto.updateValueAndValidity();

      this.costoInizialeForm(this.lineeBusinessFormArray.length - 1).controls.ribasso.updateValueAndValidity();
      this.costoInizialeForm(this.lineeBusinessFormArray.length - 1).controls.rialzo.updateValueAndValidity();
      this.costoInizialeForm(this.lineeBusinessFormArray.length - 1).controls.drift.updateValueAndValidity();
      this.costoInizialeForm(this.lineeBusinessFormArray.length - 1).controls.scarto.updateValueAndValidity();

      this.lineeBusinessArray.updateValueAndValidity();
    } else {
      this.addLineaDisabled = true;
    }
    /*     console.log(this.idLineeBusinessTempArray)
        console.log(this.lineeBusinessArray) */
  }

  onChangeTipologiaAtt(index: number) {
    if (this.lineeBusinessFormArray[index].controls.tipologiaAtt.value) {
      if (this.lineeBusinessFormArray[index].controls.tipologiaAtt.value.id == 1) {
        this.lineeBusinessFormArray[index].controls.categoriaProd.setValidators(Validators.required)
        this.lineeBusinessFormArray[index].controls.sitoProduttivo.setValidators(Validators.required)
      } else {
        this.lineeBusinessFormArray[index].controls.categoriaProd.clearValidators()
        this.lineeBusinessFormArray[index].controls.sitoProduttivo.clearValidators()
      }
      this.lineeBusinessFormArray[index].controls.categoriaProd.updateValueAndValidity()
      this.lineeBusinessFormArray[index].controls.sitoProduttivo.updateValueAndValidity()
    }
  }

  EliminaLineaDiBusiness(index: number) {
    /* inserisce data fine validità della linea */
    if (this.idLineeBusinessTempArray[index] != 0) {
      if (this.lineeBusinessArray.length > 1) {
        this.lineeBusinessForm.markAsDirty();
        if (this.modalService.hasOpenModals()) {
          this.modalService.dismissAll();
        }
        const modalRef = this.modalService.open(ModalcontentComponent, {
          backdrop: 'static',
          backdropClass: 'customBackdrop',
        });
        modalRef.componentInstance.modalPar = {
          name: "elimina",
          title: "Eliminare la linea di Business " + this.lineeBusinessFormArray[index].controls.nomeLineaBusiness.value + " ?",
          description: "NOTA BENE: L'eliminazione della linea di business cancellerà anche tutti i PRODOTTI relativi a quest'ultima e sarà necessario ricompilare i Business Plan associati.",
          undoButtonText: "Annulla",
          actionButtonText: "Elimina",
        }
        modalRef.result.then((r) => {
          if (r) {
            this.configurazioneAmbienteService.deleteLineabusiness(this.idLineeBusinessTempArray[index], this.tokeStorageService.getUser().idUtente, this.idRichiedenteTemp, this.idLineeBusinessTempArray.length).subscribe(response => {
              this.idLineeBusinessTempArray.splice(index, 1);
              this.lineeBusinessArray.removeAt(index);
              const modalRef = this.modalService.open(ModalcontentComponent, {
                backdrop: 'static',
                backdropClass: 'customBackdrop',
              });
              modalRef.componentInstance.modalPar = {
                name: "messaggioelimina",
                description: response ? "Linea di Business eliminata con successo" : "Errore",
                actionButtonText: "",
                undoButtonText: "Ok",
              }
              modalRef.result.then((r) => {
                if (!r) {
                  this.onLoadLineeBusiness();
                  this.configurazioneAmbienteService.lineeBpChanged(false)
                }
              })
            })
          }


        })
      } else {
        const modalRef = this.modalService.open(ModalcontentComponent, {
          backdrop: 'static',
          backdropClass: 'customBackdrop',
        });
        modalRef.componentInstance.modalPar = {
          name: "messaggio",
          description: "Mantenere almeno una linea di business.",
          actionButtonText: "",
          undoButtonText: "Ok",
        }
        modalRef.result.then((r) => {
          if (!r) {

          }
        })
      }
    } else {
      /* eliminazione righe senza dati da db */
      this.idLineeBusinessTempArray.splice(index, 1);
      this.lineeBusinessArray.removeAt(index);

    }

  }
  isFormDirty() {
    this.lineeBusinessArray.updateValueAndValidity();
    for (let i = 0; i < this.lineeBusinessFormArray.length; i++) {
      if (this.lineeBusinessFormArray[i].dirty) {
        this.lineeBusinessForm.markAsDirty();
      }
    }
    this.submitted = this.lineeBusinessForm.dirty
    return this.submitted
  }

  annulla() {
    if (this.isFormDirty()) {
      if (this.modalService.hasOpenModals()) {
        this.modalService.dismissAll();
      }
      const modalRef = this.modalService.open(ModalcontentComponent, {
        backdrop: 'static',
        backdropClass: 'customBackdrop',
      });
      modalRef.componentInstance.modalPar = {
        name: "annulla",
        title: "Sicuro di voler annullare ?",
        description: "Così facendo, tutte le modifiche non salvate in Linee Business andranno persi.",
        undoButtonText: "Indietro",
        actionButtonText: "Annulla",
      }
      modalRef.result.then((r) => {
        if (r) {
          this.lineeBusinessArray.clear();
          this.idLineeBusinessTempArray = [];
          this.onLoadLineeBusiness();
          this.submitted = false;
          this.addLineaDisabled = false;
          this.lineeBusinessForm.markAsPristine();

        }
      })
    }
  }

  /*onchange*/
  onChangeLinee(index: number) {

    if (this.lineeBusinessFormArray[index].controls['evoluzioneLineaDiBusiness'].value.id == 2) {
      if (this.lineeBusinessFormArray[index].controls.drift != null)
        this.lineeBusinessFormArray[index].controls.drift.setValidators([Validators.required, Validators.max(this.valoreMax.drift)]);
    } else {
      if (this.lineeBusinessFormArray[index].controls.drift != null)
        this.lineeBusinessFormArray[index].controls.drift.clearValidators();
    }
    if (this.lineeBusinessFormArray[index].controls.drift != null)
      this.lineeBusinessFormArray[index].controls.drift.updateValueAndValidity();
  }


  /* salva */
  onSubmit() {
    if (this.isFormDirty()) {
      this.lineeBusinessForm.updateValueAndValidity();
      this.lineeBusinessArray.updateValueAndValidity();
      if (this.lineeBusinessForm.valid) {
        /* modale salva */
        const modalRef = this.modalService.open(ModalcontentComponent);
        modalRef.componentInstance.modalPar = {
          name: "salvamodifiche",
          description: "Salvare le linee di business inserite?",
          actionButtonText: "Salva",
          undoButtonText: "Indietro",
        }
        modalRef.result.then((r) => {
          if (r) {
            let lineeBusiness: LineaBusiness[] = [];
            for (let i = 0; i < this.lineeBusinessFormArray.length; i++) {
              const lineaBusiness: LineaBusiness = {
                id: this.idLineeBusinessTempArray[i],
                nomeLineaBusiness: this.lineeBusinessFormArray[i].controls.nomeLineaBusiness.value,
                idTipologiaAtt: this.lineeBusinessFormArray[i].controls.tipologiaAtt.value ?
                  this.lineeBusinessFormArray[i].controls.tipologiaAtt.value.id : 0,
                idCategoriaProd: this.lineeBusinessFormArray[i].controls.categoriaProd.value ?
                  this.lineeBusinessFormArray[i].controls.categoriaProd.value.id : 0,
                idRegione: this.lineeBusinessFormArray[i].controls.sitoProduttivo.value ?
                  this.lineeBusinessFormArray[i].controls.sitoProduttivo.value.id : 0,
                costoIniziale: {
                  ribasso: this.costoInizialeForm(i).controls.ribasso.value,
                  rialzo: this.costoInizialeForm(i).controls.rialzo.value,
                  scarto: this.costoInizialeForm(i).controls.scarto.value, /* non possiamo inizializzare lo scarto a 0 */
                  drift: this.costoInizialeForm(i).controls.drift.value,
                },
                prezzoIniziale: {
                  ribasso: this.prezzoInizialeForm(i).controls.ribasso.value,
                  rialzo: this.prezzoInizialeForm(i).controls.rialzo.value,
                  scarto: this.prezzoInizialeForm(i).controls.scarto.value,
                  drift: this.prezzoInizialeForm(i).controls.drift.value,
                },
                quantitaVenduta: {
                  ribasso: this.quantitaVendutaForm(i).controls.ribasso.value,
                  rialzo: this.quantitaVendutaForm(i).controls.rialzo.value,
                  scarto: this.quantitaVendutaForm(i).controls.scarto.value,
                  drift: this.quantitaVendutaForm(i).controls.drift.value,
                },
                presenzaMagazzino: this.lineeBusinessFormArray[i].controls.presenzaMagazzino.value,
                evoluzioneLineaDiBusiness: this.lineeBusinessFormArray[i].controls.evoluzioneLineaDiBusiness.value
              }
              lineeBusiness.push(lineaBusiness)
            }

            let request = {
              idRichiedente: this.idRichiedenteTemp,
              idUtente: this.tokeStorageService.getUser().idUtente,
              lineeBusiness: lineeBusiness,
            }
            this.configurazioneAmbienteService.saveLineeBusiness(request).subscribe(
              response => {
                if (response) {
                  const modalRef = this.modalService.open(ModalcontentComponent);
                  modalRef.componentInstance.modalPar = {
                    name: "messaggiosalva",
                    description: "Linee Business salvate con successo",
                    actionButtonText: "",
                    undoButtonText: "Ok",
                  }
                  modalRef.result.then(r => {
                    if (!r) {
                      //this.configurazioneAmbienteService.Loadsubmitted(false)
                      this.onLoadLineeBusiness();
                      this.saved = true;
                      this.submitted = false;
                      this.configurazioneAmbienteService.lineeBpChanged(true)
                      /* condizione bool per andare alla prossima scheda */
                    }
                  })
                } else {
                  const modalRef = this.modalService.open(ModalcontentComponent);
                  modalRef.componentInstance.modalPar = {
                    name: "messaggiosalva",
                    description: "Errore",
                    actionButtonText: "",
                    undoButtonText: "Ok",
                  }
                  modalRef.result.then(r => {
                  })
                }



              }
            );
          } else {
            this.submitted = false;
          }
        })

      }
    }
  }
}
