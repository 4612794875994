<div class="container-fluid" id="creditizia">
  <!-- (selectedTabChange)="resetPaddingBodyNoSticky($event)" -->
  <div class="row">
    <div class="col-8">
      <h1>Certificazione situazione creditizia</h1>
    </div>
    <div class="col-4 d-flex align-items-center justify-content-end margin-top-10 labelCampiInputMesiDeb">
      <!--<button *ngIf="businessPlanTemp!=null && (businessPlanTemp.idStato==1 ||businessPlanTemp.idStato==2)"
                class="btn btn-success" (click)="onSubmitCrediti()">Salva</button>-->
      <button *ngIf="businessPlanTemp!=null && (businessPlanTemp.idStato==1 ||businessPlanTemp.idStato==2)"
        class="btn btn-secondary" style="margin-left: 20px" type="button" (click)="annullaCrediti()">
        Annulla modifiche
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-12 d-flex align-items-end justify-content-start labelCampiInputMesiDeb">
      <h2 style="font-weight:bold ;">Totale crediti al {{'31/12/' + anniBilancio}} :
        {{totaleCrediti | number:
        '1.0-0':'it'}} €
      </h2>
    </div>
    <div class="col-12 align-items-center " *ngIf="!businessPlanTemp.bilancioAbbreviato;else bilAbbreviato">
      <h2>Ripartizione crediti da bilancio:</h2>
    </div>
    <ng-template #bilAbbreviato>
      <div class="col-4 col-xl-3 align-items-center ">
        <h2>Selezionare le voci di credito&nbsp;<i id="icon" class="fa fa-1x fa-info-circle" aria-hidden="true"
            placement="top" container="body" ngbTooltip="??"></i>&nbsp;:
        </h2>
      </div>
      <div class="col-1"><button type="button" (click)="aggiornaListaCheckBox()" class="btn btn-primary dropdown-toggle"
          [disabled]="this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4">
          Modifica <span class="caret"></span>
        </button></div>
      <div *ngIf="openCheckBoxComponent==true" class="col-12"><app-check-box-list [listaCheckBox]="listaTipoCreditoBean"
          (listaCheckBoxModificata)="ModificaListaCrediti($event)"></app-check-box-list></div>

      <!--  <div class="col-1"><button class="btn btn-primary" (click)="ModificaListaDebiti()">Modifica</button>
                    </div> -->
    </ng-template>
    <hr>

    <div [formGroup]="creditiformGroup">
      <div formArrayName="arrayCreditizia" class="nopadding" id="container-situazione-finaziaria-body">
        <div class="row">
          <div class="col-12 erroreCrediti" *ngIf=" submittedCrediti && erroreSommaTotale==true">
            La somma delle voci di credito è diversa dal totale riportato sul bilancio.
          </div>
        </div>
        <div *ngFor="let credito of creditiFormArray;let i = index" [formGroupName]="i">
          <div class="row d-flex justify-content-between">
            <div class="col-12">
              <h2> {{nomeCredito[i]}}</h2>
            </div>
          </div>
          <div class="row margin-top-10 ">
            <div class="col-2">
              <label class=" nopadding">Totale</label>
              <input type="text" class="form-control" formControlName="totale" currencyMask
                [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                [ngClass]="{ 'is-invalid':submittedCrediti && (credito.controls.totale.errors || erroreSommaParziale[i]==true ||erroreSommaTotale ==true )}">
            </div>
          </div>
          <div class="col-12 erroreCrediti" *ngIf=" submittedCrediti && erroreSommaParziale[i]==true">
            La somma della suddivisione deve essere uguale al totale di
            {{nomeCredito[i]}}
          </div>
          <hr>
          <!-- crediti -->
          <div formArrayName="suddivisioneCrediti">
            <div *ngFor="let suddivisione of credito.controls.suddivisioneCrediti.controls;let j=index"
              [formGroupName]="j">
              <div class="row  margin-top-10 d-flex justify-content-start align-items-end">
                <div class="col-2">
                  <label>Descrizione</label>
                  <input type="text" class="form-control" formControlName="descrizione"
                    [ngClass]="{ 'is-invalid':submittedCrediti && suddivisione.controls.descrizione.errors}">
                </div>
                <div class="col-1">
                  <label>Importo</label>
                  <input type="text" class="form-control" formControlName="importo" currencyMask
                    [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                    [ngClass]="{ 'is-invalid':submittedCrediti && suddivisione.controls.importo.errorssubmittedCrediti || submittedCrediti && erroreSommaParziale[i] ==true}"
                    (keyup)="setValoreRinegoziatoCredito(i,j)">
                </div>
                <div class="col-2" *ngIf="this.tipoCreditoList[i] != 4">
                  <label>Importo Rinegoziato</label>
                  <input type="text" class="form-control" formControlName="importoRinegoziato" currencyMask
                    [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                    [ngClass]="{ 'is-invalid':submittedCrediti && suddivisione.controls.importoRinegoziato.errors}"
                    (keyup)="valoreRinegoziatoMax(i,j)">
                </div>
                <div class="col-1" *ngIf="this.tipoCreditoList[i] != 4">
                  <label>Anno</label>
                  <input type="number" class="form-control" formControlName="annoInizioRimborso"
                  (change)="onChangeAnno(i,j)"
                  [ngClass]="{ 'is-invalid':submittedCrediti && suddivisione.controls.annoInizioRimborso.errors}">
                </div>
                <div class="col-2">
                  <label>Mese inizio incasso</label>
                  <select class="form-select form-control" formControlName="meseInizioRimborso"
                    [ngClass]="{ 'is-invalid':submittedCrediti && suddivisione.controls.meseInizioRimborso.errors}">
                    <option [ngValue]="k" *ngFor="let mese of listaMesi;let k=index">{{mese |date:'MMMM' : 'it'}}
                    </option>
                  </select>
                </div>
                <div class="col-1">
                  <label>Rate residue</label>
                  <select class="form-select form-control" formControlName="numeroRate"
                    [ngClass]="{ 'is-invalid':submittedCrediti && suddivisione.controls.numeroRate.errors}">
                    <option [ngValue]="numero" *ngFor="let numero of listaNumeroRate">{{numero}}</option>
                  </select>
                </div>
                <div class="col-2">
                  <label>Tipologia rata</label>
                  <select class="form-select form-control" formControlName="tipologiaRata"
                    [ngClass]="{ 'is-invalid':submittedCrediti && suddivisione.controls.tipologiaRata.errors}">
                    <option [ngValue]="tipologiaRata" *ngFor="let tipologiaRata of tipoRateList">
                      {{tipologiaRata.descrizione}}</option>
                  </select>
                </div>

                <div class="col-1 d-flex justify-content-center align-items-center" *ngIf="j>0" style="margin-bottom: 0.2em;">
                  <fa-icon class="pointer" style="font-size: 20px" [icon]="faTrashAlt" (click)="eliminaCredito(i,j)">
                  </fa-icon>
                </div>
              </div>

            </div>
            <div class="row  margin-top-20">
              <div class="col-12 d-flex justify-content-end">
                <button class="btn btn-primary mr-1" type="button" (click)="aggiungiCredito(i)">Aggiungi</button>
              </div>
            </div>
          </div>



          <hr>
        </div>

      </div>
    </div>


  </div>
</div>