import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MenuPulsantieraService } from 'src/app/header/services/menu-pulsantiera.service';
import { ConfigurazioneBusinessPlanService } from 'src/app/_services/configurazione-business-plan.service';
import { DatiQuestionarioService } from 'src/app/_services/dati-questionario.service';
import { indiciSortino, SplashpageServiceService } from 'src/app/_services/splashpage-service.service';
import { DurataBP } from '../interface/durata-bp';
import { indicAsIs } from '../interface/indici-as-is';
import { SplashpageService } from '../services/splashpage.service';
import { TabelleSecondarieService } from '../services/tabelle-secondarie.service';


@Component({
  selector: 'app-sintesi-as-is',
  templateUrl: './sintesi-as-is.component.html',
  styleUrls: ['./sintesi-as-is.component.css']
})
export class SintesiAsIsComponent implements OnInit, OnDestroy {

  idBplan: number = 0;

  isPersistent: boolean = false;

  ELEMENT_INDICI_AS_IS: indicAsIs[];

  ELEMENT_INDICI_AS_IS_RIDOTTO: indicAsIs[];

  ELEMENT_ANNO_AS_IS_CONTO_ECONOMICO: indicAsIs[];

  ELEMENT_ANNO_AS_IS_CASH_FLOW: indicAsIs[];

  ELEMENT_ANNO_AS_IS_CASH_FLOW_RIDOTTO: indicAsIs[];

  ELEMENT_INDICI_STATO_PATRIMONIALE: indicAsIs[];



  displayedColumns: string[] = ['INDICI', 'VALORE'];
  dataSourceIndici;
  dataSourceIndiciRidotto;
  dataSourceContoEconomico;
  dataSourceCashFlow;
  dataSourceCashFlowRidotto;
  dataSourceStstoPatrimoniale;

  annoInizioBilancio: number = 0;

  durataBP: DurataBP = {
    annoStart: 0,
    durataAnni: 0
  };

  @Input() is_as: boolean = false;

  constructor(private splash_service: SplashpageServiceService, private datiQuestionarioService: DatiQuestionarioService, private splashDataVolatile: SplashpageServiceService, private splashpageService: SplashpageService,
    private tabelleSecondarieService: TabelleSecondarieService, private menuPulsantieraService: MenuPulsantieraService, private serviceBp: ConfigurazioneBusinessPlanService) { }

  subscription_sortinoDataTable: Subscription;
  risultatiVolatileSubscription: Subscription;
  idBpSubscription: Subscription;
  asisSubscription: Subscription;
  durataBPSubscription: Subscription;
  annoBilancioSubscription: Subscription;

  van_percentuale: number = 0
  cf_equity_percentuale: number = 0

  ngOnInit(): void {

    //this.serviceBp._annoBilancio$.subscribe(annoBilancio => {
    //this.annoInizioBilancio = annoBilancio;
    //})
    /*
        this.datiQuestionarioService.checkFormTouched.subscribe(isVolatile => {
    
          this.isPersistent = !isVolatile;
    
        })*/

    this.splash_service.postGetVanECashFLowEquity().subscribe(
      vanECashFLowEquity => {
        this.van_percentuale = vanECashFLowEquity.van * 100;
        this.cf_equity_percentuale = vanECashFLowEquity.cfEquity * 100;
        ;
      }
    );


    this.idBpSubscription = this.splash_service.BpId$.subscribe(id => {
      if (id != 0) {
        this.idBplan = id;
        this.splash_service.postGetAnnoBilancio(this.idBplan).subscribe(startInizioBilancio => {
          if (startInizioBilancio != null) {
            this.annoInizioBilancio = startInizioBilancio;
          }
        });
      }
    });

    this.risultatiVolatileSubscription = this.serviceBp.currentRisultatiVolatile.subscribe(isVolatile => {
      this.isPersistent = !isVolatile;
    })

    if (this.isPersistent) {
      if (this.idBplan != 0) {
        this.splash_service.postDurataBP(this.idBplan).subscribe(durata => {
          this.durataBP = durata;
        });

        this.splashpageService.postAsIsDataTable(this.idBplan).subscribe(responsValueIsAsTable => {
          let valueIsAsTable_str = JSON.stringify(responsValueIsAsTable);
          let valueIsAsTable_json = JSON.parse(valueIsAsTable_str);

          this.ELEMENT_INDICI_AS_IS = [
            { indice: "DSCR", value: valueIsAsTable_json.indici_anno_corrente[0].value, valueString: valueIsAsTable_json.indici_anno_corrente[0].valueString },
            { indice: "MCC Mod. e.f.", value: valueIsAsTable_json.indici_anno_corrente[1].value, valueString: valueIsAsTable_json.indici_anno_corrente[1].valueString },
            { indice: "PATRIMONIO NETTO/ATTIVO", value: valueIsAsTable_json.indici_anno_corrente[2].value, valueString: valueIsAsTable_json.indici_anno_corrente[2].valueString },
            { indice: "IRR EQUITY", value: valueIsAsTable_json.indici_anno_corrente[3].value, valueString: valueIsAsTable_json.indici_anno_corrente[3].valueString },
            { indice: "PFN/EBITDA", value: valueIsAsTable_json.indici_anno_corrente[4].value, valueString: valueIsAsTable_json.indici_anno_corrente[4].valueString },
          ];

          this.ELEMENT_INDICI_AS_IS_RIDOTTO = [
            { indice: "DSCR", value: valueIsAsTable_json.indici_anno_corrente[0].value, valueString: valueIsAsTable_json.indici_anno_corrente[0].valueString },
            { indice: "MCC Mod. e.f.", value: valueIsAsTable_json.indici_anno_corrente[1].value, valueString: valueIsAsTable_json.indici_anno_corrente[1].valueString },
            { indice: "PATRIMONIO NETTO/ATTIVO", value: valueIsAsTable_json.indici_anno_corrente[2].value, valueString: valueIsAsTable_json.indici_anno_corrente[2].valueString }
          ];

          this.ELEMENT_ANNO_AS_IS_CONTO_ECONOMICO = [
            { indice: "FATTURATO", value: valueIsAsTable_json.conto_economico[0].value },
            { indice: "EBITDA", value: valueIsAsTable_json.conto_economico[1].value },
            { indice: "EBIT", value: valueIsAsTable_json.conto_economico[2].value },
            { indice: "NET PROFIT", value: valueIsAsTable_json.conto_economico[3].value }
          ];

          this.ELEMENT_ANNO_AS_IS_CASH_FLOW = [
            { indice: "CASHFLOW", value: valueIsAsTable_json.cash_flow[0].value },
            { indice: "DISPONIBILIT\u00c0 LIQUIDA", value: valueIsAsTable_json.cash_flow[1].value },
           // { indice: "CF equity attualizzato " + this.cf_equity_percentuale + "%", value: valueIsAsTable_json.cash_flow[2].value },
           // { indice: "VAN al " + this.van_percentuale + "%", value: valueIsAsTable_json.cash_flow[3].value }
          ];

          this.ELEMENT_ANNO_AS_IS_CASH_FLOW_RIDOTTO = [
            { indice: "CASHFLOW", value: valueIsAsTable_json.cash_flow[0].value },
            { indice: "DISPONIBILIT\u00c0 LIQUIDA", value: valueIsAsTable_json.cash_flow[1].value }
          ];

          this.ELEMENT_INDICI_STATO_PATRIMONIALE = [
            { indice: "ATTIVO IMMOBILIZZATO", value: valueIsAsTable_json.stato_patrimoniale[0].value },
            { indice: "CAPITALE CIRCOLANTE", value: valueIsAsTable_json.stato_patrimoniale[1].value },
            { indice: "CREDITI", value: valueIsAsTable_json.stato_patrimoniale[2].value },
            { indice: "ALTRI DEBITI", value: valueIsAsTable_json.stato_patrimoniale[3].value },
            { indice: "DEBITI BANCARI", value: valueIsAsTable_json.stato_patrimoniale[4].value },
            { indice: "PATRIMONIO NETTO", value: valueIsAsTable_json.stato_patrimoniale[5].value },
          ];

          this.dataSourceIndici = this.ELEMENT_INDICI_AS_IS;
          this.dataSourceIndiciRidotto = this.ELEMENT_INDICI_AS_IS_RIDOTTO;
          this.dataSourceContoEconomico = this.ELEMENT_ANNO_AS_IS_CONTO_ECONOMICO;
          this.dataSourceCashFlow = this.ELEMENT_ANNO_AS_IS_CASH_FLOW;
          this.dataSourceCashFlowRidotto = this.ELEMENT_ANNO_AS_IS_CASH_FLOW_RIDOTTO;
          this.dataSourceStstoPatrimoniale = this.ELEMENT_INDICI_STATO_PATRIMONIALE;

        })
      }
    } else {

      this.asisSubscription = this.splashDataVolatile.asIs$.subscribe(dataAsIs => {
        if (dataAsIs != null) {
          this.ELEMENT_INDICI_AS_IS = [
            { indice: dataAsIs[0].indice, value: dataAsIs[0].value, valueString: dataAsIs[0].valueString },
            { indice: dataAsIs[1].indice, value: dataAsIs[1].value, valueString: dataAsIs[1].valueString },
            { indice: dataAsIs[2].indice, value: dataAsIs[2].value, valueString: dataAsIs[2].valueString },
            { indice: dataAsIs[3].indice, value: dataAsIs[3].value, valueString: dataAsIs[3].valueString },
            { indice: dataAsIs[4].indice, value: dataAsIs[4].value, valueString: dataAsIs[4].valueString }
          ];

          this.ELEMENT_INDICI_AS_IS_RIDOTTO = [
            { indice: dataAsIs[0].indice, value: dataAsIs[0].value, valueString: dataAsIs[0].valueString },
            { indice: dataAsIs[1].indice, value: dataAsIs[1].value, valueString: dataAsIs[1].valueString },
            { indice: dataAsIs[2].indice, value: dataAsIs[2].value, valueString: dataAsIs[2].valueString }
          ];

          this.ELEMENT_ANNO_AS_IS_CONTO_ECONOMICO = [
            { indice: dataAsIs[5].indice, value: dataAsIs[5].value },
            { indice: dataAsIs[6].indice, value: dataAsIs[6].value },
            { indice: dataAsIs[7].indice, value: dataAsIs[7].value },
            { indice: dataAsIs[8].indice, value: dataAsIs[8].value }
          ];

          this.ELEMENT_ANNO_AS_IS_CASH_FLOW = [
            { indice: dataAsIs[9].indice, value: dataAsIs[9].value },
            { indice: dataAsIs[10].indice, value: dataAsIs[10].value },
           // { indice: dataAsIs[11].indice, value: dataAsIs[11].value },
           // { indice: dataAsIs[12].indice, value: dataAsIs[12].value }
          ];

          this.ELEMENT_ANNO_AS_IS_CASH_FLOW_RIDOTTO = [
            { indice: dataAsIs[9].indice, value: dataAsIs[9].value },
            { indice: dataAsIs[10].indice, value: dataAsIs[10].value },
          ];

          this.ELEMENT_INDICI_STATO_PATRIMONIALE = [
            { indice: dataAsIs[13].indice, value: dataAsIs[13].value },
            { indice: dataAsIs[14].indice, value: dataAsIs[14].value },
            { indice: dataAsIs[15].indice, value: dataAsIs[15].value },
            { indice: dataAsIs[16].indice, value: dataAsIs[16].value },
            { indice: dataAsIs[17].indice, value: dataAsIs[17].value },
            { indice: dataAsIs[18].indice, value: dataAsIs[18].value }
          ];



          this.dataSourceIndici = this.ELEMENT_INDICI_AS_IS;
          this.dataSourceIndiciRidotto = this.ELEMENT_INDICI_AS_IS_RIDOTTO;
          this.dataSourceContoEconomico = this.ELEMENT_ANNO_AS_IS_CONTO_ECONOMICO;
          this.dataSourceCashFlow = this.ELEMENT_ANNO_AS_IS_CASH_FLOW;
          this.dataSourceCashFlowRidotto = this.ELEMENT_ANNO_AS_IS_CASH_FLOW_RIDOTTO;
          this.dataSourceStstoPatrimoniale = this.ELEMENT_INDICI_STATO_PATRIMONIALE;

        }
      })



      this.durataBPSubscription = this.splashDataVolatile.durataBP$.subscribe(durata => {
        if (durata != null) this.durataBP = durata;
      })

      this.annoBilancioSubscription = this.serviceBp._annoBilancio$.subscribe(anno => {
        if (anno != 0) this.annoInizioBilancio = anno;
      })
    }
  }


  ngOnDestroy() {
    //this.subscription_sortinoDataTable.unsubscribe();
    if (!this.isPersistent) {
      this.annoBilancioSubscription.unsubscribe()
      this.durataBPSubscription.unsubscribe()
      this.asisSubscription.unsubscribe()
    }

    this.risultatiVolatileSubscription.unsubscribe();
    this.idBpSubscription.unsubscribe()
  }

}
