<div class="container-fluid">
    <ng-container *ngIf="richiedenteTemp1==undefined">
        <div class="row nopadding">
            <div class="col-8 nopadding">
                <h2>Gestione linee di business</h2>
            </div>
        </div>
        <div class="row nopadding">
            <div class="col-12 nopadding d-flex align-items-center margin-top-10">
                <h3> Inserisci l'azienda di cui vuoi configurare l'ambiente.</h3>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="richiedenteTemp1!=undefined">
        <div class="row d-flex justify-content-between" id="saveLineeBusinessAmbiente">
            <div class="col-9 nopadding">
                <h2 class="margin-top-10">Gestione linee di business: </h2>
                <p>Clicca sul "<strong>+</strong>" per inserire nuove linee di business per la tua attività. È
                    necessario
                    inserire almeno una linea di Business per il calcolo del Business Plan.</p>
            </div>

            <div class="col-3 text-center d-flex align-items-center justify-content-end">
                <button class=" d-flex justify-content-center btn fa fa-plus fa-lg pointer" aria-hidden="true"
                    type="button" (click)="addLineaBusiness()" placement="top" container="body"
                    ngbTooltip="Aggiungi Linea di Business. Sono disponibili fino 10 linee di business per azienda."
                    [disabled]="addLineaDisabled">
                </button>
                <button class="btn btn-success" type="submit" (click)="onSubmit()" [disabled]="disabledSalva"
                    style="margin-left: 20px">Salva</button>
                <button class="btn btn-secondary" style="margin-left: 20px" type="button" (click)="annulla()">
                    Annulla
                </button>
            </div>
        </div>
        <hr>
        <div id="container-linee-bp-configurazione-ambiente">
            <div class="row" [formGroup]=" lineeBusinessForm">
                <div formArrayName="lineeBusiness">

                    <div *ngFor="let lineaBusiness of lineeBusinessFormArray;let i = index" [formGroupName]="i"
                        class=" border-top">
                        <h2>Linea di Business {{i+1}}</h2>
                        <div class="row" style="position: relative;">
                            <div class="col-2 d-flex align-items-center margin-bottom-10 margin-top-10">
                                <div>
                                    <label>Linea di Business</label>
                                    <input type="text" formControlName="nomeLineaBusiness" class="form-control"
                                        style="max-width: 100%;"
                                        [ngClass]="{ 'is-invalid':submitted && lineaBusiness.controls.nomeLineaBusiness.errors }" />
                                    <div *ngIf=" submitted && lineaBusiness.controls.nomeLineaBusiness.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="lineaBusiness.controls.nomeLineaBusiness.errors.required">Campo
                                            obbligatorio
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="agricoltura">
                                <div class="col-3 align-items-center margin-bottom-10 margin-top-10">
                                    <label>Tipologia attività</label>
                                    <select formControlName="tipologiaAtt" class="form-control form-select" [ngClass]="{'is-invalid': submitted &&
                                        lineaBusiness.controls.tipologiaAtt.errors}"
                                        (change)="onChangeTipologiaAtt(i)">
                                        <option [ngValue]="tipologiaAtt" *ngFor="let tipologiaAtt of tipologieAttivita">
                                            {{tipologiaAtt.descrizione}}
                                        </option>
                                    </select>
                                    <div *ngIf=" submitted && lineaBusiness.controls.tipologiaAtt.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="lineaBusiness.controls.tipologiaAtt.errors.required">Campo
                                            obbligatorio
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="this.lineeBusinessFormArray[i].controls.tipologiaAtt.value!=null && 
                                this.lineeBusinessFormArray[i].controls.tipologiaAtt.value.id==1"
                                    class="col-3 align-items-center margin-bottom-10 margin-top-10">
                                    <label>Categoria produzione</label>
                                    <select class="form-control form-select" formControlName="categoriaProd"
                                        [ngClass]="{'is-invalid': submitted && lineaBusiness.controls.categoriaProd.errors}">
                                        <option [ngValue]="categoriaProd" *ngFor="let categoriaProd of categorieProd">
                                            {{categoriaProd.descrizione}}
                                        </option>
                                    </select>
                                    <div *ngIf=" submitted && lineaBusiness.controls.categoriaProd.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="lineaBusiness.controls.categoriaProd.errors.required">Campo
                                            obbligatorio
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="this.lineeBusinessFormArray[i].controls.tipologiaAtt.value!=null && 
                                this.lineeBusinessFormArray[i].controls.tipologiaAtt.value.id==1"
                                    class="col-3 align-items-center margin-bottom-10 margin-top-10">
                                    <label>Sito produttivo</label>
                                    <select class="form-control form-select" formControlName="sitoProduttivo"
                                        [ngClass]="{'is-invalid': submitted && lineaBusiness.controls.sitoProduttivo.errors}">
                                        <option [ngValue]="regione" *ngFor="let regione of regioni">
                                            {{regione.regione}}
                                        </option>
                                    </select>
                                    <div *ngIf=" submitted && lineaBusiness.controls.sitoProduttivo.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="lineaBusiness.controls.sitoProduttivo.errors.required">Campo
                                            obbligatorio
                                        </div>
                                    </div>
                                </div>

                            </ng-container>

                            <!-- ngif == ateco!= '01' -->
                            <ng-container *ngIf="!agricoltura">
                                <div class="col-4  d-flex align-items-center">
                                    <label class="text-left margin-top-10">Si prevede
                                        la gestione delle scorte per questa linea di business?&nbsp;&nbsp;<i id="icon"
                                            class="fa fa-1x fa-info-circle margin-right-10" aria-hidden="true"
                                            placement="top" container="body"
                                            [ngbTooltip]="listaTips[9]?.descrizione"></i>&nbsp;&nbsp;
                                    </label>
                                </div>
                                <div class="col-2 d-flex align-items-center justify-content-start">
                                    <select formControlName="presenzaMagazzino" type="boolean"
                                        class="form-control form-select"
                                        [ngClass]="{ 'is-invalid':submitted && lineaBusiness.controls.presenzaMagazzino.errors }">
                                        <option [ngValue]="true">Si</option>
                                        <option [ngValue]="false">No</option>
                                    </select>
                                </div>
                            </ng-container>
                            <!--  -->

                            <div class="col-1 margin-bottom-10 d-flex justify-content-center align-items-center"
                                style="padding: 0px; position: absolute;right :0;">
                                <button class="btn fa fa-trash fa-lg pointer" (click)="EliminaLineaDiBusiness(i)"
                                    aria-hidden="true" type="button" placement="top"
                                    ngbTooltip="Elimina Linea di Business"></button>
                            </div>
                        </div>
                        <!-- ngif: ateco== '01' -->

                        <!--  -->
                        <!-- ngif == ateco!= '01' -->
                        <h2>Piano di crescita della Linea di Business</h2>
                        <div class="row">
                            <div class="col-3">
                                <div class="row">
                                    <div class="col-12 d-flex align-items-center margin-bottom-10 margin-top-10">
                                        <div>
                                            <label>Evoluzione</label>
                                            <select formControlName="evoluzioneLineaDiBusiness" value=""
                                                class="form-control form-select"
                                                [ngClass]="{ 'is-invalid':submitted && lineaBusiness.controls.evoluzioneLineaDiBusiness.errors }"
                                                style="max-width:100%;" (change)="onChangeLinee(i)">
                                                <option [ngValue]="evoluzioneLineaDiBusiness"
                                                    *ngFor="let evoluzioneLineaDiBusiness of listaEvoluzioneLinea">
                                                    {{evoluzioneLineaDiBusiness.descrizione}}
                                                </option>
                                            </select>
                                            <div *ngIf="submitted && lineaBusiness.controls.evoluzioneLineaDiBusiness.errors"
                                                class="invalid-feedback">
                                                <div
                                                    *ngIf="lineaBusiness.controls.evoluzioneLineaDiBusiness.errors.required">
                                                    Campo obbligatorio
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <ng-container
                                *ngIf="this.lineeBusinessFormArray[i].controls['evoluzioneLineaDiBusiness'].value && (this.lineeBusinessFormArray[i].controls['evoluzioneLineaDiBusiness'].value.id == 2 || this.lineeBusinessFormArray[i].controls['evoluzioneLineaDiBusiness'].value.id == 1)">

                                <div class="col-1 d-flex align-items-center justify-content-center">
                                    <h3 class="text" style="margin-left: 5px;">
                                        Oscillazioni</h3>&nbsp;&nbsp;
                                    <i id=" icon" class="fa fa-1x fa-info-circle" aria-hidden="true" container="body"
                                        placement="top" ngbTooltip="{{listaTips[10]?.descrizione}}"></i>

                                </div>
                                <div class="col-7">
                                    <table class="table table-borderless">
                                        <thead>
                                            <tr>
                                                <th scope="col"></th>
                                                <th scope="col" class="text-left"> % Ribasso
                                                </th>
                                                <th scope="col" class="text-left"> % Rialzo
                                                </th>
                                                <th scope="col" class="text-left"> % Scarto&nbsp;<i id="icon"
                                                        class="fa fa-1x fa-info-circle" aria-hidden="true"
                                                        container="body" placement="top"
                                                        [ngbTooltip]="listaTips[5]?.descrizione"></i>
                                                </th>
                                                <th *ngIf="this.lineeBusinessFormArray[i].controls['evoluzioneLineaDiBusiness'].value.id == 2"
                                                    scope="col" class="text-left"> % Drift&nbsp;<i id="icon"
                                                        class="fa fa-1x fa-info-circle" aria-hidden="true"
                                                        container="body" placement="top"
                                                        [ngbTooltip]="listaTips[6]?.descrizione"></i>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr formGroupName="quantitaVenduta">
                                                <th scope="row" class="align-middle text-right"
                                                    style="width: 25%;text-align: right;">Q.tà
                                                    venduta:
                                                </th>
                                                <td><input formControlName="ribasso" type="number" class="form-control"
                                                        min="0" onkeyup="if(this.value<0){this.value= this.value *-1}"
                                                        [ngClass]="{ 'is-invalid':submitted && lineaBusiness.controls.quantitaVenduta.controls.ribasso.errors }" />
                                                    <div *ngIf=" submitted && lineaBusiness.controls.quantitaVenduta.controls.ribasso.errors"
                                                        class="invalid-feedback">
                                                        <div
                                                            *ngIf="lineaBusiness.controls.quantitaVenduta.controls.ribasso.errors.required">
                                                            Campo
                                                            obbligatorio
                                                        </div>
                                                        <div
                                                            *ngIf="lineaBusiness.controls.quantitaVenduta.controls.ribasso.errors.max">
                                                            Parametro fuori
                                                            scala. Inserire
                                                            un valore compreso tra 0 e {{valoreMax.ribasso}}%
                                                        </div>
                                                    </div>
                                                </td>
                                                <td><input formControlName="rialzo" type="number" class="form-control"
                                                        min="0" onkeyup="if(this.value<0){this.value= this.value *-1}"
                                                        [ngClass]="{ 'is-invalid':submitted && lineaBusiness.controls.quantitaVenduta.controls.rialzo.errors }" />
                                                    <div *ngIf=" submitted && lineaBusiness.controls.quantitaVenduta.controls.rialzo.errors"
                                                        class="invalid-feedback">
                                                        <div
                                                            *ngIf="lineaBusiness.controls.quantitaVenduta.controls.rialzo.errors.required">
                                                            Campo
                                                            obbligatorio
                                                        </div>
                                                        <div
                                                            *ngIf="lineaBusiness.controls.quantitaVenduta.controls.rialzo.errors.max">
                                                            Parametro fuori
                                                            scala. Inserire
                                                            un valore compreso tra 0 e {{valoreMax.rialzo}}%
                                                        </div>
                                                    </div>
                                                </td>
                                                <td><input formControlName="scarto" type="number" class="form-control"
                                                        min="0" onkeyup="if(this.value<0){this.value= this.value *-1}"
                                                        [ngClass]="{ 'is-invalid':submitted && lineaBusiness.controls.quantitaVenduta.controls.scarto.errors }" />
                                                    <div *ngIf=" submitted && lineaBusiness.controls.quantitaVenduta.controls.scarto.errors"
                                                        class="invalid-feedback">
                                                        <div
                                                            *ngIf="lineaBusiness.controls.quantitaVenduta.controls.scarto.errors.required">
                                                            Campo obbligatorio</div>
                                                        <div
                                                            *ngIf="lineaBusiness.controls.quantitaVenduta.controls.scarto.errors.min || lineaBusiness.controls.quantitaVenduta.controls.scarto.errors.max">
                                                            Parametro fuori
                                                            scala. Inserire
                                                            un valore compreso tra 0 e {{valoreMax.scarto}}%</div>
                                                    </div>
                                                </td>
                                                <td
                                                    *ngIf="this.lineeBusinessFormArray[i].controls['evoluzioneLineaDiBusiness'].value.id == 2">
                                                    <input formControlName="drift" type="number" class="form-control"
                                                        min="0" onkeyup="if(this.value<0){this.value= this.value *-1}"
                                                        [ngClass]="{ 'is-invalid':submitted && lineaBusiness.controls.quantitaVenduta.controls.drift.errors }" />
                                                    <div *ngIf=" submitted && lineaBusiness.controls.quantitaVenduta.controls.drift.errors"
                                                        class="invalid-feedback">
                                                        <div
                                                            *ngIf="lineaBusiness.controls.quantitaVenduta.controls.drift.errors.required">
                                                            Campo
                                                            obbligatorio
                                                        </div>
                                                        <div
                                                            *ngIf="lineaBusiness.controls.quantitaVenduta.controls.drift.errors.max">
                                                            Parametro fuori
                                                            scala. Inserire
                                                            un valore compreso tra 0 e {{valoreMax.drift}}%
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr formGroupName="prezzoIniziale">
                                                <th scope="row" class="align-middle text-right"
                                                    style="width: 25%;text-align: right;">Prezzo
                                                    iniziale:
                                                </th>
                                                <td><input formControlName="ribasso" type="number" class="form-control"
                                                        min="0" onkeyup="if(this.value<0){this.value= this.value *-1}"
                                                        [ngClass]="{ 'is-invalid':submitted && lineaBusiness.controls.prezzoIniziale.controls.ribasso.errors }" />
                                                    <div *ngIf=" submitted && lineaBusiness.controls.prezzoIniziale.controls.ribasso.errors"
                                                        class="invalid-feedback">
                                                        <div
                                                            *ngIf="lineaBusiness.controls.prezzoIniziale.controls.ribasso.errors.required">
                                                            Campo
                                                            obbligatorio
                                                        </div>
                                                        <div
                                                            *ngIf="lineaBusiness.controls.prezzoIniziale.controls.ribasso.errors.max">
                                                            Parametro fuori
                                                            scala. Inserire
                                                            un valore compreso tra 0 e {{valoreMax.ribasso}}%
                                                        </div>
                                                    </div>
                                                </td>
                                                <td><input formControlName="rialzo" type="number" class="form-control"
                                                        min="0" onkeyup="if(this.value<0){this.value= this.value *-1}"
                                                        [ngClass]="{ 'is-invalid':submitted && lineaBusiness.controls.prezzoIniziale.controls.rialzo.errors }" />
                                                    <div *ngIf=" submitted && lineaBusiness.controls.prezzoIniziale.controls.rialzo.errors"
                                                        class="invalid-feedback">
                                                        <div
                                                            *ngIf="lineaBusiness.controls.prezzoIniziale.controls.rialzo.errors.required">
                                                            Campo
                                                            obbligatorio
                                                        </div>
                                                        <div
                                                            *ngIf="lineaBusiness.controls.prezzoIniziale.controls.rialzo.errors.max">
                                                            Parametro fuori
                                                            scala. Inserire
                                                            un valore compreso tra 0 e {{valoreMax.rialzo}}%
                                                        </div>
                                                    </div>
                                                </td>
                                                <td><input formControlName="scarto" type="number" class="form-control"
                                                        min="0.1" onkeyup="if(this.value<0){this.value= this.value *-1}"
                                                        [ngClass]="{ 'is-invalid':submitted && lineaBusiness.controls.prezzoIniziale.controls.scarto.errors }" />
                                                    <div *ngIf=" submitted && lineaBusiness.controls.prezzoIniziale.controls.scarto.errors"
                                                        class="invalid-feedback">
                                                        <div
                                                            *ngIf="lineaBusiness.controls.prezzoIniziale.controls.scarto.errors.required">
                                                            Campo
                                                            obbligatorio
                                                        </div>
                                                        <div
                                                            *ngIf="lineaBusiness.controls.prezzoIniziale.controls.scarto.errors.min || lineaBusiness.controls.prezzoIniziale.controls.scarto.errors.max">
                                                            Parametro fuori
                                                            scala. Inserire
                                                            un valore compreso tra 0 e {{valoreMax.scarto}}%</div>
                                                    </div>
                                                </td>
                                                <td
                                                    *ngIf="this.lineeBusinessFormArray[i].controls['evoluzioneLineaDiBusiness'].value.id == 2">
                                                    <input formControlName="drift" type="number" class="form-control"
                                                        min="0" onkeyup="if(this.value<0){this.value= this.value *-1}"
                                                        [ngClass]="{ 'is-invalid':submitted && lineaBusiness.controls.prezzoIniziale.controls.drift.errors }" />
                                                    <div *ngIf=" submitted && lineaBusiness.controls.prezzoIniziale.controls.drift.errors"
                                                        class="invalid-feedback">
                                                        <div
                                                            *ngIf="lineaBusiness.controls.prezzoIniziale.controls.drift.errors.required">
                                                            Campo
                                                            obbligatorio
                                                        </div>
                                                        <div
                                                            *ngIf="lineaBusiness.controls.prezzoIniziale.controls.drift.errors.max">
                                                            Parametro fuori
                                                            scala. Inserire
                                                            un valore compreso tra 0 e {{valoreMax.drift}}%
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr formGroupName="costoIniziale">
                                                <th scope="row" class="align-middle text-right"
                                                    style="width: 25%;text-align: right;">Costo
                                                    iniziale
                                                </th>
                                                <td><input formControlName="ribasso" type="number" class="form-control"
                                                        min="0" onkeyup="if(this.value<0){this.value= this.value *-1}"
                                                        [ngClass]="{ 'is-invalid':submitted && lineaBusiness.controls.costoIniziale.controls.ribasso.errors }" />
                                                    <div *ngIf=" submitted && lineaBusiness.controls.costoIniziale.controls.ribasso.errors"
                                                        class="invalid-feedback">
                                                        <div
                                                            *ngIf="lineaBusiness.controls.costoIniziale.controls.ribasso.errors.required">
                                                            Campo
                                                            obbligatorio
                                                        </div>
                                                        <div
                                                            *ngIf="lineaBusiness.controls.costoIniziale.controls.ribasso.errors.max">
                                                            Parametro fuori
                                                            scala. Inserire
                                                            un valore compreso tra 0 e {{valoreMax.ribasso}}%
                                                        </div>
                                                    </div>
                                                </td>
                                                <td><input formControlName="rialzo" type="number" class="form-control"
                                                        min="0" onkeyup="if(this.value<0){this.value= this.value *-1}"
                                                        [ngClass]="{ 'is-invalid':submitted && lineaBusiness.controls.costoIniziale.controls.rialzo.errors }" />
                                                    <div *ngIf=" submitted && lineaBusiness.controls.costoIniziale.controls.rialzo.errors"
                                                        class="invalid-feedback">
                                                        <div
                                                            *ngIf="lineaBusiness.controls.costoIniziale.controls.rialzo.errors.required">
                                                            Campo
                                                            obbligatorio
                                                        </div>
                                                        <div
                                                            *ngIf="lineaBusiness.controls.costoIniziale.controls.rialzo.errors.max">
                                                            Parametro fuori
                                                            scala. Inserire
                                                            un valore compreso tra 0 e {{valoreMax.rialzo}}%
                                                        </div>
                                                    </div>
                                                </td>
                                                <td><input formControlName="scarto" type="number" class="form-control"
                                                        min="0" onkeyup="if(this.value<0){this.value= this.value *-1}"
                                                        [ngClass]="{ 'is-invalid':submitted && lineaBusiness.controls.costoIniziale.controls.scarto.errors }" />
                                                    <div *ngIf=" submitted && lineaBusiness.controls.costoIniziale.controls.scarto.errors"
                                                        class="invalid-feedback">
                                                        <div
                                                            *ngIf="lineaBusiness.controls.costoIniziale.controls.scarto.errors.required">
                                                            Campo
                                                            obbligatorio
                                                        </div>
                                                        <div
                                                            *ngIf="lineaBusiness.controls.costoIniziale.controls.scarto.errors.min || lineaBusiness.controls.costoIniziale.controls.scarto.errors.max">
                                                            Parametro fuori
                                                            scala. Inserire
                                                            un valore compreso tra 0 e {{valoreMax.scarto}}%</div>
                                                    </div>
                                                </td>
                                                <td
                                                    *ngIf="this.lineeBusinessFormArray[i].controls['evoluzioneLineaDiBusiness'].value.id == 2">
                                                    <input formControlName="drift" type="number" class="form-control"
                                                        min="0" onkeyup="if(this.value<0){this.value= this.value *-1}"
                                                        [ngClass]="{ 'is-invalid':submitted && lineaBusiness.controls.costoIniziale.controls.drift.errors }" />
                                                    <div *ngIf=" submitted && lineaBusiness.controls.costoIniziale.controls.drift.errors"
                                                        class="invalid-feedback">
                                                        <div
                                                            *ngIf="lineaBusiness.controls.costoIniziale.controls.drift.errors.required">
                                                            Campo
                                                            obbligatorio
                                                        </div>
                                                        <div
                                                            *ngIf="lineaBusiness.controls.costoIniziale.controls.drift.errors.max">
                                                            Parametro fuori
                                                            scala. Inserire
                                                            un valore compreso tra 0 e {{valoreMax.drift}}%
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <!-- <div class="col-1 margin-bottom-10 d-flex justify-content-center align-items-center"
                                    style="padding: 0px;">
                                    <button class="btn fa fa-trash fa-lg pointer" (click)="EliminaLineaDiBusiness(i)"
                                        aria-hidden="true" type="button" placement="top"
                                        ngbTooltip="Elimina Linea di Business"></button>
                                </div> -->
                                <div class="col-5"
                                    *ngIf="this.lineeBusinessFormArray[i].controls['evoluzioneLineaDiBusiness'].value.id == 1">
                                </div>
                                <div class="col-7 d-flex justify-content-center"
                                    *ngIf="this.lineeBusinessFormArray[i].controls['evoluzioneLineaDiBusiness'].value.id == 1">
                                    <div class="row justify-content-center align-items-center">
                                        <div class="col-8  warning-label" style="text-align:center;">
                                            <p>
                                                <strong>Attenzione : </strong>sarà necessario configurare il
                                                valore di <strong>Drift</strong> nella Configurazione del Business
                                                Plan
                                            </p>
                                        </div>

                                    </div>
                                </div>
                            </ng-container>
                        </div>

                        <!--  -->
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

</div>