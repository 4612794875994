import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ConfigurazioneAmbienteService } from 'src/app/_services/configurazione-ambiente.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ToolTipService } from './../../_services/tool-tip.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalcontentComponent } from 'src/app/modalcontent/modalcontent/modalcontent.component';
import { BeanStatico } from 'src/app/condivisi/beanStatico';
import { ParametrGenerali } from 'src/app/condivisi/parametri-generali';
import { ToolTip } from 'src/app/interface/toolTip';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-parametri-generali',
  templateUrl: './parametri-generali.component.html',
  styleUrls: ['./parametri-generali.component.css']
})
export class ParametriGeneraliComponent implements OnInit, OnDestroy {

  @Input('atecoPrimario') codiceAtecoPrimario: string;
  @Input() submitted: boolean

  valoreMax: any = {};

  /* prendo in input  idRichiedenteTemp*/
  @Input() get idRichiedenteTemp(): number {
    return this._idRichiedenteTemp;
  }
  /* uso input pre prendere l'id. getter e setter per triggerare il reset ogni volta che l'id cambia*/
  /* _idRichiedenteTemp variabile che carico */
  set idRichiedenteTemp(idRichiedenteTemp: number) {
    this._idRichiedenteTemp = idRichiedenteTemp;
    this.notSaved = true;
    this.parametriGeneraliForm.markAsPristine();
    if (this._idRichiedenteTemp != undefined) {
      this.submitted = false;
      this.sommaCentoCrediti = true;
      this.sommaCentoPagamenti = true;
      this.parametriGeneraliForm.reset(this.initialValues);
      this.onLoadParametriGenerali()
      this.disabledSalva = false;
      /* this.onLoadParametriGenerali() */
    } else { this.disabledSalva = true; }
  }
  disabledSalva: boolean = true;
  private _idRichiedenteTemp: number;
  idParametriGeneraliTemp: number = 0;
  listaTips: ToolTip[] = [];
  listaAnnoIniziale: BeanStatico[];
  listaEvoluzioneInflazione: BeanStatico[];
  tipsSubscription: Subscription
  formValid = true;
  initialValues: any;

  parametriGeneraliForm: UntypedFormGroup;

  sommaCentoCrediti: boolean = true;
  sommaCentoPagamenti: boolean = true;

  notSaved: boolean = true;


  constructor(
    private formBuilder: UntypedFormBuilder,
    private configurazioneAmbienteService: ConfigurazioneAmbienteService,
    private tokeStorageService: TokenStorageService,
    private httpClient: HttpClient,
    private toolTipService: ToolTipService,
    private modalService: NgbModal,
  ) {
    this.parametriGeneraliForm = this.formBuilder.group({
      anniSviluppoBP: [0],
      annoIniziale: [{ value: '', disabled: true }, Validators.required,],
      inflazione: ['', Validators.required],
      evoluzioneInflazione: this.formBuilder.group({
        inflazioneIniziale: [''],
        ribasso: [0],
        rialzo: [0],
        scarto: [1],
        drift: [0],
      }),
      costiSulFatturato: [''],
      costiFatturatoVariazioneAnnoPrecedente: [''],
      giorniIncassoCrediti: this.formBuilder.group({
        a30Giorni: [0, Validators.required], /* 30 gg */
        a60Giorni: [0, Validators.required], /* 60 gg */
        a90Giorni: [0, Validators.required], /* 90 gg */
        oltre90Giorni: [0, Validators.required], /* >90 gg */
      }),
      giorniPagamentoFornitori: this.formBuilder.group({
        a30Giorni: [0, Validators.required], /* 30 gg */
        a60Giorni: [0, Validators.required], /* 60 gg */
        a90Giorni: [0, Validators.required], /* 90 gg */
        oltre90Giorni: [0, Validators.required], /* >90 gg */
      }),
    })
  }

  get f() { return this.parametriGeneraliForm.controls; }
  get evoluzioneInflazione(): UntypedFormGroup { return <UntypedFormGroup> this.parametriGeneraliForm.controls.evoluzioneInflazione; }
  get giorniIncassoCrediti(): UntypedFormGroup { return <UntypedFormGroup> this.parametriGeneraliForm.controls.giorniIncassoCrediti; }
  get giorniPagamentoFornitori(): UntypedFormGroup { return <UntypedFormGroup> this.parametriGeneraliForm.controls.giorniPagamentoFornitori; }

  ngOnDestroy(): void {
    this.tipsSubscription.unsubscribe()
  }

  ngOnInit(): void {
    this.onLoadSelect();
    this.tipsSubscription = this.toolTipService.tipsArray.subscribe(arr => {
      if (arr != null) {
        this.listaTips = arr
      }
    })


    this.initialValues = this.parametriGeneraliForm.value;
    this.configurazioneAmbienteService.getValoriMax('parametri generali').subscribe(response => {
      response.forEach(element => {
        if (element.error == null) {
          this.valoreMax[element.nomeCampo] = element.valoreMax;
        }
      });
      this.updateValidators();
      /* console.log(this.valoreMax); */
    })
  }

  onLoadParametriGenerali() {
    this.configurazioneAmbienteService.getParametriGenerali(this._idRichiedenteTemp, this.tokeStorageService.getUser().idUtente).subscribe(response => {
      if (response.error == null) {
        /* console.log(response); */
        this.idParametriGeneraliTemp = response.parametriGenerali.id;
        this.f.anniSviluppoBP.setValue(response.parametriGenerali.anniSviluppoBP);
        //this.f.inflazione.setValue(response.parametriGenerali.inflazione);
        this.listaAnnoIniziale.forEach(element => {
          if (element.id == response.parametriGenerali.annoIniziale.id) {
            this.f.annoIniziale.setValue(element);
          }
        })
        this.listaEvoluzioneInflazione.forEach(element => {
          if (element.id == response.parametriGenerali.inflazione.id) {
            this.f.inflazione.setValue(element);
          }
        })
        if (this.f.inflazione.value.id == 2) {
          this.evoluzioneInflazione.controls.inflazioneIniziale.setValue(response.parametriGenerali.evoluzioneInflazione.valoreIniziale);
          this.evoluzioneInflazione.controls.ribasso.setValue(response.parametriGenerali.evoluzioneInflazione.ribasso);
          this.evoluzioneInflazione.controls.rialzo.setValue(response.parametriGenerali.evoluzioneInflazione.rialzo);
          this.evoluzioneInflazione.controls.scarto.setValue(response.parametriGenerali.evoluzioneInflazione.scarto);
          this.evoluzioneInflazione.controls.drift.setValue(response.parametriGenerali.evoluzioneInflazione.drift);

          this.evoluzioneInflazione.controls.inflazioneIniziale.setValidators(Validators.compose([Validators.required, Validators.max(this.valoreMax.valoreInflazione)]));
          this.evoluzioneInflazione.controls.ribasso.setValidators([Validators.required, Validators.max(this.valoreMax.ribassoInflazione)]);
          this.evoluzioneInflazione.controls.rialzo.setValidators([Validators.required, Validators.max(this.valoreMax.rialzoInflazione)]);
          this.evoluzioneInflazione.controls.scarto.setValidators([Validators.required, Validators.max(this.valoreMax.scartoInflazione)]);
          this.evoluzioneInflazione.controls.drift.setValidators([Validators.required, Validators.max(this.valoreMax.driftInflazione)]);
        }
        this.f.costiSulFatturato.setValue(response.parametriGenerali.costiSulFatturato);
        this.f.costiFatturatoVariazioneAnnoPrecedente.setValue(response.parametriGenerali.costiVariazioneAnnoPrecedente);
        this.giorniIncassoCrediti.controls.a30Giorni.setValue(response.parametriGenerali.giorniIncassoCrediti.a30Giorni);
        this.giorniIncassoCrediti.controls.a60Giorni.setValue(response.parametriGenerali.giorniIncassoCrediti.a60Giorni);
        this.giorniIncassoCrediti.controls.a90Giorni.setValue(response.parametriGenerali.giorniIncassoCrediti.a90Giorni);
        this.giorniIncassoCrediti.controls.oltre90Giorni.setValue(response.parametriGenerali.giorniIncassoCrediti.oltre90Giorni);

        this.giorniPagamentoFornitori.controls.a30Giorni.setValue(response.parametriGenerali.giorniPagamentoFornitori.a30Giorni);
        this.giorniPagamentoFornitori.controls.a60Giorni.setValue(response.parametriGenerali.giorniPagamentoFornitori.a60Giorni);
        this.giorniPagamentoFornitori.controls.a90Giorni.setValue(response.parametriGenerali.giorniPagamentoFornitori.a90Giorni);
        this.giorniPagamentoFornitori.controls.oltre90Giorni.setValue(response.parametriGenerali.giorniPagamentoFornitori.oltre90Giorni);


      } else {
        this.idParametriGeneraliTemp = 0;
        this.f.annoIniziale.setValue(this.listaAnnoIniziale[0]);
      }
      this.f.anniSviluppoBP.updateValueAndValidity();
      this.f.annoIniziale.updateValueAndValidity();
      this.f.inflazione.updateValueAndValidity();
      this.evoluzioneInflazione.controls.inflazioneIniziale.updateValueAndValidity();
      this.evoluzioneInflazione.controls.ribasso.updateValueAndValidity();
      this.evoluzioneInflazione.controls.rialzo.updateValueAndValidity();
      this.evoluzioneInflazione.controls.scarto.updateValueAndValidity();
      this.evoluzioneInflazione.controls.drift.updateValueAndValidity();
      this.f.costiSulFatturato.updateValueAndValidity();
      this.f.costiFatturatoVariazioneAnnoPrecedente.updateValueAndValidity();
      this.giorniIncassoCrediti.controls.a30Giorni.updateValueAndValidity();
      this.giorniIncassoCrediti.controls.a60Giorni.updateValueAndValidity();
      this.giorniIncassoCrediti.controls.a90Giorni.updateValueAndValidity();
      this.giorniIncassoCrediti.controls.oltre90Giorni.updateValueAndValidity();
      this.giorniPagamentoFornitori.controls.a30Giorni.updateValueAndValidity();
      this.giorniPagamentoFornitori.controls.a60Giorni.updateValueAndValidity();
      this.giorniPagamentoFornitori.controls.a90Giorni.updateValueAndValidity();
      this.giorniPagamentoFornitori.controls.oltre90Giorni.updateValueAndValidity();
      this.parametriGeneraliForm.updateValueAndValidity();
    })

  }
  updateValidators() {
    this.f.anniSviluppoBP.setValidators(Validators.compose([Validators.required, Validators.max(this.valoreMax.anniSviluppoBp)]));
    this.f.costiSulFatturato.setValidators(Validators.compose([Validators.required, Validators.max(this.valoreMax.costiSulFatturato)]));
    this.f.costiFatturatoVariazioneAnnoPrecedente.setValidators(Validators.compose([Validators.required, Validators.max(this.valoreMax.costiFatturatoVariazioneAnnoPrecedente)]));
    this.f.anniSviluppoBP.updateValueAndValidity()
    this.f.costiSulFatturato.updateValueAndValidity()
    this.f.costiFatturatoVariazioneAnnoPrecedente.updateValueAndValidity()
  }


  /* caricare le select scritte a db */
  onLoadSelect() {
    this.httpClient.get<BeanStatico[]>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneAmbiente/parametriGenerali/getAnnoIniziale').subscribe(response => {
      this.listaAnnoIniziale = response
    })
    this.httpClient.get<BeanStatico[]>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneAmbiente/parametriGenerali/getModalitaEvoluzione').subscribe(response => {
      this.listaEvoluzioneInflazione = response
    })

  }
  onChangeInflazione() {
    if (this.f.inflazione.value.id == 2) {
      this.evoluzioneInflazione.controls.inflazioneIniziale.setValidators(Validators.compose([Validators.required, Validators.max(this.valoreMax.valoreInflazione)]));
      this.evoluzioneInflazione.controls.ribasso.setValidators(Validators.compose([Validators.required, Validators.max(this.valoreMax.ribassoInflazione)]));
      this.evoluzioneInflazione.controls.rialzo.setValidators(Validators.compose([Validators.required, Validators.max(this.valoreMax.rialzoInflazione)]));
      this.evoluzioneInflazione.controls.scarto.setValidators(Validators.compose([Validators.required, Validators.min(0.1), Validators.max(this.valoreMax.scartoInflazione)]));
      this.evoluzioneInflazione.controls.drift.setValidators(Validators.compose([Validators.required, Validators.max(this.valoreMax.driftInflazione)]));
    } else {
      this.evoluzioneInflazione.controls.inflazioneIniziale.clearValidators();
      this.evoluzioneInflazione.controls.ribasso.clearValidators();
      this.evoluzioneInflazione.controls.rialzo.clearValidators();
      this.evoluzioneInflazione.controls.scarto.clearValidators();
      this.evoluzioneInflazione.controls.drift.clearValidators();
    }
    this.evoluzioneInflazione.controls.inflazioneIniziale.updateValueAndValidity();
    this.evoluzioneInflazione.controls.ribasso.updateValueAndValidity();
    this.evoluzioneInflazione.controls.rialzo.updateValueAndValidity();
    this.evoluzioneInflazione.controls.scarto.updateValueAndValidity();
    this.evoluzioneInflazione.controls.drift.updateValueAndValidity();
  }

  isFormDirty() {
    this.submitted = this.parametriGeneraliForm.dirty
    return this.submitted;
  }

  annulla() {
    if (this.isFormDirty()) {
      if (this.modalService.hasOpenModals) {
        this.modalService.dismissAll();
      }
      const modalRef = this.modalService.open(ModalcontentComponent, {
        backdrop: 'static',
        backdropClass: 'customBackdrop',
      });
      modalRef.componentInstance.modalPar = {
        name: "annulla",
        title: "Sicuro di voler annullare ?",
        description: "Così facendo, tutte le modifiche non salvate in Parametri Generali andranno persi.",
        undoButtonText: "Indietro",
        actionButtonText: "Annulla",
      }
      modalRef.result.then((r) => {
        if (r) {
          this.parametriGeneraliForm.reset(this.initialValues);
          this.submitted = false;
          this.sommaCentoCrediti = true;
          this.sommaCentoPagamenti = true;
          this.onLoadParametriGenerali()

        }
      })
    }
  }

  parametriGeneraliValid() {
    this.sommaCentoCrediti = true;
    this.sommaCentoPagamenti = true;
    if (this.giorniIncassoCrediti.controls.a30Giorni.value + this.giorniIncassoCrediti.controls.a60Giorni.value + this.giorniIncassoCrediti.controls.a90Giorni.value + this.giorniIncassoCrediti.controls.oltre90Giorni.value != 100) {
      this.sommaCentoCrediti = false;
    }
    if (this.giorniPagamentoFornitori.controls.a30Giorni.value + this.giorniPagamentoFornitori.controls.a60Giorni.value + this.giorniPagamentoFornitori.controls.a90Giorni.value + this.giorniPagamentoFornitori.controls.oltre90Giorni.value != 100) {
      this.sommaCentoPagamenti = false;
    }
    this.formValid = this.parametriGeneraliForm.valid && this.sommaCentoCrediti && this.sommaCentoPagamenti;
    return this.formValid
  }
  onSubmit() {
    if (this.isFormDirty()) {
      if (this._idRichiedenteTemp != undefined) {
        /* modale per inserire l'azienda */
        /* validità form */
        if (this.parametriGeneraliValid()) {
          if (this.modalService.hasOpenModals) {
            this.modalService.dismissAll(); /*chiude tutto per sicurezza? */
          }
          /* modale salva */
          const modalRef = this.modalService.open(ModalcontentComponent);
          modalRef.componentInstance.modalPar = {
            name: "salvamodifiche",
            description: "Salvare i parametri inseriti?",
            actionButtonText: "Salva",
            undoButtonText: "Indietro",
          }
          modalRef.result.then((r) => {
            if (r) {
              let parametriGenerali: ParametrGenerali = {
                id: this.idParametriGeneraliTemp,
                anniSviluppoBP: this.f.anniSviluppoBP.value,
                annoIniziale: this.f.annoIniziale.value,
                inflazione: this.f.inflazione.value,
                evoluzioneInflazione: {
                  valoreIniziale: this.evoluzioneInflazione.controls.inflazioneIniziale.value == '' ? 0 : this.evoluzioneInflazione.controls.inflazioneIniziale.value,
                  ribasso: this.evoluzioneInflazione.controls.ribasso.value == '' ? 0 : this.evoluzioneInflazione.controls.ribasso.value,
                  rialzo: this.evoluzioneInflazione.controls.rialzo.value == '' ? 0 : this.evoluzioneInflazione.controls.rialzo.value,
                  scarto: this.evoluzioneInflazione.controls.scarto.value == '' ? 1 : this.evoluzioneInflazione.controls.scarto.value,
                  drift: this.evoluzioneInflazione.controls.drift.value == '' ? 0 : this.evoluzioneInflazione.controls.drift.value,
                },
                costiSulFatturato: this.f.costiSulFatturato.value,
                costiVariazioneAnnoPrecedente: this.f.costiFatturatoVariazioneAnnoPrecedente.value,
                giorniIncassoCrediti: {
                  a30Giorni: this.giorniIncassoCrediti.controls.a30Giorni.value == '' ? 0 : this.giorniIncassoCrediti.controls.a30Giorni.value,
                  a60Giorni: this.giorniIncassoCrediti.controls.a60Giorni.value == '' ? 0 : this.giorniIncassoCrediti.controls.a60Giorni.value,
                  a90Giorni: this.giorniIncassoCrediti.controls.a90Giorni.value == '' ? 0 : this.giorniIncassoCrediti.controls.a90Giorni.value,
                  oltre90Giorni: this.giorniIncassoCrediti.controls.oltre90Giorni.value == '' ? 0 : this.giorniIncassoCrediti.controls.oltre90Giorni.value,
                },
                giorniPagamentoFornitori: {
                  a30Giorni: this.giorniPagamentoFornitori.controls.a30Giorni.value == '' ? 0 : this.giorniPagamentoFornitori.controls.a30Giorni.value,
                  a60Giorni: this.giorniPagamentoFornitori.controls.a60Giorni.value == '' ? 0 : this.giorniPagamentoFornitori.controls.a60Giorni.value,
                  a90Giorni: this.giorniPagamentoFornitori.controls.a90Giorni.value == '' ? 0 : this.giorniPagamentoFornitori.controls.a90Giorni.value,
                  oltre90Giorni: this.giorniPagamentoFornitori.controls.oltre90Giorni.value == '' ? 0 : this.giorniPagamentoFornitori.controls.oltre90Giorni.value,
                }
              }
              let request = {
                idRichiedente: this._idRichiedenteTemp,
                idUtente: this.tokeStorageService.getUser().idUtente,
                parametriGenerali: parametriGenerali,
              }
              console.log(request.parametriGenerali);
              this.configurazioneAmbienteService.saveParametriGenerali(request).subscribe(
                response => {
                  if (response) {
                    const modalRef = this.modalService.open(ModalcontentComponent);
                    modalRef.componentInstance.modalPar = {
                      name: "messaggiosalva",
                      description: "Salvato con successo",
                      actionButtonText: "",
                      undoButtonText: "Ok",
                    }
                    modalRef.result.then(r => {
                      //this.configurazioneAmbienteService.LoadParametriGeneraliDirty(false)
                      this.notSaved = false;
                      this.parametriGeneraliForm.reset(this.initialValues);
                      this.onLoadParametriGenerali();
                    })
                  } else {
                    const modalRef = this.modalService.open(ModalcontentComponent);
                    modalRef.componentInstance.modalPar = {
                      name: "messaggiosalva",
                      description: "Errore salvataggio",
                      actionButtonText: "",
                      undoButtonText: "Ok",
                    }
                    modalRef.result.then(r => {
                      /* this.onLoadParametriGenerali(); */
                    })
                  }


                });
            }
          });



        }
      }
    }
  }

}
