// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrlQuestionario: 'https://endy.goalgo.it/Fas', // http://localhost:8080 https://svil.goalgo.it/Fas https://test.goalgo.it/Endy https://endy.goalgo.it/Fas /* https://exogito.ddns.net/Fas    http://80.211.50.149:8082/Fas*/
  apiUrlNode: '', // http://80.211.50.149:28082 'https://test.goalgo.it/fasNode'
  apiUrlPdf: '',
  apiUrlLogin: 'https://endy.goalgo.it/Fas',//'http://localhost:8080 https://svil.goalgo.it/Fas https://endy.goalgo.it/Fas https://test.goalgo.it/Endy',

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
