import { DecimalPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild, EventEmitter, Output, OnDestroy } from '@angular/core';
import { FormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BeanStatico } from 'src/app/condivisi/beanStatico';
import { ParametriStocasticiIniziale } from 'src/app/condivisi/parametri-stocastici-iniziale';
import { customCorrToSave, IndicatoriGuida, IndicatoriGuidaRequest } from 'src/app/interface/indicatori-guida-bp';
import { PraticheNuova } from 'src/app/interface/Pratiche-nuova';
import { ModalcontentComponent } from 'src/app/modalcontent/modalcontent/modalcontent.component';
import { ConfigurazioneAmbienteService } from 'src/app/_services/configurazione-ambiente.service';
import { ConfigurazioneBusinessPlanService } from 'src/app/_services/configurazione-business-plan.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { ToolTipService } from 'src/app/_services/tool-tip.service';
import { environment } from 'src/environments/environment';
import { ToolTip } from 'src/app/interface/toolTip';
import { Subscription } from 'rxjs';
import { MatCorr } from 'src/app/interface/correlazioni';
import { CorrelazioniService } from 'src/app/_services/correlazioni.service';
import { BeanStaticoEsteso } from 'src/app/condivisi/beanStaticoEsteso';
import { BeanStaticoCorrelazioni } from 'src/app/condivisi/beanStaticoCorrelazioni';

@Component({
  selector: 'app-indicatori-guida-bp',
  templateUrl: './indicatori-guida-bp.component.html',
  styleUrls: ['./indicatori-guida-bp.component.css']
})
export class IndicatoriGuidaBpComponent implements OnInit, OnDestroy {

  @ViewChild('costiFissi') costiFissi!: ElementRef;
  @Input() submitted: boolean
  @Input() indicatoriGuidaVolatile!: IndicatoriGuidaRequest
  @Input() indicatoriGuidaForm: UntypedFormGroup;
  @Output() emitIndicatoriFormValid = new EventEmitter<any>()
  @Input() businessPlanTemp: PraticheNuova


  idIndicatoriGuidaTemp: number = 0;
  initialValues: any;
  valoreMax: any = {};
  arrayInflazioneAnnuale: number[] = null;
  inflazioneEvoluzione: ParametriStocasticiIniziale = null;

  risultatiVolatileSubscription: Subscription
  arrayLineeBpCorrelazioniSubscription: Subscription
  tipsSubscription: Subscription

  listaEvoluzioneInflazione: BeanStatico[];
  listaTips: ToolTip[] = [];

  idAnnoIniziale: number = 0;
  arrayAnni: number[] = [];

  firstLb: number = 0;
  otherLb: number[] = [];
  lastSelected: number = -1;
  lastSelectedCp: number = -1;
  isFirstTime: boolean = true;
  listaTipoCorrelazioniTraLinee: BeanStaticoEsteso[] = [];
  opt1: number[] = [1, 2, 3, 4]
  opt2: number[] = [1, 2, 3, 4]
  opt3: number[] = [1, 2, 3, 4]
  opt4: number[] = [1, 2, 3, 4]
  tempMat: MatCorr = { matrice1: 0, matrice2: 0, matrice3: 0, matrice4: 0, matrice5: 0, corr12: 0, corr13: 0, corr14: 0, corr15: 0 };
  corrMiste: number[] = [];
  corrMistePerLegenda: number[] = [];
  corrInterne: number[] = [];
  corrMisteTemp: number[] = [0, 0, 0, 0];
  ordineLinee: number[] = [];
  ordineLineeAmbiente: number[] = []
  tempDataToSave: customCorrToSave = { id: 0, idBusinessPlan: 0, idMatrice: 0, ordineLineeBp: [] };

  indicatoriGuidaBpValid: boolean = true
  isRisultatiVolatile: boolean = false

  lineeBusinessCorrelazioni: BeanStaticoCorrelazioni[] = [];
  lineeBusinessCorrelazioniOrdinato: BeanStatico[] = [];
  cartProductCorr: any[] = [{ uno: BeanStatico, due: BeanStatico }];
  valoriMatriceDefault: MatCorr;

  warningInflazioneIniziale: boolean = false
  warningInflazioneDrift: boolean = false
  warningInflazioneScarto: boolean = false
  warningInflazioneRialzo: boolean = false
  warningInflazioneRibasso: boolean = false
  warningInflazioneAnnuale: boolean[] = []
  warningInflazioneAnnualeComp: boolean = false
  warningCostiFissi: boolean = false;
  warningCostiFissiVariazioneAnnoPrecedente: boolean = false
  warningCostiSulFatturato: boolean = false
  warningCostiFatturatoVariazioneAnnoPrecedente: boolean = false

  sommaCentoCrediti: boolean = true;
  sommaCentoPagamenti: boolean = true;

  correlazione: UntypedFormControl;
  valoreCorrelazione: UntypedFormControl;

  firstLBStart: number = 0;
  otherLBstart: number[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private decimalPipe: DecimalPipe,
    private configurazioneAmbienteService: ConfigurazioneAmbienteService,
    private configurazioneBusinessPlanService: ConfigurazioneBusinessPlanService,
    private tokeStorageService: TokenStorageService,
    private httpClient: HttpClient,
    private toolTipService: ToolTipService,
    private modalService: NgbModal,
    private corrService: CorrelazioniService,
  ) {


  }

  checkValMax(nomeCampo: string) {
    /* inflazione */
    if (nomeCampo == "inflazioneIniziale") {
      if (this.evoluzioneInflazione.controls.inflazioneIniziale.value != null) {
        if (this.evoluzioneInflazione.controls.inflazioneIniziale.value > this.valoreMax.valoreInflazione) {
          this.warningInflazioneIniziale = true;
        } else {
          this.warningInflazioneIniziale = false
        }
      }
    } else if (nomeCampo == "inflazioneRibasso") {
      if (this.evoluzioneInflazione.controls.ribasso.value != null) {
        if (this.evoluzioneInflazione.controls.ribasso.value > this.valoreMax.ribassoInflazione) {
          this.warningInflazioneRibasso = true;
        } else {
          this.warningInflazioneRibasso = false
        }
      }
    } else if (nomeCampo == "inflazioneRialzo") {
      if (this.evoluzioneInflazione.controls.rialzo.value != null) {
        if (this.evoluzioneInflazione.controls.rialzo.value > this.valoreMax.rialzoInflazione) {
          this.warningInflazioneRialzo = true;
        } else {
          this.warningInflazioneRialzo = false
        }
      }
    } else if (nomeCampo == "inflazioneScarto") {
      if (this.evoluzioneInflazione.controls.scarto.value != null) {
        if (this.evoluzioneInflazione.controls.scarto.value > this.valoreMax.scartoInflazione) {
          this.warningInflazioneScarto = true;
        } else {
          this.warningInflazioneScarto = false
        }
      }
    } else if (nomeCampo == "inflazioneDrift") {
      if (this.evoluzioneInflazione.controls.drift.value != null) {
        if (this.evoluzioneInflazione.controls.drift.value > this.valoreMax.driftInflazione) {
          this.warningInflazioneDrift = true;
        } else {
          this.warningInflazioneDrift = false
        }
      }
    }
    /* costi e variazione anno precedente */
    if (nomeCampo == "costiSulFatturato") {
      if (this.f.costiSulFatturato.value != null) {
        if (this.f.costiSulFatturato.value > this.valoreMax.costiSulFatturato) {
          this.warningCostiSulFatturato = true;
        } else {
          this.warningCostiSulFatturato = false
        }
      }
    } else if (nomeCampo == "costiFatturatoVariazioneAnnoPrecedente") {
      if (this.f.costiFatturatoVariazioneAnnoPrecedente.value != null) {
        if (this.f.costiFatturatoVariazioneAnnoPrecedente.value > this.valoreMax.costiFatturatoVariazioneAnnoPrecedente) {
          this.warningCostiFatturatoVariazioneAnnoPrecedente = true;
        } else {
          this.warningCostiFatturatoVariazioneAnnoPrecedente = false
        }
      }
    } else if (nomeCampo == "costiSulFatturato") {
      if (this.f.costiSulFatturato.value != null) {
        if (this.f.costiSulFatturato.value > this.valoreMax.costiSulFatturato) {
          this.warningCostiSulFatturato = true;
        } else {
          this.warningCostiSulFatturato = false
        }
      }
    } else if (nomeCampo == "costiFissiVariazioneAnnoPrecedente") {
      if (this.f.costiFissiVariazioneAnnoPrecedente.value != null) {
        if (this.f.costiFissiVariazioneAnnoPrecedente.value > this.valoreMax.costiFissiVariazioneAnnoPrecedente) {
          this.warningCostiFissiVariazioneAnnoPrecedente = true;
        } else {
          this.warningCostiFissiVariazioneAnnoPrecedente = false
        }
      }
    }
  }
  checkValMaxInflazioneAnnuale(index: number) {
    this.warningInflazioneAnnualeComp = false
    if (this.inflazioneAnnualeFormArray[index].value != null) {
      if (this.inflazioneAnnualeFormArray[index].value > this.valoreMax.valoreInflazione) {
        this.warningInflazioneAnnuale.splice(index, 1, true);
      } else {
        this.warningInflazioneAnnuale.splice(index, 1, false);
      }
    }
    for (let index = 0; index < this.warningInflazioneAnnuale.length; index++) {
      this.warningInflazioneAnnualeComp = this.warningInflazioneAnnualeComp || this.warningInflazioneAnnuale[index]
    }
  }
  /* mettere nel submit, annulla, elabora */
  disabilitaWarning() {
    this.warningInflazioneIniziale = false
    this.warningInflazioneDrift = false
    this.warningInflazioneScarto = false
    this.warningInflazioneRialzo = false
    this.warningInflazioneRibasso = false

    this.warningCostiFissi = false;
    this.warningCostiFissiVariazioneAnnoPrecedente = false
    this.warningCostiSulFatturato = false
    this.warningCostiFatturatoVariazioneAnnoPrecedente = false
    this.warningInflazioneAnnualeComp = false
    for (let index = 0; index < this.warningInflazioneAnnuale.length; index++) {
      this.warningInflazioneAnnuale.splice(index, 1, false);
    }
  }

  get f() { return this.indicatoriGuidaForm.controls; }
  get evoluzioneInflazione(): UntypedFormGroup { return <UntypedFormGroup> this.indicatoriGuidaForm.controls.evoluzioneInflazione; }
  get giorniIncassoCrediti(): UntypedFormGroup { return <UntypedFormGroup> this.indicatoriGuidaForm.controls.giorniIncassoCrediti; }
  get inflazioneAnnualeArray(): UntypedFormArray { return <UntypedFormArray> this.indicatoriGuidaForm.controls.inflazioneAnnuale; }
  get correlazioneArray(): UntypedFormArray { return <UntypedFormArray> this.indicatoriGuidaForm.controls.correlazioniBusinessPlan; }

  get inflazioneAnnualeFormArray(): UntypedFormControl[] { return <UntypedFormControl[]> this.inflazioneAnnualeArray.controls; }
  get correlazioneFormArray(): UntypedFormGroup[] { return <UntypedFormGroup[]> this.correlazioneArray.controls; }
  get giorniPagamentoFornitori(): UntypedFormGroup { return <UntypedFormGroup> this.indicatoriGuidaForm.controls.giorniPagamentoFornitori; }

  ngOnInit(): void {


    this.corrService.takeAllCorrDesc().subscribe(result => {
      this.listaTipoCorrelazioniTraLinee = result;
    })


    this.risultatiVolatileSubscription = this.configurazioneBusinessPlanService.currentRisultatiVolatile.subscribe(siVolatile => {
      this.isRisultatiVolatile = siVolatile
    })

    this.indicatoriGuidaForm = this.formBuilder.group({
      anniSviluppoBP: [{ value: '', disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],
      inflazione: [{ value: '', disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],
      evoluzioneInflazione: this.formBuilder.group({
        inflazioneIniziale: [{ value: '', disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
        ribasso: [{ value: '', disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
        rialzo: [{ value: '', disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
        scarto: [{ value: '', disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
        drift: [{ value: '', disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
      }),
      inflazioneAnnuale: this.formBuilder.array([]),
      costiSulFatturato: [{ value: '', disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, [Validators.required]],
      costiFatturatoVariazioneAnnoPrecedente: [{ value: '', disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, [Validators.required]],
      costiFissi: [{ value: '', disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required,],
      costiFissiVariazioneAnnoPrecedente: [{ value: '', disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],
      giorniIncassoCrediti: this.formBuilder.group({
        a30Giorni: [{ value: '', disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required], /* 30 gg */
        a60Giorni: [{ value: '', disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required], /* 60 gg */
        a90Giorni: [{ value: '', disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],/* 90 gg */
        oltre90Giorni: [{ value: '', disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required], /* >90 gg */
      }),
      giorniPagamentoFornitori: this.formBuilder.group({
        a30Giorni: [{ value: '', disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required], /* 30 gg */
        a60Giorni: [{ value: '', disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required], /* 60 gg */
        a90Giorni: [{ value: '', disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],/* 90 gg */
        oltre90Giorni: [{ value: '', disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required], /* >90 gg */
      }),
      correlazioniBusinessPlan: this.formBuilder.array([])
    })

    this.arrayLineeBpCorrelazioniSubscription = this.configurazioneBusinessPlanService.currentLineeCorrelazioni.subscribe(r => {

      if (r != null) {
        this.correlazioneArray.clear();
        this.lineeBusinessCorrelazioni = [];
        this.lineeBusinessCorrelazioni = r;
        this.cartProductCorr = [];
        for (var i = 0; i < r.length; i++) {
          if (i != 0) {
            let tempForm = this.formBuilder.group({
              correlazione: [{ value: '', disabled: this.businessPlanTemp != null && ((this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) || this.checkDisable(i - 1)) }, Validators.required],
              valoreCorrelazione: [{ value: '', disabled: this.businessPlanTemp != null && ((this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) || this.checkDisable(i - 1)) }, Validators.required],
            })
            this.correlazioneArray.push(tempForm)
          }
          for (var l = 0; l < r.length; l++) {
            i != l ? this.cartProductCorr.push({ uno: r[i], due: r[l] }) : null;
          }
        }
        this.checkExistCustomCorr();
        this.onLoadSelect();

      }
    })

    this.configurazioneAmbienteService.getValoriMax('parametri generali').subscribe(response => {
      response.forEach(element => {
        if (element.error == null) {
          this.valoreMax[element.nomeCampo] = element.valoreMax;
        }
      });
      this.updateValidators();
    })
    this.tipsSubscription = this.toolTipService.tipsArray.subscribe(arr => {
      if (arr != null) {
        this.listaTips = arr
      }
    })

  }

  updateValidators() {
    this.f.anniSviluppoBP.setValidators(Validators.required);
    this.f.costiSulFatturato.setValidators(Validators.required);
    this.f.costiFatturatoVariazioneAnnoPrecedente.setValidators(Validators.required);
    this.f.costiFissiVariazioneAnnoPrecedente.setValidators(Validators.required);
    this.f.inflazione.setValidators(Validators.required);

    this.f.anniSviluppoBP.updateValueAndValidity();
    this.f.inflazione.updateValueAndValidity();
    this.evoluzioneInflazione.controls.inflazioneIniziale.updateValueAndValidity();
    this.f.costiSulFatturato.updateValueAndValidity();
    this.f.costiFatturatoVariazioneAnnoPrecedente.updateValueAndValidity();
  }



  onChangeInflazione() {
    this.inflazioneAnnualeArray.clear()
    this.arrayAnni = [];
    if (this.f.inflazione.value.id == 2) {

      this.evoluzioneInflazione.controls.inflazioneIniziale.setValue(this.inflazioneEvoluzione == null ? '' : this.inflazioneEvoluzione.valoreIniziale);
      this.evoluzioneInflazione.controls.ribasso.setValue(this.inflazioneEvoluzione == null ? '' : this.inflazioneEvoluzione.ribasso,);
      this.evoluzioneInflazione.controls.rialzo.setValue(this.inflazioneEvoluzione == null ? '' : this.inflazioneEvoluzione.rialzo);
      this.evoluzioneInflazione.controls.scarto.setValue(this.inflazioneEvoluzione == null ? '' : this.inflazioneEvoluzione.scarto);
      this.evoluzioneInflazione.controls.drift.setValue(this.inflazioneEvoluzione == null ? '' : this.inflazioneEvoluzione.drift);

      this.evoluzioneInflazione.controls.inflazioneIniziale.setValidators(Validators.required);
      this.evoluzioneInflazione.controls.ribasso.setValidators(Validators.required);
      this.evoluzioneInflazione.controls.rialzo.setValidators(Validators.required);
      this.evoluzioneInflazione.controls.scarto.setValidators(Validators.required);
      this.evoluzioneInflazione.controls.drift.setValidators(Validators.required);


    } else if (this.f.inflazione.value.id == 1) {
      this.evoluzioneInflazione.controls.inflazioneIniziale.clearValidators();
      this.evoluzioneInflazione.controls.ribasso.clearValidators();
      this.evoluzioneInflazione.controls.rialzo.clearValidators();
      this.evoluzioneInflazione.controls.scarto.clearValidators();
      this.evoluzioneInflazione.controls.drift.clearValidators();
      const annoIniziale = new Date().getFullYear();
      if (this.f.anniSviluppoBP.value < 11) {
        this.warningInflazioneAnnuale = []
        this.arrayAnni = [];
        for (let i = 0; i < this.f.anniSviluppoBP.value; i++) {
          this.warningInflazioneAnnuale.push(false)
          if (this.idAnnoIniziale == 1) {
            this.arrayAnni.push(annoIniziale + i);
          } else if (this.idAnnoIniziale == 2) {
            this.arrayAnni.push(annoIniziale + i + 1);
          }
          const annoForm = new UntypedFormControl({ value: this.arrayInflazioneAnnuale[i] == null ? 0 : this.arrayInflazioneAnnuale[i], disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required);
          annoForm.updateValueAndValidity();
          this.inflazioneAnnualeFormArray.push(annoForm);
          this.inflazioneAnnualeFormArray[i].updateValueAndValidity()
        }
      }
      this.inflazioneAnnualeArray.updateValueAndValidity();
    }
    this.evoluzioneInflazione.controls.inflazioneIniziale.updateValueAndValidity();
    this.evoluzioneInflazione.controls.ribasso.updateValueAndValidity();
    this.evoluzioneInflazione.controls.rialzo.updateValueAndValidity();
    this.evoluzioneInflazione.controls.scarto.updateValueAndValidity();
    this.evoluzioneInflazione.controls.drift.updateValueAndValidity();
  }

  onLoadSelect() {
    this.httpClient.get<BeanStatico[]>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneAmbiente/parametriGenerali/getModalitaEvoluzione').subscribe(response => {
      this.listaEvoluzioneInflazione = response;

      this.getIndicatoriGuida();

    })
  }



  elaboraIndicatoriGuidaBpComponent() {
    if (this.inflazioneAnnualeFormArray.length > 0) {
      for (let i = 0; i < this.f.anniSviluppoBP.value; i++) {
        this.inflazioneAnnualeFormArray[i].updateValueAndValidity()
      }
    }
    this.inflazioneAnnualeArray.updateValueAndValidity();
    this.indicatoriGuidaForm.updateValueAndValidity();
    this.submitted = true;
    /* check somma percentuali pagamenti e crediti*/
    //Le somme contengono direttamente il booleano corrispondente (somma == 100), nell'if controlliamo che siano entrambe vere
    this.sommaCentoCrediti = this.giorniIncassoCrediti.controls.a30Giorni.value + this.giorniIncassoCrediti.controls.a60Giorni.value + this.giorniIncassoCrediti.controls.a90Giorni.value + this.giorniIncassoCrediti.controls.oltre90Giorni.value == 100;
    this.sommaCentoPagamenti = this.giorniPagamentoFornitori.controls.a30Giorni.value + this.giorniPagamentoFornitori.controls.a60Giorni.value + this.giorniPagamentoFornitori.controls.a90Giorni.value + this.giorniPagamentoFornitori.controls.oltre90Giorni.value == 100;
    this.indicatoriGuidaBpValid = this.indicatoriGuidaForm.valid && this.sommaCentoPagamenti && this.sommaCentoCrediti
    if (this.indicatoriGuidaBpValid) {
      this.disabilitaWarning()
      let indicatoriGuida: IndicatoriGuida = {
        id: this.idIndicatoriGuidaTemp,
        anniSviluppoBP: this.f.anniSviluppoBP.value,
        idAnnoIniziale: this.idAnnoIniziale,
        inflazione: this.f.inflazione.value,
        evoluzioneInflazione: {
          valoreIniziale: this.evoluzioneInflazione.controls.inflazioneIniziale.value == '' ? 0 : this.evoluzioneInflazione.controls.inflazioneIniziale.value,
          ribasso: this.evoluzioneInflazione.controls.ribasso.value == '' ? 0 : this.evoluzioneInflazione.controls.ribasso.value,
          rialzo: this.evoluzioneInflazione.controls.rialzo.value == '' ? 0 : this.evoluzioneInflazione.controls.rialzo.value,
          scarto: this.evoluzioneInflazione.controls.scarto.value == '' ? 1 : this.evoluzioneInflazione.controls.scarto.value,
          drift: this.evoluzioneInflazione.controls.drift.value == '' ? 0 : this.evoluzioneInflazione.controls.drift.value,
        },
        inflazioneAnnuale: this.inflazioneAnnualeArray ? this.inflazioneAnnualeArray.value : [],
        costiSulFatturato: this.f.costiSulFatturato.value,
        costiFatturatoVariazioneAnnoPrecedente: this.f.costiFatturatoVariazioneAnnoPrecedente.value,
        costiFissi: parseFloat(this.f.costiFissi.value),
        costiFissiVariazioneAnnoPrecedente: this.f.costiFissiVariazioneAnnoPrecedente.value,
        giorniIncassoCrediti: {
          a30Giorni: this.giorniIncassoCrediti.controls.a30Giorni.value == '' ? 0 : this.giorniIncassoCrediti.controls.a30Giorni.value,
          a60Giorni: this.giorniIncassoCrediti.controls.a60Giorni.value == '' ? 0 : this.giorniIncassoCrediti.controls.a60Giorni.value,
          a90Giorni: this.giorniIncassoCrediti.controls.a90Giorni.value == '' ? 0 : this.giorniIncassoCrediti.controls.a90Giorni.value,
          oltre90Giorni: this.giorniIncassoCrediti.controls.oltre90Giorni.value == '' ? 0 : this.giorniIncassoCrediti.controls.oltre90Giorni.value,
        },
        giorniPagamentoFornitori: {
          a30Giorni: this.giorniPagamentoFornitori.controls.a30Giorni.value == '' ? 0 : this.giorniPagamentoFornitori.controls.a30Giorni.value,
          a60Giorni: this.giorniPagamentoFornitori.controls.a60Giorni.value == '' ? 0 : this.giorniPagamentoFornitori.controls.a60Giorni.value,
          a90Giorni: this.giorniPagamentoFornitori.controls.a90Giorni.value == '' ? 0 : this.giorniPagamentoFornitori.controls.a90Giorni.value,
          oltre90Giorni: this.giorniPagamentoFornitori.controls.oltre90Giorni.value == '' ? 0 : this.giorniPagamentoFornitori.controls.oltre90Giorni.value,
        }

      }
      let request: IndicatoriGuidaRequest = {
        idBusinessPlan: this.businessPlanTemp.id,
        /*idRichiedente: this._idRichiedenteTemp,  */
        idUtente: this.tokeStorageService.getUser().idUtente,
        indicatoriGuida: indicatoriGuida,
        correlazioniBp: this.tempDataToSave,
      }

      return request
      //this.configurazioneBusinessPlanService.elaboraIndicatoriGuidaBp(request)



    }
  }

  isFormDirty() {
    let inflazioneAnnualeArrayDirty = false
    if (this.inflazioneAnnualeFormArray.length > 0) {
      for (let i = 0; i < this.f.anniSviluppoBP.value; i++) {
        this.inflazioneAnnualeFormArray[i].updateValueAndValidity()
        if (this.inflazioneAnnualeFormArray[i].dirty)
          inflazioneAnnualeArrayDirty = this.inflazioneAnnualeFormArray[i].dirty
      }
    }
    if (inflazioneAnnualeArrayDirty) {
      this.inflazioneAnnualeArray.markAsDirty()
    }
    this.inflazioneAnnualeArray.updateValueAndValidity();
    this.indicatoriGuidaForm.updateValueAndValidity();

    this.submitted = this.indicatoriGuidaForm.dirty
    //this.submitted = true; // imposto direttamente a true perche puo capitare che indicatori guida non venga mai modificato e quindi mai salvato
    return this.submitted;
  }
  getIndicatoriGuida() {
    if (this.businessPlanTemp) {
      if (this.isRisultatiVolatile && this.indicatoriGuidaVolatile != null) {
        this.idIndicatoriGuidaTemp = this.indicatoriGuidaVolatile.indicatoriGuida.id;
        this.arrayInflazioneAnnuale = this.indicatoriGuidaVolatile.indicatoriGuida.inflazioneAnnuale;
        this.inflazioneEvoluzione = this.indicatoriGuidaVolatile.indicatoriGuida.evoluzioneInflazione;
        this.idAnnoIniziale = this.indicatoriGuidaVolatile.indicatoriGuida.idAnnoIniziale,
          this.f.anniSviluppoBP.setValue(this.indicatoriGuidaVolatile.indicatoriGuida.anniSviluppoBP);

        if (this.listaEvoluzioneInflazione) {
          this.listaEvoluzioneInflazione.forEach(element => {
            if (element.id == this.indicatoriGuidaVolatile.indicatoriGuida.inflazione.id) {
              this.f.inflazione.setValue(element);
            }
          })
        }

        this.f.costiSulFatturato.setValue(this.indicatoriGuidaVolatile.indicatoriGuida.costiSulFatturato);
        this.f.costiFatturatoVariazioneAnnoPrecedente.setValue(this.indicatoriGuidaVolatile.indicatoriGuida.costiFatturatoVariazioneAnnoPrecedente);
        this.f.costiFissi.setValue(this.indicatoriGuidaVolatile.indicatoriGuida.costiFissi);
        this.f.costiFissiVariazioneAnnoPrecedente.setValue(this.indicatoriGuidaVolatile.indicatoriGuida.costiFissiVariazioneAnnoPrecedente);
        this.giorniIncassoCrediti.controls.a30Giorni.setValue(this.indicatoriGuidaVolatile.indicatoriGuida.giorniIncassoCrediti.a30Giorni);
        this.giorniIncassoCrediti.controls.a60Giorni.setValue(this.indicatoriGuidaVolatile.indicatoriGuida.giorniIncassoCrediti.a60Giorni);
        this.giorniIncassoCrediti.controls.a90Giorni.setValue(this.indicatoriGuidaVolatile.indicatoriGuida.giorniIncassoCrediti.a90Giorni);
        this.giorniIncassoCrediti.controls.oltre90Giorni.setValue(this.indicatoriGuidaVolatile.indicatoriGuida.giorniIncassoCrediti.oltre90Giorni);

        this.giorniPagamentoFornitori.controls.a30Giorni.setValue(this.indicatoriGuidaVolatile.indicatoriGuida.giorniPagamentoFornitori.a30Giorni);
        this.giorniPagamentoFornitori.controls.a60Giorni.setValue(this.indicatoriGuidaVolatile.indicatoriGuida.giorniPagamentoFornitori.a60Giorni);
        this.giorniPagamentoFornitori.controls.a90Giorni.setValue(this.indicatoriGuidaVolatile.indicatoriGuida.giorniPagamentoFornitori.a90Giorni);
        this.giorniPagamentoFornitori.controls.oltre90Giorni.setValue(this.indicatoriGuidaVolatile.indicatoriGuida.giorniPagamentoFornitori.oltre90Giorni);

        this.f.anniSviluppoBP.updateValueAndValidity();
        this.f.inflazione.updateValueAndValidity();
        this.evoluzioneInflazione.controls.inflazioneIniziale.updateValueAndValidity();
        this.evoluzioneInflazione.controls.ribasso.updateValueAndValidity();
        this.evoluzioneInflazione.controls.rialzo.updateValueAndValidity();
        this.evoluzioneInflazione.controls.scarto.updateValueAndValidity();
        this.evoluzioneInflazione.controls.drift.updateValueAndValidity();
        this.f.costiSulFatturato.updateValueAndValidity();
        this.f.costiFatturatoVariazioneAnnoPrecedente.updateValueAndValidity();
        this.f.costiFissi.updateValueAndValidity();
        this.f.costiFissiVariazioneAnnoPrecedente.updateValueAndValidity();
        this.giorniIncassoCrediti.controls.a30Giorni.updateValueAndValidity();
        this.giorniIncassoCrediti.controls.a60Giorni.updateValueAndValidity();
        this.giorniIncassoCrediti.controls.a90Giorni.updateValueAndValidity();
        this.giorniIncassoCrediti.controls.oltre90Giorni.updateValueAndValidity();
        this.giorniPagamentoFornitori.controls.a30Giorni.updateValueAndValidity();
        this.giorniPagamentoFornitori.controls.a60Giorni.updateValueAndValidity();
        this.giorniPagamentoFornitori.controls.a90Giorni.updateValueAndValidity();
        this.giorniPagamentoFornitori.controls.oltre90Giorni.updateValueAndValidity();
        this.indicatoriGuidaForm.updateValueAndValidity();
        this.initialValues = this.indicatoriGuidaForm.value;
        this.onChangeInflazione()
      }
      else {
        this.configurazioneBusinessPlanService.getIndicatoriGuida(this.businessPlanTemp.id, this.businessPlanTemp.idAziendaRichiedente).subscribe(response => {
          if (response.error == null) {
            this.idIndicatoriGuidaTemp = response.source == 'BusinessPlan' ? response.id : 0;
            this.arrayInflazioneAnnuale = response.inflazioneAnnuale;
            this.inflazioneEvoluzione = response.evoluzioneInflazione;
            this.idAnnoIniziale = response.idAnnoIniziale,
              this.f.anniSviluppoBP.setValue(response.anniSviluppoBP);
            this.returnAnniBp()
            if (this.listaEvoluzioneInflazione) {
              this.listaEvoluzioneInflazione.forEach(element => {
                if (element.id == response.inflazione.id) {
                  this.f.inflazione.setValue(element);
                }
              })
            }
            this.f.costiSulFatturato.setValue(response.costiSulFatturato);
            this.f.costiFatturatoVariazioneAnnoPrecedente.setValue(response.costiVariazioneAnnoPrecedente);
            this.f.costiFissi.setValue(response.costiFissi);
            this.f.costiFissiVariazioneAnnoPrecedente.setValue(response.costiFissiVariazioneAnnoPrecedente != null ? response.costiFissiVariazioneAnnoPrecedente : '');
            this.giorniIncassoCrediti.controls.a30Giorni.setValue(response.giorniIncassoCrediti.a30Giorni);
            this.giorniIncassoCrediti.controls.a60Giorni.setValue(response.giorniIncassoCrediti.a60Giorni);
            this.giorniIncassoCrediti.controls.a90Giorni.setValue(response.giorniIncassoCrediti.a90Giorni);
            this.giorniIncassoCrediti.controls.oltre90Giorni.setValue(response.giorniIncassoCrediti.oltre90Giorni);

            this.giorniPagamentoFornitori.controls.a30Giorni.setValue(response.giorniPagamentoFornitori.a30Giorni);
            this.giorniPagamentoFornitori.controls.a60Giorni.setValue(response.giorniPagamentoFornitori.a60Giorni);
            this.giorniPagamentoFornitori.controls.a90Giorni.setValue(response.giorniPagamentoFornitori.a90Giorni);
            this.giorniPagamentoFornitori.controls.oltre90Giorni.setValue(response.giorniPagamentoFornitori.oltre90Giorni);

            this.f.anniSviluppoBP.updateValueAndValidity();
            this.f.inflazione.updateValueAndValidity();
            this.evoluzioneInflazione.controls.inflazioneIniziale.updateValueAndValidity();
            this.evoluzioneInflazione.controls.ribasso.updateValueAndValidity();
            this.evoluzioneInflazione.controls.rialzo.updateValueAndValidity();
            this.evoluzioneInflazione.controls.scarto.updateValueAndValidity();
            this.evoluzioneInflazione.controls.drift.updateValueAndValidity();
            this.f.costiSulFatturato.updateValueAndValidity();
            this.f.costiFatturatoVariazioneAnnoPrecedente.updateValueAndValidity();
            this.f.costiFissi.updateValueAndValidity();
            this.f.costiFissiVariazioneAnnoPrecedente.updateValueAndValidity();
            this.giorniIncassoCrediti.controls.a30Giorni.updateValueAndValidity();
            this.giorniIncassoCrediti.controls.a60Giorni.updateValueAndValidity();
            this.giorniIncassoCrediti.controls.a90Giorni.updateValueAndValidity();
            this.giorniIncassoCrediti.controls.oltre90Giorni.updateValueAndValidity();
            this.giorniPagamentoFornitori.controls.a30Giorni.updateValueAndValidity();
            this.giorniPagamentoFornitori.controls.a60Giorni.updateValueAndValidity();
            this.giorniPagamentoFornitori.controls.a90Giorni.updateValueAndValidity();
            this.giorniPagamentoFornitori.controls.oltre90Giorni.updateValueAndValidity();
            this.indicatoriGuidaForm.updateValueAndValidity();
          }
          this.initialValues = this.indicatoriGuidaForm.value;
          this.onChangeInflazione()
        })
      }
    }

  }

  markFormPristine() {
    this.indicatoriGuidaForm.markAsPristine()
  }
  Annulla() {
    if (this.isFormDirty()) {
      this.submitted = false;/*  va messo qui perche alreimenti se clicco annulla nei campi vuoti parte il validator.required finche lui non chiude la modale*/
      if (this.modalService.hasOpenModals) {
        this.modalService.dismissAll();
      }
      const modalRef = this.modalService.open(ModalcontentComponent, {
        backdrop: 'static',
        backdropClass: 'customBackdrop',
      });
      modalRef.componentInstance.modalPar = {
        name: "annulla",
        title: "Sicuro di voler annullare ?",
        description: "Così facendo tutte le modifiche non salvate su questa pagina andranno perse.",
        undoButtonText: "Indietro",
        actionButtonText: "Annulla",
      }
      modalRef.result.then((r) => {
        if (r) {
          this.disabilitaWarning()
          this.indicatoriGuidaForm.reset(this.initialValues);

          this.sommaCentoCrediti = true;
          this.sommaCentoPagamenti = true;
          this.indicatoriGuidaBpValid = true
          this.emitIndicatoriFormValid.emit({ indicatoriGuidaBpValid: this.indicatoriGuidaBpValid, indicatoriGuidaDirty: this.submitted })

          this.corrMisteTemp = [];
          this.otherLb = []
          this.firstLb = this.firstLBStart;
          this.lastSelected = this.otherLBstart.length;
          this.lastSelectedCp = this.otherLBstart.length;
          this.corrMiste.forEach(el => {
            this.corrMisteTemp.push(el)
          })
          this.otherLBstart.forEach(el => {
            this.otherLb.push(el)
          })

          this.getIndicatoriGuida()
          this.markFormPristine()

        }
      })
    }

  }
  isFormValid() {
    this.giorniPagamentoFornitori.controls.a30Giorni.updateValueAndValidity();
    this.giorniPagamentoFornitori.controls.a60Giorni.updateValueAndValidity();
    this.giorniPagamentoFornitori.controls.a90Giorni.updateValueAndValidity();
    this.giorniPagamentoFornitori.controls.oltre90Giorni.updateValueAndValidity();
    this.f.anniSviluppoBP.updateValueAndValidity();
    this.f.inflazione.updateValueAndValidity();
    this.evoluzioneInflazione.controls.inflazioneIniziale.updateValueAndValidity();
    this.f.costiSulFatturato.updateValueAndValidity();
    this.f.costiFatturatoVariazioneAnnoPrecedente.updateValueAndValidity();
    if (this.inflazioneAnnualeFormArray.length > 0) {
      for (let i = 0; i < this.f.anniSviluppoBP.value; i++) {
        this.inflazioneAnnualeFormArray[i].updateValueAndValidity()
      }
    }
    this.inflazioneAnnualeArray.updateValueAndValidity();
    this.f.costiFissi.updateValueAndValidity()
    this.f.costiFissiVariazioneAnnoPrecedente.updateValueAndValidity()
    this.indicatoriGuidaForm.updateValueAndValidity();
    this.sommaCentoCrediti = this.giorniIncassoCrediti.controls.a30Giorni.value + this.giorniIncassoCrediti.controls.a60Giorni.value + this.giorniIncassoCrediti.controls.a90Giorni.value + this.giorniIncassoCrediti.controls.oltre90Giorni.value == 100;
    this.sommaCentoPagamenti = this.giorniPagamentoFornitori.controls.a30Giorni.value + this.giorniPagamentoFornitori.controls.a60Giorni.value + this.giorniPagamentoFornitori.controls.a90Giorni.value + this.giorniPagamentoFornitori.controls.oltre90Giorni.value == 100;

    this.indicatoriGuidaBpValid = this.indicatoriGuidaForm.valid && this.sommaCentoPagamenti && this.sommaCentoCrediti

    return this.indicatoriGuidaBpValid
  }

  onSubmit() {
    this.disabilitaWarning()
    if (this.submitted) {//this.submitted
      if (this.isRisultatiVolatile) {
        let indicatoriGuida: IndicatoriGuida = {
          id: this.idIndicatoriGuidaTemp,
          anniSviluppoBP: this.f.anniSviluppoBP.value,
          idAnnoIniziale: this.idAnnoIniziale,
          inflazione: this.f.inflazione.value,
          evoluzioneInflazione: {
            valoreIniziale: this.evoluzioneInflazione.controls.inflazioneIniziale.value == '' ? 0 : this.evoluzioneInflazione.controls.inflazioneIniziale.value,
            ribasso: this.evoluzioneInflazione.controls.ribasso.value == '' ? 0 : this.evoluzioneInflazione.controls.ribasso.value,
            rialzo: this.evoluzioneInflazione.controls.rialzo.value == '' ? 0 : this.evoluzioneInflazione.controls.rialzo.value,
            scarto: this.evoluzioneInflazione.controls.scarto.value == '' ? 1 : this.evoluzioneInflazione.controls.scarto.value,
            drift: this.evoluzioneInflazione.controls.drift.value == '' ? 0 : this.evoluzioneInflazione.controls.drift.value,
          },
          inflazioneAnnuale: this.inflazioneAnnualeArray ? this.inflazioneAnnualeArray.value : [],
          costiSulFatturato: this.f.costiSulFatturato.value,
          costiFatturatoVariazioneAnnoPrecedente: this.f.costiFatturatoVariazioneAnnoPrecedente.value,
          costiFissi: parseFloat(this.f.costiFissi.value),
          costiFissiVariazioneAnnoPrecedente: this.f.costiFissiVariazioneAnnoPrecedente.value,
          giorniIncassoCrediti: {
            a30Giorni: this.giorniIncassoCrediti.controls.a30Giorni.value == '' ? 0 : this.giorniIncassoCrediti.controls.a30Giorni.value,
            a60Giorni: this.giorniIncassoCrediti.controls.a60Giorni.value == '' ? 0 : this.giorniIncassoCrediti.controls.a60Giorni.value,
            a90Giorni: this.giorniIncassoCrediti.controls.a90Giorni.value == '' ? 0 : this.giorniIncassoCrediti.controls.a90Giorni.value,
            oltre90Giorni: this.giorniIncassoCrediti.controls.oltre90Giorni.value == '' ? 0 : this.giorniIncassoCrediti.controls.oltre90Giorni.value,
          },
          giorniPagamentoFornitori: {
            a30Giorni: this.giorniPagamentoFornitori.controls.a30Giorni.value == '' ? 0 : this.giorniPagamentoFornitori.controls.a30Giorni.value,
            a60Giorni: this.giorniPagamentoFornitori.controls.a60Giorni.value == '' ? 0 : this.giorniPagamentoFornitori.controls.a60Giorni.value,
            a90Giorni: this.giorniPagamentoFornitori.controls.a90Giorni.value == '' ? 0 : this.giorniPagamentoFornitori.controls.a90Giorni.value,
            oltre90Giorni: this.giorniPagamentoFornitori.controls.oltre90Giorni.value == '' ? 0 : this.giorniPagamentoFornitori.controls.oltre90Giorni.value,
          }

        }
        let request: IndicatoriGuidaRequest = {
          idBusinessPlan: this.businessPlanTemp.id,
          /*idRichiedente: this._idRichiedenteTemp,  */
          idUtente: this.tokeStorageService.getUser().idUtente,
          indicatoriGuida: indicatoriGuida,
          correlazioniBp: this.tempDataToSave,
        }
        this.markFormPristine()
        return request
      } else {
        let indicatoriGuida: IndicatoriGuida = {
          id: this.idIndicatoriGuidaTemp,
          anniSviluppoBP: this.f.anniSviluppoBP.value,
          idAnnoIniziale: this.idAnnoIniziale,
          inflazione: this.f.inflazione.value,
          evoluzioneInflazione: {
            valoreIniziale: this.evoluzioneInflazione.controls.inflazioneIniziale.value == '' ? 0 : this.evoluzioneInflazione.controls.inflazioneIniziale.value,
            ribasso: this.evoluzioneInflazione.controls.ribasso.value == '' ? 0 : this.evoluzioneInflazione.controls.ribasso.value,
            rialzo: this.evoluzioneInflazione.controls.rialzo.value == '' ? 0 : this.evoluzioneInflazione.controls.rialzo.value,
            scarto: this.evoluzioneInflazione.controls.scarto.value == '' ? 1 : this.evoluzioneInflazione.controls.scarto.value,
            drift: this.evoluzioneInflazione.controls.drift.value == '' ? 0 : this.evoluzioneInflazione.controls.drift.value,
          },
          inflazioneAnnuale: this.inflazioneAnnualeArray ? this.inflazioneAnnualeArray.value : [],
          costiSulFatturato: this.f.costiSulFatturato.value,
          costiFatturatoVariazioneAnnoPrecedente: this.f.costiFatturatoVariazioneAnnoPrecedente.value,
          costiFissi: parseFloat(this.f.costiFissi.value),
          costiFissiVariazioneAnnoPrecedente: this.f.costiFissiVariazioneAnnoPrecedente.value,
          giorniIncassoCrediti: {
            a30Giorni: this.giorniIncassoCrediti.controls.a30Giorni.value == '' ? 0 : this.giorniIncassoCrediti.controls.a30Giorni.value,
            a60Giorni: this.giorniIncassoCrediti.controls.a60Giorni.value == '' ? 0 : this.giorniIncassoCrediti.controls.a60Giorni.value,
            a90Giorni: this.giorniIncassoCrediti.controls.a90Giorni.value == '' ? 0 : this.giorniIncassoCrediti.controls.a90Giorni.value,
            oltre90Giorni: this.giorniIncassoCrediti.controls.oltre90Giorni.value == '' ? 0 : this.giorniIncassoCrediti.controls.oltre90Giorni.value,
          },
          giorniPagamentoFornitori: {
            a30Giorni: this.giorniPagamentoFornitori.controls.a30Giorni.value == '' ? 0 : this.giorniPagamentoFornitori.controls.a30Giorni.value,
            a60Giorni: this.giorniPagamentoFornitori.controls.a60Giorni.value == '' ? 0 : this.giorniPagamentoFornitori.controls.a60Giorni.value,
            a90Giorni: this.giorniPagamentoFornitori.controls.a90Giorni.value == '' ? 0 : this.giorniPagamentoFornitori.controls.a90Giorni.value,
            oltre90Giorni: this.giorniPagamentoFornitori.controls.oltre90Giorni.value == '' ? 0 : this.giorniPagamentoFornitori.controls.oltre90Giorni.value,
          }
        }
        let request: IndicatoriGuidaRequest = {
          idBusinessPlan: this.businessPlanTemp.id,
          /*idRichiedente: this._idRichiedenteTemp,  */
          idUtente: this.tokeStorageService.getUser().idUtente,
          indicatoriGuida: indicatoriGuida,
          correlazioniBp: this.tempDataToSave,
        }
        this.markFormPristine()
        return request
      }

    } else {
      return null
    }


  }


  returnAnniBp() {
    this.f.anniSviluppoBP.updateValueAndValidity()
    let inizioDurata = {
      anniSviluppoBp: this.f.anniSviluppoBP.value,
      annoIniziale: this.idAnnoIniziale == 2 ? new Date().getFullYear() + 1 : new Date().getFullYear()
    }
    this.configurazioneBusinessPlanService.changeInizioDurata(inizioDurata);
    return inizioDurata;
  }

  ////////////////////////////////////////////////////

  // selezione della coppia di linee di business 
  onChangeCouple(el: any, index: number) {
    this.lastSelectedCp = index;
    index < this.lineeBusinessCorrelazioni.length - 2 ? (this.correlazioneFormArray[index + 1].controls.correlazione.enable(), this.correlazioneFormArray[index + 1].controls.correlazione.setValue(null)) : null;

    if (index == 0) {
      this.firstLb = el.split(",")[0]
      this.otherLb = []
      this.ordineLinee = []

      for (let i = index + 2; i < this.lineeBusinessCorrelazioni.length - 1; i++) {
        (this.correlazioneFormArray[i].controls.correlazione.disable(), this.correlazioneFormArray[i].controls.correlazione.setValue(null))
      }

      this.otherLb.push(+el.split(",")[1])
      this.ordineLinee.push(+el.split(",")[0])
      this.ordineLinee.push(+el.split(",")[1])

    } else {
      this.otherLb = this.otherLb.slice(0, index)
      this.otherLb.push(+el.split(",")[1])
      this.ordineLinee = this.ordineLinee.slice(0, index + 1)
      this.ordineLinee.push(+el.split(",")[1])

    }


    if (this.lastSelectedCp == this.lineeBusinessCorrelazioni.length - 2 && this.lastSelected == this.lineeBusinessCorrelazioni.length - 2) {
      let tempMat: MatCorr = {
        matrice1: this.corrInterne[0], matrice2: this.corrInterne[1], matrice3: this.corrInterne[2], matrice4: this.corrInterne[3], matrice5: this.corrInterne[4],
        corr12: this.corrMisteTemp[0], corr13: this.corrMisteTemp[1], corr14: this.corrMisteTemp[2], corr15: this.corrMisteTemp[3]
      };
      this.mergeData(tempMat)
    }

  }

  filterOtherByFirst(idLB: number, idOptPrinc: number): boolean {
    return idLB != 0 ? (idOptPrinc == idLB) : true;
  }

  filterNext(idOptSecArr: number[], idOptSec: number, index: number): boolean {
    let tempLB = idOptSecArr;
    return tempLB.slice(0, index).includes(idOptSec);
  }



  // cambio la select del valore delle correlazioni 
  cambiaOpt(scelta: number, indice: number) {

    this.isFirstTime = false;
    this.opt1 = [1, 2, 3, 4]
    this.opt2 = [1, 2, 3, 4]
    this.opt3 = [1, 2, 3, 4]
    this.opt4 = [1, 2, 3, 4]

    this.lastSelected = indice;
    this.corrMisteTemp[indice] = +scelta;

    this.corrMisteTemp.forEach((el, i) => {
      if (i > indice) {
        this.corrMisteTemp[i] = 0;
        if (i < this.lineeBusinessCorrelazioni.length - 1) {
          this.correlazioneFormArray[i].controls.valoreCorrelazione.disable()
        }
      }
    })

    let tempMat: MatCorr = {
      matrice1: this.corrInterne[0], matrice2: this.corrInterne[1], matrice3: this.corrInterne[2], matrice4: this.corrInterne[3], matrice5: this.corrInterne[4],
      corr12: this.corrMisteTemp[0], corr13: this.corrMisteTemp[1], corr14: this.corrMisteTemp[2], corr15: this.corrMisteTemp[3]
    };

    this.corrService.checkOptions(tempMat).subscribe(r => {
      if (indice == 0) {
        return this.opt1 = r
      } else if (indice == 1) {
        return this.opt2 = r
      } else if (indice == 2) {
        return this.opt3 = r
      } else if (indice == 3) {
        return this.opt4 = r
      } else {
        return true
      }
    })

    if (indice < this.lineeBusinessCorrelazioni.length - 2) {
      this.correlazioneFormArray[indice + 1].controls.valoreCorrelazione.enable()
      this.correlazioneFormArray[indice + 1].controls.valoreCorrelazione.setValue(null)
    }
    indice == this.lineeBusinessCorrelazioni.length - 2 && this.lastSelectedCp == this.lineeBusinessCorrelazioni.length - 2 ? this.mergeData(tempMat) : null;
  }

  isClean(): boolean {
    return this.corrMiste.every((v, i) => v == this.corrMisteTemp[i])
  }

  checkAble(n: number, index: number): boolean {
    if (index == 1) {
      return this.opt1.includes(n);
    } else if (index == 2) {
      return this.opt2.includes(n);
    } else if (index == 3) {
      return this.opt3.includes(n);
    } else if (index == 4) {
      return this.opt4.includes(n);
    } else {
      return true
    }
  }

  checkDisable(indice: number): boolean {
    if (indice > 0 && this.isFirstTime) {
      return indice > this.lastSelected
    } else if (indice > 0 && !this.isFirstTime) {
      return indice > this.lastSelected + 1
    } else {
      return false;
    }
  }

  cleanOpt(indice: number, type: any): boolean {
    if (this.isFirstTime) {
      return false;
    } else {
      if (indice == 0) {
        return false;
      } else {
        return type == "correlazione" ? indice > this.lastSelectedCp : indice > this.lastSelected;
      }
    }

  }

  // viene compilato l'oggetto da salvare 
  mergeData(tempMat: MatCorr) {
    this.corrService.checkExistingMat(tempMat).subscribe(r => {

      this.tempDataToSave.idMatrice = r
      this.tempDataToSave.ordineLineeBp = this.ordineLinee;
      this.tempDataToSave.idBusinessPlan = this.businessPlanTemp.id;

    })
  }

  // funzione che prende i dati della correlazione da db
  checkExistCustomCorr(): void {
    if (this.isRisultatiVolatile && this.indicatoriGuidaVolatile != null) {
      if (this.indicatoriGuidaVolatile.correlazioniBp != null) {

        this.ordineLinee = [];
        this.corrMiste = [];
        this.otherLb = [];
        this.otherLBstart = [];
        this.corrMisteTemp = [];
        this.lastSelected = this.lineeBusinessCorrelazioni.length - 2;
        this.lastSelectedCp = this.lineeBusinessCorrelazioni.length - 2;
        this.ordineLinee = this.indicatoriGuidaVolatile.correlazioniBp.ordineLineeBp;

        this.tempDataToSave.id = this.indicatoriGuidaVolatile.correlazioniBp.id;
        this.tempDataToSave.idMatrice = this.indicatoriGuidaVolatile.correlazioniBp.idMatrice;
        this.tempDataToSave.idBusinessPlan = this.indicatoriGuidaVolatile.correlazioniBp.idBusinessPlan;
        this.tempDataToSave.ordineLineeBp = this.indicatoriGuidaVolatile.correlazioniBp.ordineLineeBp


        this.corrService.getCorrelazioniMat(this.tempDataToSave.idMatrice).subscribe(r => {
          this.corrInterne = [];
          this.corrInterne.push(r.matrice1)
          this.corrInterne.push(r.matrice2)
          this.corrInterne.push(r.matrice3)
          this.corrInterne.push(r.matrice4)
          this.corrInterne.push(r.matrice5)

          this.corrMiste.push(r.corr12)
          this.corrMiste.push(r.corr13)
          this.corrMiste.push(r.corr14)
          this.corrMiste.push(r.corr15)

          this.corrMisteTemp.push(r.corr12)
          this.corrMisteTemp.push(r.corr13)
          this.corrMisteTemp.push(r.corr14)
          this.corrMisteTemp.push(r.corr15)

        })

        this.firstLb = this.indicatoriGuidaVolatile.correlazioniBp.ordineLineeBp[0];
        this.firstLBStart = this.indicatoriGuidaVolatile.correlazioniBp.ordineLineeBp[0];

        this.indicatoriGuidaVolatile.correlazioniBp.ordineLineeBp.forEach((el, i) => {
          if (i > 0) {
            this.otherLb.push(el);
            this.otherLBstart.push(el);
          }
        })

        this.corrService.getAmbienteValues(this.businessPlanTemp.idAziendaRichiedente, this.lineeBusinessCorrelazioni.length).subscribe(res => {

          this.ordineLineeAmbiente = res.customCorrAMB.ordineLinee;

          this.corrMistePerLegenda.push(res.valoriMatrice.corr12)
          this.corrMistePerLegenda.push(res.valoriMatrice.corr13)
          this.corrMistePerLegenda.push(res.valoriMatrice.corr14)
          this.corrMistePerLegenda.push(res.valoriMatrice.corr15)
          this.ordinaLB(this.ordineLineeAmbiente)
        });

      } else {

        this.corrService.getAmbienteValues(this.businessPlanTemp.idAziendaRichiedente, this.lineeBusinessCorrelazioni.length).subscribe(res => {

          this.ordineLineeAmbiente = res.customCorrAMB.ordineLinee;

          this.corrMistePerLegenda.push(res.valoriMatrice.corr12)
          this.corrMistePerLegenda.push(res.valoriMatrice.corr13)
          this.corrMistePerLegenda.push(res.valoriMatrice.corr14)
          this.corrMistePerLegenda.push(res.valoriMatrice.corr15)
          this.ordinaLB(this.ordineLineeAmbiente)
        });

      }
      //this.correlazioneArray.markAsDirty();
      this.correlazioneArray.updateValueAndValidity();
    } else {
      this.corrService.checkCorrelazioni(this.businessPlanTemp.id, this.businessPlanTemp.idAziendaRichiedente, this.lineeBusinessCorrelazioni.length).subscribe(r => {
        this.corrMistePerLegenda = [];
        this.corrInterne = [];
        this.corrInterne.push(r.valoriMatrice.matrice1)
        this.corrInterne.push(r.valoriMatrice.matrice2)
        this.corrInterne.push(r.valoriMatrice.matrice3)
        this.corrInterne.push(r.valoriMatrice.matrice4)
        this.corrInterne.push(r.valoriMatrice.matrice5)

        if (r.customCorr.id != 0) {

          this.ordineLinee = [];
          this.corrMiste = [];
          this.otherLb = [];
          this.otherLBstart = [];
          this.corrMisteTemp = [];
          this.lastSelected = this.lineeBusinessCorrelazioni.length - 2;
          this.lastSelectedCp = this.lineeBusinessCorrelazioni.length - 2;
          this.ordineLinee = r.customCorr.ordineLineeBp;

          this.tempDataToSave.id = r.customCorr.id;
          this.tempDataToSave.idMatrice = r.customCorr.idMatrice;
          this.tempDataToSave.idBusinessPlan = r.customCorr.idBusinessPlan;
          this.tempDataToSave.ordineLineeBp = r.customCorr.ordineLineeBp

          this.corrMiste.push(r.valoriMatrice.corr12)
          this.corrMiste.push(r.valoriMatrice.corr13)
          this.corrMiste.push(r.valoriMatrice.corr14)
          this.corrMiste.push(r.valoriMatrice.corr15)

          this.corrMisteTemp.push(r.valoriMatrice.corr12)
          this.corrMisteTemp.push(r.valoriMatrice.corr13)
          this.corrMisteTemp.push(r.valoriMatrice.corr14)
          this.corrMisteTemp.push(r.valoriMatrice.corr15)


          this.firstLb = r.customCorr.ordineLineeBp[0];
          this.firstLBStart = r.customCorr.ordineLineeBp[0];

          r.customCorr.ordineLineeBp.forEach((el, i) => {
            if (i > 0) {
              this.otherLb.push(el);
              this.otherLBstart.push(el);
            }
          })
          //console.log("STO QUA ? ")
          this.corrService.getAmbienteValues(this.businessPlanTemp.idAziendaRichiedente, this.lineeBusinessCorrelazioni.length).subscribe(res => {

            this.ordineLineeAmbiente = res.customCorrAMB.ordineLinee;
            this.corrMistePerLegenda.push(res.valoriMatrice.corr12)
            this.corrMistePerLegenda.push(res.valoriMatrice.corr13)
            this.corrMistePerLegenda.push(res.valoriMatrice.corr14)
            this.corrMistePerLegenda.push(res.valoriMatrice.corr15)
            this.ordinaLB(this.ordineLineeAmbiente)
          });
        } else {

          this.ordineLineeAmbiente = r.customCorr.ordineLineeBp;

          this.tempDataToSave.id = r.customCorr.id;
          this.tempDataToSave.idMatrice = r.customCorr.idMatrice;
          this.tempDataToSave.idBusinessPlan = r.customCorr.idBusinessPlan;
          this.tempDataToSave.ordineLineeBp = r.customCorr.ordineLineeBp

          this.corrMistePerLegenda.push(r.valoriMatrice.corr12)
          this.corrMistePerLegenda.push(r.valoriMatrice.corr13)
          this.corrMistePerLegenda.push(r.valoriMatrice.corr14)
          this.corrMistePerLegenda.push(r.valoriMatrice.corr15)

          this.ordinaLB(this.ordineLineeAmbiente)
        }
        this.correlazioneArray.updateValueAndValidity();
      });
    }



  }

  ordinaLB(lineeDaAmbiente: number[]) {
    console.log(this.lineeBusinessCorrelazioni)
    this.lineeBusinessCorrelazioni.forEach(el => {
      this.lineeBusinessCorrelazioniOrdinato[lineeDaAmbiente.indexOf(el.idAmbiente)] = el;
    })
  }

  //setta i valori a quelli di ambiente 
  onResetDefault() {
    this.firstLb = 0;
    this.otherLb = [];
    //this.corrMiste = []
    this.corrMisteTemp = []
    this.ordineLinee = []

    this.firstLb = this.lineeBusinessCorrelazioniOrdinato[0].id;
    this.ordineLinee.push(this.lineeBusinessCorrelazioniOrdinato[0].id)
    this.lineeBusinessCorrelazioniOrdinato.forEach((el, i) => {
      i > 0 ? this.ordineLinee.push(el.id) : null;
    })


    this.lineeBusinessCorrelazioniOrdinato.forEach((el, i) => {
      i > 0 ? this.otherLb.push(el.id) : null;
    })


    this.corrMistePerLegenda.forEach(el => {
      // this.corrMiste.push(el)
      this.corrMisteTemp.push(el)
    })

    this.corrMisteTemp.forEach((el, i) => {
      if (i > 0) {
        if (i < this.lineeBusinessCorrelazioniOrdinato.length - 1) {
          this.correlazioneFormArray[i].controls.valoreCorrelazione.disable()
        }
      }
    })

    for (let i = 0; i < this.lineeBusinessCorrelazioniOrdinato.length - 1; i++) {
      i > 0 ? this.correlazioneFormArray[i].controls.correlazione.disable() : '';
    }



    this.lastSelected = this.lineeBusinessCorrelazioniOrdinato.length - 2;
    this.lastSelectedCp = this.lineeBusinessCorrelazioniOrdinato.length - 2;
    let tempMat: MatCorr = {
      matrice1: this.corrInterne[0], matrice2: this.corrInterne[1], matrice3: this.corrInterne[2], matrice4: this.corrInterne[3], matrice5: this.corrInterne[4],
      corr12: this.corrMisteTemp[0], corr13: this.corrMisteTemp[1], corr14: this.corrMisteTemp[2], corr15: this.corrMisteTemp[3]
    };
    this.mergeData(tempMat)
    if (!this.corrMiste.every((v, i) => v == this.corrMisteTemp[i]) || this.corrMiste.length == 0) {
      this.correlazioneArray.markAsDirty();
    }
    if (!this.otherLb.every((v, i) => v == this.otherLBstart[i])) {
      this.correlazioneArray.markAsDirty();
    }

    this.correlazioneArray.updateValueAndValidity();

  }



  // funzione che setta le option della select con i risultati che arrivano da backend
  presetSelect(indice: number, LBorID: number[] | number, tipo: string): boolean {
    if (tipo == "label") {
      if (LBorID[0] == this.firstLb && this.otherLb[indice] == LBorID[1]) {
        this.correlazioneFormArray[indice]?.controls['correlazione'].setValue([LBorID[0], LBorID[1]])
        this.correlazioneFormArray[indice]?.controls['correlazione'].updateValueAndValidity()
        this.correlazioneArray.updateValueAndValidity();
        return true;
      } else {
        return false
      }
    } else {
      if (LBorID == this.corrMisteTemp[indice]) {
        this.correlazioneFormArray[indice]?.controls['valoreCorrelazione'].setValue(LBorID)
        this.correlazioneFormArray[indice]?.controls['valoreCorrelazione'].updateValueAndValidity()
        this.correlazioneArray.updateValueAndValidity();
        return true
      } else {
        return false
      }
    }
  }





  //////////////////////////////////
  ngOnDestroy(): void {
    if (this.risultatiVolatileSubscription != undefined) {
      this.risultatiVolatileSubscription.unsubscribe()
    }
    this.configurazioneBusinessPlanService.lineeCorrelazioniBp(null);
    this.arrayLineeBpCorrelazioniSubscription.unsubscribe();
    this.tipsSubscription.unsubscribe()

  }

}


