<div class="container-fluid" [formGroup]="magazzino">
    <div class="row" id="save-magazzino">
        <div class="col-8 d-flex align-items-center justify-content-start nopadding ">
            <h2 style="font-weight: bold;" *ngIf="rimanenzeTotMagazzino!=0">Rimanenze magazzino aggiornate al
                31/12/{{dataAggiornamento}} :
                {{rimanenzeTotMagazzino | number:'1.0-0':'it'}} €
            </h2>
        </div>
        <div class="col-4 d-flex align-items-end justify-content-end margin-top-10 margin-bottom-10">
            <!-- <button *ngIf="businessPlanTemp!=null && (businessPlanTemp.idStato==1 ||businessPlanTemp.idStato==2)"
                class="btn btn-success" (click)="onSubmit()">Salva</button> -->
            <button *ngIf="businessPlanTemp!=null && (businessPlanTemp.idStato==1 ||businessPlanTemp.idStato==2)"
                class="btn btn-secondary" type="button" (click)="Annulla()">
                Annulla modifiche
            </button>
        </div>
    </div>
    <hr>
    <div class="row nopadding margin-0">
        <!-- id="addPaddingInStickyRicavi" -->
        <div class="col-12 d-flex nopadding">
            <ul>
                <li *ngIf="rimanenzeTotMagazzino!=0">
                    Per la produzione del Business Plan è necessario inserire, per le Linee
                    Business che lo prevedono, la valorizzazione iniziale del magazzino
                    al primo
                    anno.
                </li>
                <li *ngIf="rimanenzeTotMagazzino==0">
                    Per la produzione del Business Plan è necessario scomporre in forma percentuale l'importo del
                    magazzino,
                    sulle Linee Business che lo prevedono.
                </li>
                <br>
                <li>
                    Sulla base delle informazioni inserite (rimanenza di magazzino e ricavi stimati
                    sulla
                    relativa Linea di Business) l'indice di rotazione del magazzino risulata essere quello mostrato.
                    &Egrave;
                    possibile modificare ogni singolo indice di rotazione.
                </li>
            </ul>
        </div>
        <div class=" col-5 col-xl-5 d-flex align-items-center margin-left-20 nopadding">
            <h3 class="align-middle">Abilita oscillazioni</h3>
            <input class="margin-left-20" type="checkbox" formControlName="oscillazioniMagazzino"
                (change)="abilitaOscillazioni()">
        </div>
        <div *ngIf="!this.sumValoriInizialiBool" class="error col-12">
            La somma delle percentuali valori iniziali deve essere pari a 100%
        </div>
    </div>
    <!-- START qando NON è presente un valore iniziale -->
    <div>
        <!-- id="container-magazzino-body-bp" -->
        <div formArrayName="magazzinoarray">
            <div *ngFor="let magazzinoForm of magazzinoFormArray;let i = index" [formGroupName]="i"
                class=" margin-top-20 margin-bottom-20 margin-left-20">
                <hr>
                <h2>{{lineeBusinessMagazzino.length>0 ? lineeBusinessMagazzino[i].nomeLinea:''}}</h2>
                <div class="row margin-top-20">
                    <div class="col-2" *ngIf="rimanenzeTotMagazzino!=0">
                        <label>Valore iniziale (%)</label><!-- &nbsp;&nbsp;
                        <i id=" icon" class="fa fa-1x fa-info-circle" aria-hidden="true" container="body"
                            placement="top" ngbTooltip={{listaTips[44]?.descrizione}}></i> -->
                        <input type="number" class="form-control valore_iniziale" formControlName="valoreIniziale"
                            min="0" onkeyup="if(this.value<0){this.value= this.value *-1}"
                            [ngClass]="{ 'is-invalid':(submitted && this.magazzinoForm.controls.valoreIniziale.errors) || (submitted && !this.sumValoriInizialiBool) }"
                            (keyup)="applicaPerc(i)" (focusout)="setIndiceRotazione(i)" />
                        <div *ngIf=" (submitted && this.magazzinoForm.controls.valoreIniziale.errors)"
                            class="invalid-feedback">
                            <div *ngIf="this.magazzinoForm.controls.valoreIniziale.errors.required"> Campo obbligatorio
                            </div>
                            <div *ngIf="this.magazzinoForm.controls.valoreIniziale.errors.max">Il valore iniziale non
                                può
                                superare il 100%</div>
                        </div>
                    </div>
                    <div class="col-2" *ngIf="rimanenzeTotMagazzino!=0">
                        <label>Valore iniziale (€)</label>
                        <!--&nbsp;&nbsp; <i id=" icon" class="fa fa-1x fa-info-circle" aria-hidden="true" container="body" placement="top"
                        ngbTooltip={{listaTips[44]?.descrizione}}></i> -->
                        <input type="text" class="form-control valore_iniziale" formControlName="valoreInizialeNumerico"
                            currencyMask
                            [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0, suffix: ' €', allowNegative: false }"
                            min="0" />
                        <!-- <div *ngIf=" (submitted && this.magazzinoForm.controls.valoreIniziale.errors)" class="invalid-feedback">
                        <div *ngIf="this.magazzinoForm.controls.valoreIniziale.errors.required"> Campo obbligatorio
                        </div>
                        <div *ngIf="this.magazzinoForm.controls.valoreIniziale.errors.max">Il valore iniziale non può
                            superare il 100%</div>
                    </div> -->
                    </div>
                    <div class="col-2" *ngIf="rimanenzeTotMagazzino==0"><label>Valore iniziale (€)<!-- &nbsp;&nbsp;<i
                                id=" icon" class="fa fa-1x fa-info-circle" aria-hidden="true" container="body"
                                placement="top" ngbTooltip="??"></i> --></label>
                        <input type="text" class="form-control valore_iniziale" formControlName="valoreIniziale" min="0"
                            currencyMask
                            [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0, suffix: ' €', allowNegative: false }"
                            onkeyup="if(this.value<0){this.value= this.value *-1}" (focusout)="setIndiceRotazione(i)"
                            [ngClass]="{ 'is-invalid':(submitted && this.magazzinoForm.controls.valoreIniziale.errors) || (submitted && !this.sumValoriInizialiBool)}" />
                        <div *ngIf=" (submitted && this.magazzinoForm.controls.valoreIniziale.errors)"
                            class="invalid-feedback">
                            <div *ngIf="this.magazzinoForm.controls.valoreIniziale.errors.required"> Campo obbligatorio
                            </div>

                        </div>
                    </div>
                    <div class="col-2">
                        <label>Indice di rotazione</label>
                        <input type="number" class="form-control" formControlName="indiceRotazione" min="0"
                            onkeyup="if(this.value<0){this.value= this.value *-1}"
                            [ngClass]="{ 'is-invalid':submitted && this.magazzinoForm.controls.indiceRotazione.errors}" />
                        <div *ngIf=" submitted && this.magazzinoForm.controls.indiceRotazione.errors"
                            class="invalid-feedback">
                            <div *ngIf="this.magazzinoForm.controls.indiceRotazione.errors.required">Campo obbligatorio
                            </div>
                        </div>
                        <!-- (keyup)="checkValMax(i,'magazzinoIndiceRotazione')"
                        <div *ngIf="warningMagazzinoIndiceRotazione[i]" class="warning-label">
                        <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo di mantenere
                        il indice di rotazione sotto il {{this.valoreMax["indiceRotazione"]}}%
                    </div> -->
                    </div>
                    <div class="col-6">
                        <div class="row">
                            <div class="col-3" *ngIf="f.oscillazioniMagazzino.value">
                                <label>Ribasso (%)</label>
                                <input type="number" class="form-control" formControlName="ribasso" min="0"
                                    onkeyup="if(this.value<0){this.value= this.value *-1}"
                                    (keyup)="checkValMax(i,'magazzinoRibasso')"
                                    [ngClass]="{ 'is-invalid':submitted && this.magazzinoForm.controls.ribasso.errors ,'warning-control':warningMagazzinoRibasso[i] }" />
                                <div *ngIf=" submitted && this.magazzinoForm.controls.ribasso.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="this.magazzinoForm.controls.ribasso.errors.required">Campo obbligatorio
                                    </div>

                                </div>
                                <div *ngIf="warningMagazzinoRibasso[i]" class="warning-label">
                                    <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo di
                                    mantenere
                                    il Ribasso sotto il {{this.valoreMax["ribasso"]}}%
                                </div>
                            </div>
                            <div class="col-3" *ngIf="f.oscillazioniMagazzino.value">
                                <label>Rialzo (%)</label>
                                <input type="number" class="form-control" formControlName="rialzo" min="0"
                                    onkeyup="if(this.value<0){this.value= this.value *-1}"
                                    (keyup)="checkValMax(i,'magazzinoRialzo')"
                                    [ngClass]="{ 'is-invalid':submitted && this.magazzinoForm.controls.rialzo.errors ,'warning-control':warningMagazzinoRialzo[i] }" />
                                <div *ngIf=" submitted && this.magazzinoForm.controls.rialzo.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="this.magazzinoForm.controls.rialzo.errors.required">Campo obbligatorio
                                    </div>
                                </div>
                                <div *ngIf="warningMagazzinoRialzo[i]" class="warning-label">
                                    <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo di
                                    mantenere
                                    il Rialzo sotto il {{this.valoreMax["rialzo"]}}%
                                </div>
                            </div>
                            <div class="col-3" *ngIf="f.oscillazioniMagazzino.value">
                                <label>Scarto (%)&nbsp;&nbsp;<i id=" icon" class="fa fa-1x fa-info-circle"
                                        aria-hidden="true" container="body" placement="top"
                                        ngbTooltip={{listaTips[8]?.descrizione}}></i></label>
                                <input type="number" class="form-control" formControlName="scarto" min="0"
                                    onkeyup="if(this.value<0){this.value= this.value *-1}"
                                    (keyup)="checkValMax(i,'magazzinoScarto')"
                                    [ngClass]="{ 'is-invalid':submitted && this.magazzinoForm.controls.scarto.errors ,'warning-control':warningMagazzinoScarto[i] }" />
                                <div *ngIf=" submitted && this.magazzinoForm.controls.scarto.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="this.magazzinoForm.controls.scarto.errors.required">Campo obbligatorio
                                    </div>

                                </div>
                                <div *ngIf="warningMagazzinoScarto[i]" class="warning-label">
                                    <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo di
                                    mantenere
                                    il Scarto sotto il {{this.valoreMax["scarto"]}}%
                                </div>
                            </div>
                            <div class="col-3">
                                <label>Drift (%)&nbsp;&nbsp; <i id=" icon" class="fa fa-1x fa-info-circle"
                                        aria-hidden="true" container="body" placement="top"
                                        ngbTooltip={{listaTips[9]?.descrizione}}></i></label>
                                <input type="number" class="form-control" formControlName="drift" min="0"
                                    onkeyup="if(this.value<0){this.value= this.value *-1}"
                                    (keyup)="checkValMax(i,'magazzinoDrift')"
                                    [ngClass]="{ 'is-invalid':submitted && this.magazzinoForm.controls.drift.errors ,'warning-control':warningMagazzinoDrift[i] }" />
                                <div *ngIf=" submitted && this.magazzinoForm.controls.drift.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="this.magazzinoForm.controls.drift.errors.required">Campo obbligatorio
                                    </div>

                                </div>
                                <div *ngIf="warningMagazzinoDrift[i]" class="warning-label">
                                    <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo di
                                    mantenere
                                    il Drift sotto il {{this.valoreMax["drift"]}}%
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <hr>
</div>