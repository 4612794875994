import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LineaBusinessBp } from '../condivisi/linea-business-bp';
import { ProdottoLineaBusinessBp } from '../condivisi/ProdottoLineaBusinessBp';
import { PraticheNuova } from '../interface/Pratiche-nuova';
import { BeanStatico } from './../condivisi/beanStatico';
import { Magazzino } from '../interface/magazzino';
import { InfoBilancio } from '../interface/bilancio';
import { SituazioneCreditizia, SituazioneDebitoria } from '../interface/situazione-debitoria';
import { InvestimentiDisinvestimenti } from '../interface/InvestimentiDisinvestimenti';
import { FormGroup } from '@angular/forms';
import { QuestionarioBp } from '../interface/questionario-bp';
import { IndicatoriGuidaRequest } from '../interface/indicatori-guida-bp';
import { LineaBusinessBpCompleto } from '../interface/LineaBusinessBpCompleto';
import { QuestionarioBpTot } from '../interface/QuestionarioBpTot';
import { BeanStaticoCorrelazioni } from '../condivisi/beanStaticoCorrelazioni';
import { Provincia } from '../condivisi/provincia';

@Injectable({
  providedIn: 'root'
})
export class ConfigurazioneBusinessPlanService {

  //lista debiti e crediti
  listaDebiti: BeanStatico[];
  listaCrediti: BeanStatico[];
  //IsVolatile
  private risultatiVolatile = new BehaviorSubject<boolean>(false);
  currentRisultatiVolatile: Observable<boolean> = this.risultatiVolatile.asObservable();

  /* service per salvare il recod dei nuovi Bp vuoti */
  private businessPlan = new BehaviorSubject<PraticheNuova>(null);
  currentBusinessPlan: Observable<PraticheNuova> = this.businessPlan.asObservable();

  private lineeCorrelazioni = new BehaviorSubject<BeanStaticoCorrelazioni[]>(null);
  currentLineeCorrelazioni: Observable<BeanStaticoCorrelazioni[]> = this.lineeCorrelazioni.asObservable();

  private questionarioBp = new BehaviorSubject<QuestionarioBp>(null);
  currentQuestionarioBp: Observable<QuestionarioBp> = this.questionarioBp.asObservable();

  private bpSalvato: BehaviorSubject<boolean> = new BehaviorSubject(false);
  checkBpSalvato: Observable<boolean> = this.bpSalvato.asObservable();

  private selectRicaviValorizazione: BehaviorSubject<number> = new BehaviorSubject(0);
  selectRicaviValorizazione$: Observable<number> = this.selectRicaviValorizazione.asObservable();
  /* private ricaviComplessiviArray: BehaviorSubject<any[]> = new BehaviorSubject(null);
  currentRicaviComplessiviArray: Observable<any[]> = this.ricaviComplessiviArray.asObservable(); */

  private annoBilancio = new BehaviorSubject<number>(0);
  _annoBilancio$: Observable<number> = this.annoBilancio.asObservable();

  private ResetCountRicaviCosti = new BehaviorSubject<number>(0);
  _ResetCountRicaviCosti$: Observable<number> = this.ResetCountRicaviCosti.asObservable();


  private inizioDurata: BehaviorSubject<any> = new BehaviorSubject(null);
  currentInizioDurata: Observable<any> = this.inizioDurata.asObservable();

  constructor(
    private httpClient: HttpClient,
  ) { }

  sendSelectValueOptionSelect(num: number) {
    this.selectRicaviValorizazione.next(num);
  }

  resetRicaviCostiCount() {
    this.ResetCountRicaviCosti.next(0);
  }

  inviaAnnoBilancio(annoBilancio: number) {
    this.annoBilancio.next(annoBilancio);
  }

  ElaboraBp(questionarioBp: QuestionarioBp) {
    this.questionarioBp.next(questionarioBp)
  }

  lineeCorrelazioniBp(lineeBp: BeanStaticoCorrelazioni[]) {
    this.lineeCorrelazioni.next(lineeBp)
  }


  cancellaTutto() {
    this.questionarioBp.next(null);
    this.businessPlan.next(null);
  }

  /*  */
  LoadCurrentBusinessPlan(businessPlan: PraticheNuova) {
    this.businessPlan.next(businessPlan)
  }

  changeBpSalvato(valore: boolean) {
    this.bpSalvato.next(valore)
  }

  /* generali */

  listaBplanIsPresent(idUtente: number) {
    return this.httpClient.post<boolean>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneBusinessPlan/listaBplanIsPresent', { idUtente: idUtente });
  }

  getValoriMax(nomeSezione: string) {
    return this.httpClient.post<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneBusinessPlan/getValoriMax', { nomeSezione: nomeSezione });
  }
  getDatiBilancio(idStatoPatrimonialeAttivo: number): Observable<InfoBilancio> {
    return this.httpClient.post<InfoBilancio>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneBusinessPlan/getDatiBilancio', { idStatoPatrimonialeAttivo: idStatoPatrimonialeAttivo });
  }
  /* ----------- */

  /* lista bplan */
  getAllBpRichiedente(idUtente: number) {
    return this.httpClient.post<any>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneBusinessPlan/getAllBpRichiedente', { idUtente: idUtente });
  }

  saveBusinessPlanNuovo(request: PraticheNuova) {
    return this.httpClient.post<PraticheNuova>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneBusinessPlan/saveBusinessPlanNuovo', request);
  }
  saveBusinessPlanLavorazione(questionario: QuestionarioBpTot, idBpOld: number) {
    return this.httpClient.post<number>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneBusinessPlan/saveBusinessPlanLavorazione/' + idBpOld, questionario);
  }

  saveBusinessPlanPreparazione(questionario: QuestionarioBpTot) {
    return this.httpClient.post<any>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneBusinessPlan/saveBusinessPlanPreparazione', questionario);
  }
  deleteBusinessPlan(idBp: number, idStato: number) {
    return this.httpClient.post<boolean>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneBusinessPlan/deleteBusinessPlan', { idBp: idBp, idStato: idStato });
  }
  deleteRisultati(idBp: number) {
    return this.httpClient.post<boolean>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneBusinessPlan/deleteRisultati', idBp);
  }

  aggiornaListaBp(idRichiedente: number) {
    return this.httpClient.post<boolean>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneBusinessPlan/aggiornaListaBp', { idRichiedente: idRichiedente });
  }

  checkPresenzaMagazzino(idBusinessPlan: number): Observable<boolean> {
    return this.httpClient.post<boolean>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneBusinessPlan/checkPresenzaMagazzino', { idBusinessPlan: idBusinessPlan });
  }

  isRisultatiVolatile(risultatiVolatile: boolean) {
    this.risultatiVolatile.next(risultatiVolatile)
  }
  calcolaBusinessPlan(questionario: QuestionarioBpTot) {
    return this.httpClient.post<any>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneBusinessPlan/elaboraBP', questionario)
  }

  changeInizioDurata(valore) {
    this.inizioDurata.next(valore)
  }
  /*---------------- */

  /* indicator guida */

  getIndicatoriGuida(idBusinessPlan: number, idRichiedente: number) {
    return this.httpClient.post<any>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneBusinessPlan/getIndicatoriGuida', { idBusinessPlan: idBusinessPlan, idRichiedente: idRichiedente });
  }
  saveIndicatoriGuida(request: any) {
    return this.httpClient.post<boolean>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneBusinessPlan/saveIndicatoriGuida', request);
  }
  /* ------------ */
  /* investimenti disinvestimenti */
  getListaTipoInvDis(idRichiedente: number, settoreDisinvestimento: string) {
    return this.httpClient.post<any>(environment.apiUrlQuestionario + '/fasGoAlgo/getTipoInvestimentiAndTipoDisinvestimenti/' + settoreDisinvestimento, { idRichiedente: idRichiedente });
  }
  getListaModalitaFinanziamentoEGaranzie() {
    return this.httpClient.get<any>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneBusinessPlan/getListaModalitaFinanziamentoEGaranzie');
  }

  getInvestimentiDisinvestimenti(idBusinessPlan: number) {
    return this.httpClient.post<any>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneBusinessPlan/getInvestimentiAndDisinvestimenti', { idBusinessPlan: idBusinessPlan });
  }

  /* ------------ */
  ///ricaVI costi
  getRicaviCosti() {
    return this.httpClient.get<BeanStatico[]>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneBusinessPlan/getRicaviCosti');
  }
  getLineeBusiness(idBusinessPlan: number, idRichiedente: number) {
    return this.httpClient.post<LineaBusinessBp[]>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneBusinessPlan/getLineeBp', { idBusinessPlan: idBusinessPlan, idRichiedente: idRichiedente });
  }

  getProdotti(idLineaBusiness: number, idBusinessPlan: number, idTipologiaAtt: number) {
    return this.httpClient.post<ProdottoLineaBusinessBp[]>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneBusinessPlan/getProdotti', { idLineaBusiness: idLineaBusiness, idBusinessPlan: idBusinessPlan, idTipologiaAtt: idTipologiaAtt });
  }

  /*   saveLineaBpProdotti(linea: LineaBusinessBp, prodotti: ProdottoLineaBusinessBp[]) {
      return this.httpClient.post<number>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneBusinessPlan/saveLineaBpProdotti', { linea: linea, prodotti: prodotti });
    } */
  getLineaBusiness(idLineaBusiness: number) {
    return this.httpClient.post<LineaBusinessBp>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneBusinessPlan/getLineaBusiness', idLineaBusiness);
  }

  getProvince() {
    return this.httpClient.get<Provincia[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getProvince');
  }

  getCanaliDistribuzione() {
    return this.httpClient.get<BeanStatico[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getCanaliDistribuzione');
  }
  /* ------------ */



  /* magazzino */
  getValoriMagazzino(idBp: number) {
    return this.httpClient.post<Magazzino[]>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneBusinessPlan/getMagazzino', { idBp: idBp });
  }

  getListaDebiti(): Observable<BeanStatico[]> {
    return this.httpClient.get<BeanStatico[]>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneBusinessPlan/getListaDebiti')
  }
  getListaCrediti(): Observable<BeanStatico[]> {
    return this.httpClient.get<BeanStatico[]>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneBusinessPlan/getListaCrediti')
  }



  /* sit finanziaria */
  getSituazioneDebitoria(idBp: number, idStatoPatrimonialePassivo: number, idRichiedente: number): Observable<SituazioneDebitoria> {
    return this.httpClient.post<SituazioneDebitoria>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneBusinessPlan/getSituazioneDebitoria', { idBp: idBp, idRichiedente: idRichiedente, idStatoPatrimonialePassivo: idStatoPatrimonialePassivo });
  }
  getSituazioneDebitoriaNew(idBp: number, idRichiedente: number): Observable<SituazioneDebitoria> {
    return this.httpClient.post<SituazioneDebitoria>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneBusinessPlan/getSituazioneDebitoriaNew', { idBusinessPlan: idBp, idRichiedente: idRichiedente });
  }

  getSituazioneCreditizia(idBp: number, idRichiedente: number, idStatoPatrimonialeAttivo: number): Observable<SituazioneCreditizia> {
    return this.httpClient.post<SituazioneCreditizia>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneBusinessPlan/getSituazioneCreditizia', { idBusinessPlan: idBp, idRichiedente: idRichiedente, idStatoPatrimonialeAttivo: idStatoPatrimonialeAttivo });
  }
  getSituazioneCreditiziaNew(idBp: number, idRichiedente: number): Observable<SituazioneCreditizia> {
    return this.httpClient.post<SituazioneCreditizia>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneBusinessPlan/getSituazioneCreditiziaNew', { idBusinessPlan: idBp, idRichiedente: idRichiedente });
  }



  /* ----------- */

}
