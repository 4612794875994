import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CashFlow, ColCrisiImpresa, Complessiva, ContoEconomico, DebitoFinanziario, Debitoria, Garanzie, IndicatoriEconomici, IndiciBilancio, Patrimoniale, saveDettaglio } from '../risultati-questionario/interface/dettaglio';
import { SoglieCrisiImpresa } from '../risultati-questionario/interface/SoglieCrisiImpresa';

@Injectable({
  providedIn: 'root'
})
export class DettaglioRisultatiService {

  private baseUrl = environment.apiUrlQuestionario + '/fasGoAlgo';

  constructor(private http: HttpClient) { }

  private risultatiDettaglio: BehaviorSubject<saveDettaglio> = new BehaviorSubject<saveDettaglio>(null);
  public risultatiDettaglio$: Observable<saveDettaglio> = this.risultatiDettaglio.asObservable();


  private risultatiCommento: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public risultatiCommento$: Observable<string> = this.risultatiCommento.asObservable();

  private annoStart: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public annoStart$: Observable<number> = this.annoStart.asObservable();

  public sendAnnoStart(annoStart: number) {
    this.annoStart.next(annoStart);
  }

  public aggiornaCommento(commento: string) {
    this.risultatiCommento.next(commento);
  }


  public getSoglieCrisiImpresa(idBusinessPlan: number): Observable<SoglieCrisiImpresa> {
    return this.http.post<SoglieCrisiImpresa>(this.baseUrl + '/getSoglieCrisiImpresa', idBusinessPlan)
  }

  public takeValueDettaglio(risultati: saveDettaglio) {

    this.risultatiDettaglio.next(risultati);
  }

  public cancellaTutto() {
    this.risultatiDettaglio.next(null);
  }

  public complessivaTab(idBp: number): Observable<Complessiva[]> {
    return this.http.post<Complessiva[]>(this.baseUrl + '/allRisultatiComplessiva', { idBusinessPlan: idBp });
  }

  public indicatoriTab(idBp: number): Observable<IndicatoriEconomici[]> {
    return this.http.post<IndicatoriEconomici[]>(this.baseUrl + '/allRisultatiIndicatori', { idBusinessPlan: idBp });
  }

  public debitoriaTab(idBp: number): Observable<Debitoria[]> {
    return this.http.post<Debitoria[]>(this.baseUrl + '/allRisultatiSituazioneDebitoria', { idBusinessPlan: idBp });
  }

  public getCrisiImpresa(idBp: number): Observable<ColCrisiImpresa[]> {
    return this.http.post<ColCrisiImpresa[]>(this.baseUrl + '/getCrisiImpresa', { idBusinessPlan: idBp });
  }

  public contoeconomicoTab(idBp: number): Observable<ContoEconomico[]> {
    return this.http.post<ContoEconomico[]>(this.baseUrl + '/allRisultatiContoEconomico', { idBusinessPlan: idBp });
  }

  public cashflowTab(idBp: number): Observable<CashFlow[]> {
    return this.http.post<CashFlow[]>(this.baseUrl + '/allRisultatiCashflow', { idBusinessPlan: idBp });
  }

  public patrimonialeTab(idBp: number): Observable<Patrimoniale[]> {
    return this.http.post<Patrimoniale[]>(this.baseUrl + '/allRisultatiStatoPatrimoniale', { idBusinessPlan: idBp });
  }

  public debitoFinanziarioTab(idBp: number): Observable<DebitoFinanziario[]> {
    return this.http.post<DebitoFinanziario[]>(this.baseUrl + '/allRisultatiDebitoFinanziario', { idBusinessPlan: idBp });
  }

  public mccTab(idBp: number): Observable<Debitoria[]> {
    return this.http.post<Debitoria[]>(this.baseUrl + '/allRisultatiMCC', { idBusinessPlan: idBp });
  }

  public garanzieTab(idBp: number): Observable<Garanzie> {
    return this.http.post<Garanzie>(this.baseUrl + '/risultatiGaranzie', { idBusinessPlan: idBp });
  }

  public indiciBilancioTab(idBp: number): Observable<IndiciBilancio[]> {
    return this.http.post<IndiciBilancio[]>(this.baseUrl + '/allRisultatiIndiciBilancio', { idBusinessPlan: idBp });
  }

  public istogrammaIndiciBilancioTab(idBp: number): Observable<Debitoria[]> {
    return this.http.post<Debitoria[]>(this.baseUrl + '/allRisultatiIstogrammaBilancio', { idBusinessPlan: idBp });
  }

  public getCommentoBp(idBp: number): Observable<string> {
    return this.http.get<string>(this.baseUrl + '/getCommentoRisBp/' + idBp, { responseType: 'text' as 'json' });
  }
}
